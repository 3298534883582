<div class="footer-container ime-hide-only-on-mobile">
  <hr />
  <div class="footer-container__contents content-container">
    <div class="container_links">
      <div
        class="sendReceive"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="500"
      >
        <span class="sendReceive__title"> {{'footer.send_receive' | translate}} </span>
        <div class="sendReceive__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.HOW_IT_WORKS"
            routerLinkActive="active-link"
          >
          {{'footer.how_it_works' | translate}}
          </a>
          <div
            class="routeLink"
            [routerLink]="'../' + routerPath.REMITTANCE"
            routerLinkActive="active-link"
          >
            {{'footer.remittance' | translate}}
          </div>
          <div
            class="routeLink"
            [routerLink]="'../' + routerPath.WALLET_TRANSFER"
            routerLinkActive="active-link"
          >
            {{'footer.wallet_transfer' | translate}}
          </div>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.BANK_TRANSFER"
            routerLinkActive="active-link"
          >
            {{'footer.bank_transfers' | translate}}
          </a>
        </div>
      </div>
      <div
        class="payWithImePay"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="700"
      >
        <span class="payWithImePay__title"> {{'footer.pay_with_ime_pay' | translate}} </span>
        <div class="payWithImePay__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.WAY_TO_PAY"
            routerLinkActive="active-link"
          >
            {{'footer.ways_to_pay' | translate}}
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PAY_SERVICE"
            routerLinkActive="active-link"
          >
          {{'footer.pay_for_ime_pay_services' | translate}}
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PAY_ONLINE_IN_APPS"
            routerLinkActive="active-link"
          >
          {{'footer.pay_in_apps_and_online' | translate}}
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PAY_IN_STORE"
            routerLinkActive="active-link"
          >
          {{'footer.pay_in_stores' | translate}}
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PAY_THROUGH_CARD"
            routerLinkActive="active-link"
          >
          {{'footer.ime_pay_visa_card' | translate}}
          </a>
        </div>
      </div>
      <div
        class="payForBusiness"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="900"
      >
        <span class="payForBusiness__title"> {{'footer.ime_pay_for_business' | translate}} </span>
        <div class="payForBusiness__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
          {{'footer.accept_ime_pay_payments' | translate}}
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
          {{'footer.register_your_business' | translate}}
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
          {{'footer.accept_ime_pay_in_apps_and_online' | translate}}
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
          {{'footer.accept_ime_pay_in_stores' | translate}}
          </a>
        </div>
      </div>
      <div
        class="qrSection"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1000"
      >
        <div class="qr-code">
          <img src="assets/images/get-app-scan-qr.webp" />
        </div>
        <span> {{'footer.scan_to_get_the_app' | translate}} </span>
      </div>
      <div
        class="company"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1100"
      >
        <span class="company__title">{{'footer.company' | translate}}</span>
        <div class="company__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.ABOUT_US"
            routerLinkActive="active-link"
            >{{'footer.about_us' | translate}}</a
          >
          <div
            class="routeLink"
            (click)="goToLink(ourAgentList)"
          >
          {{'footer.our_agents' | translate}}
          </div>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.ACCESSIBILITY_STATEMENT"
            routerLinkActive="active-link"
            >{{'footer.accessibility_statement' | translate}}</a
          >
          <a
            class="routeLink"
            routerLinkActive="active-link"
            (click)="
              goToLink(
                'https://www.linkedin.com/company/imepay-official/jobs/'
              )
            "
            >{{'footer.career' | translate}}</a
          >
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.TERMS"
            routerLinkActive="active-link"
            >{{'footer.terms_conditions' | translate}}</a
          >
          <a
          class="routeLink"
          [routerLink]="'../' + routerPath.SAFETY_AND_COMPLIANCE"
          routerLinkActive="active-link"
          >{{'footer.safety_compliance' | translate}}</a
        >
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PRIVACY_POLICY"
            routerLinkActive="active-link"
            >{{'footer.privacy' | translate}}</a
          >

          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.GRIEVANCES"
            routerLinkActive="active-link"
          >{{'footer.grievances' | translate}}</a
          >
          <a
          class="routeLink"
          [routerLink]="'../' + routerPath.ISMS_POLICY"
          routerLinkActive="active-link"
        >{{'footer.isms_policy' | translate}}</a
        >
        </div>
      </div>
      <div
        class="resource"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1300"
      >
        <span class="resource__title">{{'footer.resources' | translate}}</span>
        <div class="resource__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            {{'footer.how_to' | translate}}
          </a>
          <div
            class="routeLink"
            (click)="goToLink(commissionAndCharges)"
          >
            {{'footer.charges_commission' | translate}}
          </div>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            {{'footer.help_support' | translate}}
          </a>
          <div
            class="routeLink"
            (click)="goToLink('https://blog.imepay.com.np/')"
          >
            {{'footer.blog' | translate}}
          </div>
          <div
          class="routeLink"
          (click)="
            goToLink(
              'http://developer.imepay.com.np/'
            )
          "
        >
        {{'footer.developers' | translate}}
        </div>
        </div>
      </div>
      <div
        class="contact"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1500"
      >
        <span class="contact__title">{{'footer.contact' | translate}}</span>
        <div class="contact__links">
          <div class="contact__text">{{'footer.ntc_toll_free' | translate}}</div>
          <div class="contact__text">{{'footer.ncell_toll_free' | translate}}</div>
          <div class="contact__text">{{'footer.phone_numbers' | translate}}</div>
          <div class="contact__text">
            <a class="contact__text" href="mailto:info@imepay.com.np">
              {{'footer.email' | translate}}
            </a>
          </div>
          <div class="contact__text">{{'footer.whatsapp' | translate}}</div>
        </div>
      </div>
      <div
        class="copyRight"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1500"
      >
        <img
          src="assets/images/ime-digital-copyRight.svg"
          alt="logo of ime digital"
        />
        <span>{{'footer.copyright' | translate}}</span>
      </div>
    </div>
    <div class="footer">
      <div class="footer__left">
        <div class="logo">
          <img src="assets/logo/ime-main.svg" alt="logo of ime group" />
        </div>
        <div class="social--links">
          <a
            href="https://www.facebook.com/imepay"
            target="_blank"
            class="social-icons"
          >
            <ime-json-animator
              [options]="animationIconOption[0]"
            ></ime-json-animator>
          </a>
          <a
            href="https://www.youtube.com/channel/UCqg5Kde1gJ13Tr2GsqE9tNg"
            target="_blank"
            class="social-icons"
          >
            <ime-json-animator
              [options]="animationIconOption[1]"
            ></ime-json-animator>
          </a>
          <a
            href="https://www.instagram.com/imepay_official"
            target="_blank"
            class="social-icons"
          >
            <ime-json-animator
              [options]="animationIconOption[2]"
            ></ime-json-animator>
          </a>
          <a
            href="https://www.linkedin.com/company/imepay-official/"
            target="_blank"
            class="social-icons"
          >
            <ime-json-animator
              [options]="animationIconOption[3]"
            ></ime-json-animator>
          </a>
        </div>
      </div>
      <div class="footer__middle">
        <a
          href="https://www.sisainfosec.com/certificate.php?number=31005913646142466455&type=pcidss"
          target="_blank"
          rel="sisa certified"
          matTooltip="Sisa certified. Click here to view"
          matTooltipPosition="above"
        >
          <img
            [src]="sisaImgUrl"
            alt=""
            class="footer__middle__img-1"
            (mouseenter)="sisaImgUrl = 'assets/images/sisa-hover.png'"
            (mouseleave)="sisaImgUrl = 'assets/images/sisa.png'"
          />
        </a>
        <a
        href="https://connect2.amtivo.com/cert/amtivocert10001.asp?c=626442&v=65glgg396r&e=60018"
        target="_blank"
        rel="Amtivo certified"
      >
        <img
          [src]="amtivoImgUrl"
          matTooltip="Amtivo certified. Click here to view"
          alt="Amtivo certified"
          class="footer__middle__img-2"
        />
      </a>
      </div>

      <div class="footer__right">
       {{'footer.imepay_uses_advanced' | translate}}
      </div>
    </div>
  </div>
</div>

<div class="footer-container-mb ime-show-only-on-mobile">
  <div class="footer-container-mb__contents contents content-container">
    <div class="contents__menus">
      <mat-accordion>
        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState1 = true"
          (closed)="panelOpenState1 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">{{'footer.send_receive' | translate}}</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState1
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.HOW_IT_WORKS"
          >
          {{'footer.how_it_works' | translate}}
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.REMITTANCE"
          >
          {{'footer.remittance' | translate}}
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.WALLET_TRANSFER"
          >
          {{'footer.wallet_transfer' | translate}}
          </div>

          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.BANK_TRANSFER"
          >
          {{'footer.bank_transfers' | translate}}
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState2 = true"
          (closed)="panelOpenState2 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">{{'footer.pay_with_ime_pay' | translate}}</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState2
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.WAY_TO_PAY"
          >
          {{'footer.ways_to_pay' | translate}}
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PAY_SERVICE"
          >
          {{'footer.pay_for_ime_pay_services' | translate}}
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PAY_ONLINE_IN_APPS"
          >
          {{'footer.pay_in_apps_and_online' | translate}}
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PAY_IN_STORE"
          >
          {{'footer.pay_in_stores' | translate}}
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PAY_THROUGH_CARD"
          >
          {{'footer.ime_pay_visa_card' | translate}}
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle disabled>
          <mat-expansion-panel-header>
            <div class="menus__title">
              <a
                routerLinkActive="active-link"
                [routerLink]="'../' + routerPath.COMING_SOON"
                rel="for business"
              >
              {{'footer.ime_pay_for_business' | translate}}</a
              >
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState3 = true"
          (closed)="panelOpenState3 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">{{'footer.company' | translate}}</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState3
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.ABOUT_US"
          >
          {{'footer.about_us' | translate}}
          </div>
          <div
          class="menus__subtitle"
          routerLinkActive="active-link"
          (click)="
            goToLink(ourAgentList)
          "
          >{{'footer.our_agents' | translate}}</div
        >
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.ACCESSIBILITY_STATEMENT"
          >
          {{'footer.accessibility_statement' | translate}}
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            (click)="
              goToLink(
                'https://www.linkedin.com/company/imepay-official/jobs'
              )
            "
            >{{'footer.career' | translate}}</div
          >
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.TERMS"
          >
          {{'footer.terms_conditions' | translate}}
          </div>
          <div
          class="menus__subtitle"
          routerLinkActive="active-link"
          [routerLink]="'../' + routerPath.SAFETY_AND_COMPLIANCE"
        >
        {{'footer.safety_compliance' | translate}}
        </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PRIVACY_POLICY"
          >
          {{'footer.privacy' | translate}}
          </div>

          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.GRIEVANCES"
          >
          {{'footer.grievances' | translate}}
          </div>
          <div
          class="menus__subtitle"
          routerLinkActive="active-link"
          [routerLink]="'../' + routerPath.ISMS_POLICY"
        >
        {{'footer.isms_policy' | translate}}
        </div>


        </mat-expansion-panel>
        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState4 = true"
          (closed)="panelOpenState4 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">Resources</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState4
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div
            class="menus__subtitle"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
          {{'footer.how_to' | translate}}
          </div>
          <div
            class="menus__subtitle"
            (click)="goToLink(commissionAndCharges)"
          >
          {{'footer.charges_commission' | translate}}
          </div>
          <div
            class="menus__subtitle"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
          {{'footer.help_support' | translate}}
          </div>
          <div
            class="menus__subtitle"
            (click)="goToLink('https://blog.imepay.com.np/')"
          >
          {{'footer.blog' | translate}}
          </div>
          <div
          class="menus__subtitle"
          (click)="goToLink('http://developer.imepay.com.np/')"
        >
        {{'footer.developers' | translate}}
        </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState5 = true"
          (closed)="panelOpenState5 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">{{'footer.contact' | translate}}</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState5
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="menus__subtitle">{{'footer.ntc_toll_free' | translate}}</div>
          <div class="menus__subtitle">{{'footer.ncell_toll_free' | translate}}</div>
          <div class="menus__subtitle">{{'footer.phone_numbers' | translate}}</div>
          <div class="menus__subtitle">{{'footer.email' | translate}}</div>
          <div class="menus__subtitle">{{'footer.whatsapp' | translate}}</div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="contents__social-icons">
      <div class="social--links">
        <a
          href="https://www.facebook.com/imepay"
          target="_blank"
          class="social-icons"
        >
          <ime-json-animator
            [options]="animationIconOption[0]"
          ></ime-json-animator>
        </a>
        <a
          href="https://www.youtube.com/channel/UCqg5Kde1gJ13Tr2GsqE9tNg"
          target="_blank"
          class="social-icons"
        >
          <ime-json-animator
            [options]="animationIconOption[1]"
          ></ime-json-animator>
        </a>
        <a
          href="https://www.instagram.com/imepay_official"
          target="_blank"
          class="social-icons"
        >
          <ime-json-animator
            [options]="animationIconOption[2]"
          ></ime-json-animator>
        </a>
        <a
          href="https://www.linkedin.com/company/imepay-official/"
          target="_blank"
          class="social-icons"
        >
          <ime-json-animator
            [options]="animationIconOption[3]"
          ></ime-json-animator>
        </a>
      </div>
    </div>
    <div class="contents__copy-right">
      <div
        class="copyRight"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1500"
      >
        <img
          src="assets/images/ime-digital-copyRight.svg"
          alt="logo of ime digital"
        />
        <span>{{'footer.copyright' | translate}}</span>
      </div>
    </div>
  </div>
</div>
