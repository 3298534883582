import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ROUTER_BASE_PATH } from 'src/app/shared/constants/router-path';
import { setTiltProp } from 'src/app/shared/service/utilsService/utility.service';

@Component({
  selector: 'ime-ways-to-pay',
  templateUrl: './ways-to-pay.component.html',
  styleUrls: ['./ways-to-pay.component.scss']
})
export class WaysToPayComponent implements OnInit {

  routerPath = ROUTER_BASE_PATH;

  tiltSettings = setTiltProp();
  tiltSettingsPopupImage = setTiltProp(true);

  constructor(private titleService: Title) {
    this.titleService.setTitle("Ways to Pay - Ime Pay")
   }
  imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg';
  ngOnInit(): void {
  }
}
