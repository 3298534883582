import { Component, OnInit } from '@angular/core';
import { ContactSupportComponent } from '../contact-support/contact-support.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'ime-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg';
  constructor(private _bottomSheet: MatBottomSheet, private _route: Router) { }

  ngOnInit(): void {
  }

  
  showHelpLine(){
    this._bottomSheet.open(ContactSupportComponent,{
      panelClass:'HL-dailog'
    });
  }
  goToPage(param){
    this._route.navigate([param]);
  }
}
