import { Component, Inject, OnInit } from '@angular/core';
import { YoutubeserviceService } from '../../service/youtube-service/youtube-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ime-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit {
  youtubeUrl: string;
  title: string;

  constructor(
    private _youtubeService: YoutubeserviceService,
    private _matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data
    ) {

   }
   close() {
      this._matDialogRef.close()
   }

   ngOnInit(): void {
     this.title = this.data?.videoTitle;
     this._youtubeService.embedUrlSource$.subscribe(
       url =>{
         this.youtubeUrl = url;
        }
      );
    }
  }
