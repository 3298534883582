import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { NotificatonService } from "../service/notificaton.service";

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private _notificationService: NotificatonService) {}

  statusCodeHandler(statusCode, errorMessage) {
    // this._notificationService.openSnackBar(errorMessage, "Dismiss", "3000");
  }

  handleError(error: HttpErrorResponse): void {
    let errorMessage =
      (error && error.error && error.error["responseDescription"])?error.error["responseDescription"] : "Somthing Went Wrong";
    this.statusCodeHandler(error.status, errorMessage);
  }
}
