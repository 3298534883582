<section class="PP-container">
  <div class="PP-container__content">
    <div class="desc-section">
      <div class="ime-heading-4 page-title">
        <span>Terms </span>
        <span>& Conditions</span>
        <br />

      </div>

      <div class="update-date">
        <div class="ime-heading-5">Last Updated</div>
        <p class="ime-text-2">September 13, 2023</p>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Welcome to IMEpay!</h5>
        <div class="desc ime-text-2">
          <p>
            Installing and registering yourself in IME Pay automatically indicates that you agree with the Terms and
            Conditions mentioned hereunder. These terms and conditions will be considered as an agreement between you
            and IME Digital Ltd (IME Pay) having registered office at IME Complex, Panipokhari, Kathmandu. Hence, kindly
            read the T&C carefully before proceeding.
          </p>
          <div class="info-table">
            <div class="info-table__heading">
              Definitions
            </div>
            <div class="info-table__content">
              <div class="info-table__column">
                <div class="info-table__row info-table__row__heading ime-heading-5">IME Digital</div>
                <div class="info-table__row ime-text-2">IME Digital Solution Ltd</div>
              </div>
              <div class="info-table__column">
                <div class="info-table__row info-table__row__heading">IME pay</div>
                <div class="info-table__row ime-text-2">The technology platform that enables the Wallet services managed
                  and operated by IME Digital.
                </div>
              </div>
              <div class="info-table__column">
                <div class="info-table__row info-table__row__heading">Customer</div>
                <div class="info-table__row ime-text-2">IME Pay wallet account holder.</div>
              </div>
              <div class="info-table__column">
                <div class="info-table__row info-table__row__heading">Merchant</div>
                <div class="info-table__row ime-text-2">Parties (e.g. shops/outlets) that accept payments using IME Pay.
                </div>
              </div>
              <div class="info-table__column">
                <div class="info-table__row info-table__row__heading">Wallet</div>
                <div class="info-table__row ime-text-2">Wallet account of the Customer - opened and maintained in IME
                  Pay.</div>
              </div>
              <div class="info-table__column">
                <div class="info-table__row info-table__row__heading">Agent</div>
                <div class="info-table__row ime-text-2">Parties (e.g. shops/outlets) that where customers can get over
                  the counter services of IME Pay.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Customers Liability</h5>
        <div class="desc ime-text-2">
          <ol>
            <li>
              Password and PIN associated with wallet will entirely be responsibility of Customer. Customer shall not
              disclose PIN/Password or permit possession of Wallet to any other person. Customer takes the full
              responsibility for any and all transactions made by the use of Wallet whether or not performed with
              his/her knowledge or authority.
            </li>
            <li>
              Customer shall not reveal 4-digit PIN/Password to any other person. Customer shall immediately notify IME
              Digital, if the confidentiality of PIN/Password is compromised. Customer should request IME Digital to
              block account, cancel PIN or reset it via IME Pay app or by contacting our helplines i.e. <a href="tel:+97701-4217600">01-4217600</a> or
              <a href="tel:+97716600-16-16-16">16600-16-16-16</a> but send written application along with their KYC documents within 24 hours after verbal
              request on call.
            </li>

            <li>
              The verification of customer reporting compromise of the pin/password will be done by customer service
              unit’s agents by asking random personal information provided during registration. If the information
              provided are valid then request made by customer are honored. In case of written request, customer service
              unit can ask for additional information via email or over the phone to verify the identity of the
              customer. The actual victim needs to contact IME Digital to report such issue. IME Digital shall not be
              liable, responsible or accountable in any way whatsoever on not honoring any reporting made by any person
              other than the actual victim.
            </li>

            <li>
              A customer shall operate only one wallet in IME pay with their KYC documents. Customers found to have
              registered themselves in multiple wallets or using the KYC documents of any individual other than theirs
              (for any reason whatsoever) shall be dealt with as per IME Digital’s discretion, which includes
              blocking/locking/holding of wallets or reporting to regulatory bodies. Even though wallet is not bound to
              a particular device, IME digital has made provision where by a customer cannot use more than 3 mobile
              wallets account in a single device in a 30-day period.
            </li>
            <li>
              KYC documents of a customer once registered in a wallet cannot be changed. Request to change KYC details
              and wallet ownership from one customer to another cannot be done. In such scenarios, customer will be
              required to register a new wallet. IME Digital shall re-verify KYC documents of customer’s once in every
              two years and may contact the customer if a wallet’s KYC needs to be updated.
            </li>
          </ol>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Terms of Service</h5>
        <div class="desc ime-text-2">
          <div>
            <ol>
              <li>
                Customers can avail IME Pay services 24/7 except for times when the App is going through a system
                upgrade, which will be notified to the customer with In-app notifications and on our social media
                channels in advance.
              </li>
              <li>
                Wallets in IME Pay found to be inactive for a long period of time usually more than 5 months may be
                suspended as per IME Digital’s discretion, which includes blocking/locking of wallets which can be re
                activated only after contacting IME Digital head office by customer and re- verifying the KYC of the
                customer.
              </li>
              <li>
                IME Digital is not bound to carry out the instruction given through IME Pay, if such instruction appear
                suspicious to IME Digital.
              </li>
              <li>
                IME Digital is under no obligation to honor any payment instruction unless there are sufficient fund in
                Wallet at the time of receiving such payment instruction. Any transaction initiated with sufficient
                funds are processed immediately and transaction status (success, failure or suspicious) are known to the
                customer within 30 seconds.
              </li>

              <li>
                Customer shall accept the IME Digital’s record of transactions as conclusive and binding for all
                purposes.
              </li>
              <li>
                Funds claimed to be mistakenly transferred by the customer via wallet/agent transfer methods shall not
                be processed for refund or wallet adjustment until verification by IME Digital and full consent from the
                receiver’s end. IME Digital may put a hold on the funds in question or wallet ID while doing so. IME
                Digital is entitled to reverse entries made in error without any prior consent from customer
              </li>
              <li>
                Any complain regarding scam/fraudulent activity will be evaluated by IME digital and if deemed necessary
                the wallet account that received complain can be put on hold. However, blocking the wallet or reversal
                of funds cannot be done based on customer complain or request. The request for blocking or reversal of
                funds can be done through regulatory body’s written letter authorizing IME Digital to do the same.
              </li>
              <li>
                IME Digital facilitates payment only and in no circumstance shall be responsible for the quantity,
                quality, suitability, safety or delivery of goods and services by Merchant. Customer understands that
                any issue or disputes regarding the warranty, guarantee, quality of goods and services and their
                exchange or refund will be addressed either by the manufacturer/ service provider or Merchant and
                Customer agrees to settle such issues and disputes directly with Merchant. However, IME Digital will
                assist Customer in resolving any issues with Merchant within 72 hours of receiving any complaints from
                Customer.
              </li>

              <li>
                IME Digital shall not be liable, responsible or accountable in any way whatsoever arising by any
                malfunction or failure of the electronic devices/system or on the failure or delay on IME Digital to act
                on the instruction given via this medium. IME Digital shall not be responsible for any loss or damage
                incurred or suffered by Customer as a result of non-acceptance of instructions given on IME Pay.
              </li>
              <li>
                Customer can view their transaction history from customer’s mobile application. If he/she requires
                additional details, then he/she can contact IME Digital solution limited and provide the request in
                written via email or letter. The email can be sent to <a href="mailto:info@imepay.com.np">info@imepay.com.np</a> or <a href="mailto:compliance@imepay.com.np">compliance@imepay.com.np</a>.
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Card Related</h5>
        <div class="desc ime-text-2">
          <ol>
            <li>
              <strong>SMS Alert Facilities:</strong><br />
              SMS alert facilities will be provided for every transaction conducted using the Card. These alerts are
              designed to keep track of transactions and enhance the security of Card usage. It is responsibility of the
              cardholder to ensure that the contact information is up to date to receive these alerts.
            </li>
            <li>
              <strong>Confidentiality and Security</strong><br />
              The Cardholder is responsible for maintaining the confidentiality of the Personal Identification Number
              (PIN) associated with the Card. PIN should not be disclosed to
              any other person or entity.
              The possession of the Card must be safeguarded at all times. The cardholder should not permit any
              unauthorized individual to possess or use the Card.
              The Cardholder assumes full responsibility for any transactions conducted using the Card, whether or not
              such transactions were made with their knowledge or authorization.
            </li>
            <li>
              <strong>Charges levied for use of Card</strong>
            </li>
            <div class="info-table">
              <div class="info-table__content">
                <div class="info-table__column">
                  <div class="info-table__row info-table__row__heading ime-heading-5"><strong>Type of Service</strong></div>
                  <div class="info-table__row ime-text-2"><strong>Amount (in NPR)</strong></div>
                </div>
                <div class="info-table__column">
                  <div class="info-table__row info-table__row__heading">Card Issuance Fee for 3 year</div>
                  <div class="info-table__row ime-text-2">-
                  </div>
                </div>
                <div class="info-table__column">
                  <div class="info-table__row info-table__row__heading">Card Issuance Fee Annual Instalment</div>
                  <div class="info-table__row ime-text-2">-</div>
                </div>
                <div class="info-table__column">
                  <div class="info-table__row info-table__row__heading">Re-issuance Fee (Damaged/Replacement)</div>
                  <div class="info-table__row ime-text-2">Rs. 200
                  </div>
                </div>
                <div class="info-table__column">
                  <div class="info-table__row info-table__row__heading">Card Blocking Fee
                  </div>
                  <div class="info-table__row ime-text-2">-</div>
                </div>
                <div class="info-table__column">
                  <div class="info-table__row info-table__row__heading">Renewal Fee</div>
                  <div class="info-table__row ime-text-2">Rs. 200</div>
                </div>
              </div>
            </div>
            <li>
             <strong> Precautionary Measures:</strong><br/>
             For safety, following precautionary measures should be applied by the cardholder:
             <ul>
              <li>Do not share your PIN with anyone.</li>
              <li>Keep your card in a safe place.</li>
              <li>Regularly change your PIN for added security.</li>
              <li>Carefully review transaction alerts and statements to identify any unauthorized transactions.</li>
              <li>If your card is lost or stolen, report it immediately.</li>
             </ul>
            </li>
            <li>
              <strong>Closure of Service</strong><br/>
              If any customer does not want to use the services of Card, then customer can lock their card form IME Pay itself at any point of time and initiate Permanent Block of Card form IME Pay itself. However, customers have to inform in verbal/written or any other possible means of communication to IME Pay for approval of the same. Then, immediately Card shall be blocked by the Card Team, Operations Team.
            </li>
            <li>
              <strong>Transaction Issues</strong><br/>
              If any issues are faced while performing transactions through Card, then customer can contact Customer Service Department or provide their queries at card@imepay.com.np. Similarly, customer can also resolve the issues faced by contacting through Helpdesk facility (Viber, WhatsApp, Zendesk.)
            </li>
          </ol>
        </div>
      </div>



      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Privacy Policy</h5>
        <div class="desc ime-text-2">
          <ol>
            <li>
              Unless required or requested by law or any government body, information pertaining to service provided to Customer or Wallet will be kept confidential.
            </li>
            <li>
              Information will not be provided to any third-party services without user’s consent. In case of users do not provide their information or consent for the usage of personal information or later on withdraw their consent for the usage of the personal information so collected, IME Digital reserves the right to, not provide the services or to withdraw the services for which the said information was sought.
            </li>
            <li>
              Privacy Policy associated with the use of IME Pay App will be stated in the company’s website: <a href="https://imepay.com.np/#/privacy-policy">https://imepay.com.np/#/privacy-policy</a>
            </li>
          </ol>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Risk Management</h5>
        <div class="desc ime-text-2">
          <ol>
            <li>
              As a method of risk management, IME Digital shall periodically monitor your transactions and reserves the right to terminate the membership, hold/block wallet, withdraw the privileges attached to Wallet at any time and to call upon Customer to surrender Wallet as per its discretion or, if it has a reason to believe that the customer has been involved in any violation of its T&C. IME Digital while doing so, may verify its suspicion through phone calls or personal contact with customers. IME Digital also reserves the right to report suspicious transaction to regulatory bodies as well as blocking or holding the wallet until such suspicions are not cleared either by customer or regulatory bodies.
            </li>
          </ol>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Indemnification</h5>
        <div class="desc ime-text-2">
          <ol>
            <li>
              Customer undertakes to indemnify IME Digital and to keep IME Digital indemnified against all losses, damage, cost or expenses incurred and sustained by IME Digital arising out of Customer’s failure to observe any of the terms and conditions herein mentioned.
            </li>
          </ol>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Wallet discontinuation</h5>
        <div class="desc ime-text-2">
          <ol>
            <li>
              Customer’s wishing to block their wallet shall provide written instructions along with their KYC documents to IME Digital and in case of a verbal request provide the same within 24 hours after the request. Wallet block request dropped in Social media platforms will not be entertained. Funds need to be completely utilized by the customer when requesting to block their wallets. However, in some situation IME digital can transfer the funds between customers multiple wallets on customers written request.
            </li>
            <li>
              Upon termination of membership or withdrawal of privileges of Wallet for any reason whatsoever, customer shall be liable for payment of the bills arising out of use of Wallet till it is not terminated.
            </li>
            <li>
              IME Digital may temporarily or permanently block Wallet for breach of IME Pay Terms and Conditions by Customer.
            </li>
            <li>
              Reward points, in any case, will not be transferrable from one wallet to another.
            </li>
          </ol>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Information and Grievance Handling</h5>
        <div class="desc ime-text-2">
          <ol>
            <li>IME Digital’s Information and Grievance Handling Desk (Customer Service Unit) shall be the first point of contact for the customers. Issues/ complains put forward by the customer will be directed to concerned departments by the Information and Grievance Handling Desk if necessary. Information and Grievance Handling Desk (Customer Service Unit) can be reached by calling <a href="tel:+97701-4217600">01-4217600</a> or <a href="tel:+97716600-16-16-16">16600-16-16-16</a> or sending email at <a href="mailto:info@imepay.com.np">info@imepay.com.np</a>.
            </li>
            <li>
              Any problem reported by customer related to transaction processing/transaction failure will be handled by Customer Service unit. Customer can report such issues via phone, email, chat. Customer will be made aware about the reason for the issue occurred and time needed to resolve the issue. In case of transaction failure customer is entitled to receive refund up to the amount of the transaction. The refund will be initiated within 24 hours. However, in case some third party like BFI’s, VISA or government entities are involved the refund process might take more than 24 hours.
            </li>
            <li>
              All or any disputes arising out in relation to the terms and conditions of this Agreement shall be settled amicably through mutual discussion.
            </li>
          </ol>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Charges and Commission/Limit related</h5>
        <div class="desc ime-text-2">
        <ol>
          <li>
            Commission and/or service charge shall be levied by IME Digital as applicable. Wallet shall be subject to the applicable charges as per IME Digital’s schedule of charges as revised from time to time. IME Digital shall always be entitled to recover any charges, expenses, fees, commission, mark-up, and penalties, withholding taxes levied by Government Department or authorities with or without notice to Customer.
          </li>
          <li>
            Daily and monthly usage limit of Wallet will be set in accordance with the guidelines issued by Nepal Rastra Bank and the policy of IME Digital. Charges and commission related to each type of transaction will be stated in the IME Pay App while commencing the transaction or can be referred to the IME Pay’s website: <a href="https://blog.IME Pay.com.np/wp-content/uploads/2022/08/Charge-and-Commission-Details.pdf">https://blog.IME Pay.com.np/wp-content/uploads/2022/08/Charge-and-Commission-Details.pdf</a>.
          </li>
          <li>
            The limit per transaction will be as per Nepal Rastra Banks circular and directives.
          </li>
        </ol>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Miscellaneous</h5>
        <div class="desc ime-text-2">
          <ol>
            <li>
              Agreeing to these terms and conditions shall conclude that you are capable and eligible to get into an agreement and shall stop the use of the wallet if you disagree with the T&C.
            </li>
            <li>
              Use of IME Pay shall be subject to these terms and conditions, IME Digital’s prevailing rules and regulations and other conditions provided in its website – <a href="http://imepay.com.np/#/terms">www.IME Pay.com.np</a> (“IME Pay Terms and Conditions”).
            </li>
            <li>
              IME Digital reserves the right to amend IME Pay Terms and Conditions at any time without prior notice to Customer and such amended terms and conditions shall be binding on Customer. It will be the customer’s responsibility to review these T&C for any updates or changes.
            </li>
          </ol>
        </div>
      </div>
    </div>

    <div class="menu-section">
      <ul>
        <li class="ime-text-2" routerLink="../privacy-policy">Privacy Policy</li>
        <li class="ime-text-2 active">Terms & Conditions</li>
      </ul>
    </div>
  </div>
</section>

<ime-footer></ime-footer>