    <div class="outer-wrapper">
      <ime-side-nav-mobile>
      </ime-side-nav-mobile>
    </div>


    <div id="overlay" *ngIf="loaderService.showLoadingIcon | async">
      <span id="nav">
          <div class="submit-message">
            <mat-spinner
            mode="indeterminate">
          </mat-spinner>
          </div>
      </span>
    </div>
    