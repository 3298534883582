import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'ime-get-app-message',
  templateUrl: './get-app-message.component.html',
  styleUrls: ['./get-app-message.component.scss']
})
export class GetAppMessageComponent implements OnInit {
  animationIconOption: AnimationOptions;
  constructor() { 
    this.animationIconOption = {
      path:'assets/images/json-image/icons-message-sent.json',
      autoplay:true,
      loop:true
    }
  }

  ngOnInit(): void {
  }
}
