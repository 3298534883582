<div *ngIf="showHeader">
  <ime-header
    [inputSideNav]="sideNav"
    (closeSidenavEvent)="hideHeader($event)"
  ></ime-header>
</div>
<mat-sidenav-container>
  <mat-sidenav #sideNav mode="push" position="end">
    <div class="sideNav-container">
        <div class="sideNav-container__header">
            <div class="sideNav-container__header__close" (click)="closeMenu()">
              <img src="assets/icon/close.svg" alt=""  />
            </div>
          </div>
      <div class="sideNav-container__contents">
        <div class="sideNav-container__contents__content">
          <div class="toolbar-container__buttons">
            <div class="menu-title" >Select Language</div>
              <button mat-menu-item (click)="selectLanguage('en')">
                <div class="language__card">
                  <div class="language__card__content">
                    <div class="language__card__icon"><img src="/assets/icon/translate/en.png" alt="" srcset=""></div>
                    <div class="language__card__text">English</div>
                  </div>
                  <div class="language__card__tick">
                      <mat-icon color="accent" *ngIf="language == 'en'">check</mat-icon>
                  </div>
                </div>

              </button>
              <button mat-menu-item (click)="selectLanguage('np')">
                <div class="language__card">
                  <div class="language__card__content">
                    <div class="language__card__icon language__card__icon-np"><img src="/assets/icon/translate/np.png" alt="" srcset=""></div>
                    <div class="language__card__text">नेपाली</div>
                  </div>
                  <div class="language__card__tick">
                    <mat-icon color="accent" *ngIf="language == 'np'">check</mat-icon>
                  </div>
                </div>
              </button>
            <!-- <button (click)="toggleLanguage()">Toggle</button> -->
          </div>
          <div class="sideNav-container__contents__content__menu menu">
            <div class="{{menus.class}}" *ngFor="let menus of menuItems"> 
                <div class="menu-title" >{{menus.menuItem}}</div>
              <div class="sub-menu-title" *ngFor="let options of menus.subMenu">
                <a [routerLink]="options.link" (click)="closeMenu()" routerLinkActive="active-link" class="sub-menu-title__link" >  {{options.name}}</a>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sideNav-container__footer">
        <div class="sideNav-container__contents__footer__btn">
          <ime-btn-get-app
          (click)="closeMenu()"
            [data]="{ getApp: false, login: true }"
          ></ime-btn-get-app>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
