<!-- <header [@toggle]="isVisible ? 'visible' : 'hidden'"> -->
<header [ngClass]="{ 'header-scrolled': changeHeaderCss }">
  <mat-toolbar class="toolbar-container">
    <div class="toolbar-container__logo-menus">
      <div class="header-logo">
        <img
          class="logo"
          src="assets/logo/ime-main.svg"
          alt="logo of IMEPay"
          [routerLink]="'/'"
        />
      </div>
      <div class="container-menus" *ngIf="showMenu">
        <div class="menu-list" *ngFor="let menu of headerMenuItems">
          <div class="menus">
            <a mat-flat-button class="menu" *ngIf="!menu.redirect"
              >{{ menu.menuItem
              }}<img
                *ngIf="menu.subMenu"
                src="assets/icon/down.svg"
                alt=""
                srcset=""
            /></a>
            <a
              mat-flat-button
              class="menu menu-redirect"
              *ngIf="menu.redirect"
              (click)="goToLink('https://blog.imepay.com.np/become-an-ime-agent/')"
              >{{ menu.menuItem
              }}<img
                *ngIf="menu.subMenu"
                src="assets/icon/down.svg"
                alt=""
                srcset=""
            /></a>
            <div [ngClass]="menu.class" *ngIf="menu.subMenu">
              <div class="sub-menu__box">
                <a
                  *ngFor="let menuItem of menu.subMenu"
                  class="sub-menu__box__link"
                  routerLink="{{ menuItem.link }}"
                  routerLinkActive="active-link"
                >
                  <a>{{ menuItem.name }}</a>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toolbar-container__buttons">
      <ime-btn-get-app [data]="buttonRequired"></ime-btn-get-app>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <div *ngIf="language == 'en'">
          <div class="language__card">
            <div class="language__card__icon"><img src="/assets/icon/translate/en.png" alt="" srcset=""></div>
            <div class="language__card__text">English</div>
          </div>
        </div>
        <div *ngIf="language == 'np'">
          <div class="language__card">
            <div class="language__card__icon language__card__icon-np-1"><img src="/assets/icon/translate/np.png" alt="" srcset=""></div>
            <div class="language__card__text">नेपाली</div>
          </div>
        </div>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="selectLanguage('en')">
          <div class="language__card">
            <div class="language__card__icon"><img src="/assets/icon/translate/en.png" alt="" srcset=""></div>
            <div class="language__card__text">English</div>
          </div>
        </button>
        <button mat-menu-item (click)="selectLanguage('np')">
          <div class="language__card">
            <div class="language__card__icon language__card__icon-np"><img src="/assets/icon/translate/np.png" alt="" srcset=""></div>
            <div class="language__card__text">नेपाली</div>
          </div>
        </button>
      </mat-menu>
      <!-- <button (click)="toggleLanguage()">Toggle</button> -->
    </div>
    <div class="ham-menu" (click)="openMenu()">
      <img src="assets/icon/menu-icon.svg" alt="" />
    </div>
  </mat-toolbar>
</header>
