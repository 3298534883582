import { AbstractControl } from '@angular/forms';
import { PATTERNS } from '../../constants/patterns';

export const validateMobile= (control: AbstractControl): { [key: string]: any } | null => {

    if(!control.value) return;
    const mobilePattern = new RegExp(PATTERNS.MOBILE_NUMBER);
    if(!mobilePattern.test(control.value)) return { 'msg': 'Enter a valid mobile number'}

}
