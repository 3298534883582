import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ObserveElementService } from 'src/app/shared/service/observe-element/observe-element.service';
import { setTiltProp } from 'src/app/shared/service/utilsService/utility.service';
import { ROUTER_BASE_PATH } from '../../shared/constants/router-path';


@Component({
  selector: 'ime-pay-in-app-online',
  templateUrl: './pay-in-app-online.component.html',
  styleUrls: ['./pay-in-app-online.component.scss']
})
export class PayInAppOnlineComponent implements OnInit {

  @ViewChild("brandsDiv") brandsDiv: ElementRef;

  tiltSettings = setTiltProp();
  tiltSettingsPopupImage = setTiltProp(true);
  routerPath = ROUTER_BASE_PATH;
  constructor(private _elementObserver: ObserveElementService, private titleService: Title) {
    this.titleService.setTitle("Pay in Apps and Online - Ime Pay")
   }

  imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg';
  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this._elementObserver.observeElement(this.brandsDiv, "-10%")
    .subscribe((res) =>{
      if(res){
        this.brandsDiv.nativeElement.classList.add("container-trusted-brands__right__image-2__animate");
      }
    })
  }
  externalLink(url){
    window.open(url);
  }
}
