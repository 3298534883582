import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObserveElementService {

  constructor() { }
  observeElement(element: ElementRef,showBeforeMargin:string): Observable<boolean> {
    return new Observable(observer => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        observer.next(entries);
      },{  
        rootMargin: showBeforeMargin
    });
  
      intersectionObserver.observe(element.nativeElement);
  
      return () => { intersectionObserver.disconnect(); };
    }).pipe(
      mergeMap((entries: IntersectionObserverEntry[]) => entries),
      map(entry => entry.isIntersecting),
      distinctUntilChanged()
    );
  }
}
