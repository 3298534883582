import { ChangeDetectorRef, Component, OnInit } from "@angular/core";

@Component({
  selector: "ime-contact-support",
  templateUrl: "./contact-support.component.html",
  styleUrls: ["./contact-support.component.scss"],
})
export class ContactSupportComponent implements OnInit {
  tollFreeNo = 16600161616;
  phone = 9803556655;
  email = 'info@imepay.com.np';
  copiedStatus = [false,false,false,false,false];
  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  notify(argIndex) {
    this.copiedStatus[argIndex] = true;
    setTimeout(() => {
      this.copiedStatus[argIndex] = false;
      this._changeDetectorRef.detectChanges();
    }, 600);
  }
}
