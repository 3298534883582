import { Component, OnInit } from '@angular/core';
import { ContactSupportComponent } from '../contact-support/contact-support.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'ime-pagenotfound',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PagenotfoundComponent implements OnInit {

  constructor(private _bottomSheet: MatBottomSheet,private _route: Router) { }
  imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg';
  ngOnInit(): void {
  }

  showHelpLine(){
    this._bottomSheet.open(ContactSupportComponent,{
      panelClass:'HL-dailog'
    });
  }
  goToPage(param){
    this._route.navigate([param]);
  }
}
