import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'ime-lifestyle-wallet-card',
  templateUrl: './lifestyle-wallet-card.component.html',
  styleUrls: ['./lifestyle-wallet-card.component.scss']
})
export class LifestyleWalletCardComponent implements OnInit {
  
    
  customOptions :OwlOptions;
  slides;
  @Input('data') data;
  constructor() { }
  ngOnInit(): void {
    this.slides = this.data.slides;
    this.customOptions = this.data.config;
  }

}
