<section class="PP-container">
  <div class="PP-container__content">
    <div class="desc-section">

      <div class="ime-heading-4 page-title">
        <span>Privacy</span>
        <span>Policy</span>
      </div>

      <div class="update-date">
        <div class="ime-heading-5">Last Updated</div>
        <p class="ime-text-2">September 20, 2021</p>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Commitment to Privacy</h5>
        <div class="desc ime-text-2">
          <p>
            We at IME pay, are committed towards protecting the privacy of our
            customers using policies regarding the collection, usage, and
            disclosure of the information provided to the customer. IME Digital
            and all of its staff members shall be committed to respect and
            protect the privacy of its customers.
          </p>

          <p>
            All the personal information about the customers provided on
            transaction forms, application forms, annual reports, etc., shall be
            deemed confidential. Any such information collected from the
            customer shall be in possession of the company and be kept
            confidential. It shall be passed on to a statutory body only in
            accordance with the existing law. Further, staff members, officials,
            or the management body shall not disclose any information provided
            to the Financial Information Unit, NRB nor divulge the
            facts/confidentiality of the document that has/had come into
            knowledge during investigation and inquiry or during the execution
            of own duties to any other persons including the customers through
            any means except under the circumstances as may be required by the
            prevailing laws.
          </p>
          <p>
            Personally identifiable information includes all information to
            identify an individual user.
          </p>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">The information we collect</h5>
        <div class="desc ime-text-2">
          <p>
            Information is collected as per the Directives made by Nepal Rastra
            Bank.
          </p>

          <ul>
            <li>
              Upon registering wallet yourself as an Unverified User, and/or to
              access services, users will only need to provide their Mobile
              Number and Full Name.
            </li>
            <li>
              If any Agency/Firm/Company, is being registered as An Agent /
              Distributor / SubAgent, Merchant, a physical/digital form needs to
              be filled up, agreement signed and submitted to IME Digital.

              <p>The details are collected:</p>
              <ul>
                <li>Mobile Number</li>
                <li>
                  Name of the Agent Business (only registered business name will
                  be valid not individual person)
                </li>
                <li>Personal details of the directors</li>
                <li>Business Type</li>
                <li>Registration Certificate</li>
                <li>
                  Address (State, District, Municipality/Village Municipality,
                  Ward Number, City, Street/Tole)
                </li>
                <li>Secondary contact details</li>
                <li>Bank Details (Optional)</li>
                <li>Business Document PAN, MOA, AOA</li>
                <li>License (if any)</li>
              </ul>
            </li>

            <li>
              If someone is being enrolled, as a Verified User, a
              physical/digital form needs to be filled up and submitted at the
              agent location, which in turn is forwarded to us digitally or
              physically.
              <p>Personal information collected and held us may include:</p>
              <ul>
                <li>Mobile Number</li>
                <li>First, middle and last Name</li>
                <li>Gender</li>
                <li>Date Of Birth</li>
                <li>Nationality</li>
                <li>Father/Husband Name</li>
                <li>Grandfather Name</li>
                <li>Marital Status</li>
                <li>Occupation</li>
                <li>Email</li>
                <li>
                  Current and Permanent Address (State, District,
                  Municipality/Village Municipality, Ward Number)
                </li>
                <li>
                  Proof of identity (citizenship, Voters ID, passport, valid
                  driving license)
                </li>
              </ul>
            </li>
          </ul>

          <p>
            IME Digital collects, stores, processes the following types of
            sensitive personal information such as a password, financial
            information (including details of bank account details), and
            location detail for providing our products, services via Mobile
            phone and for use of our website.
          </p>

          <p>
            We may also hold information related to our customer’s utilization
            of our services the use of our mobile app which may include the
            following:
          </p>

          <div>
            <ul>
              <li>
                <strong> Camera / Gallery Photo</strong>
                <p>
                  This feature is used to capture images while filling out KYC
                  documents and updating profile pictures within the app. The
                  images are stored in the server and will be shared with other
                  IME Pay users to verify identity while sending and requesting
                  money over the IME Pay application.
                </p>
              </li>

              <li>
                <strong>Network State and Internet Access</strong>
                <p>
                  The application connects to a dedicated API to complete
                  transactions services provided within. It uses these
                  permissions to check if there is any connection to the
                  internet as well as to connect to the internet if not
                  connected.
                </p>
              </li>

              <li>
                <strong>Read Contacts</strong>
                <p>
                  The application accesses the user’s phone contacts to select a
                  contact so that the phone number of contacts could be
                  populated in the IME Pay app instead of having to manually
                  type phone numbers while using various services of the IME Pay
                  application. Contact information is not stored in the server
                  and is not shared via the internet.
                </p>
              </li>

              <li>
                <strong>User SMS</strong>
                <p>
                  App is able to send and receive SMS through user’s device to
                  forward transaction request and confirm transaction status
                  respectively whenever there is no internet available. The user
                  bears sent SMS charge based on the tariff of network provider.
                  Receiving SMS is however free of cost to the user.
                </p>
              </li>

              <li>
                <strong> Precise Location Permission (non-continuous)</strong>
                <p>
                  Certain feature like Agent locator requires access to location
                  service to display near agents in the map.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">HOW WE USE THE PERSONAL INFORMATION WE COLLECT</h5>
        <div class="desc ime-text-2">
          <p>
            Our primary purpose in collecting personal information is to provide
            you with a safe, smooth, efficient, fun and customized experience.
          </p>
          <p>We may use your personal information to:</p>
          <div>
            <ul>
              <li>Verify user’s identity</li>
              <li>Complete transaction effectively as per user’s request</li>
              <li>Communicate about changes to our services</li>
              <li>
                Respond to user’s queries in relation to service or assistance
              </li>
              <li>Protect from unauthorized use of the wallet</li>
              <li>
                Promote and market our products and services, which we think
                could be of interest to user and may benefit them.
              </li>
              <li>
                Safeguard against fraud, money laundering, and misuse of account
                incorporating necessary identity verification
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Disclosure of information</h5>
        <div class="desc ime-text-2">
          <p>
            User’s information will be kept confidential and will not be
            disclosed to other parties except required by the regulator and/or
            users agree for the disclosure. We may keep a log of the activities
            performed by users and websites using various internet techniques
            such as web cookies, server log files, etc. for analytical purposes
            and for analysis of the amiability of various features on our site.
            This information may be used to provide users with a better
            experience with our platform along with evidentiary purposes.
          </p>
          <p>
            In case of users do not provide their information or consent for the
            usage of personal information or later on withdraw their consent for
            the usage of the personal information so collected, IME Digital
            reserves the right to, not provide the services or to withdraw the
            services for which the said information was sought.
          </p>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">Criteria For Account Deletion & Data Retention</h5>
        <div class="desc ime-text-2">
          <ol>
            <li><strong>Scenario Based Account Deletion</strong>Scenario Based Account Deletion</li>
            <ol type="a">
              <li> <strong>Case Nos. #. In Active Accounts</strong></li>
              Those accounts/wallets which have been
              passive or inactive since long time, like ranging from 6 months to 3 years. Wallet
              Services will be deleted. Considering following parameters wallet are supposed to be In
              active state.
              <ol type="i">
                <li> <strong>Balance Nil / or Zero</strong></li>
                For deletion of wallet account, there should not have any
                remaining balances into particular wallet. All Balances are supposed to be NIL.

                <li> <strong>Transaction Pending</strong></li>
                No any transactional activity should be performed or
                pending associated with the particular wallet. All Financial Transactions should
                have been completed or resolved before wallet account is deleted.
                <li><strong>Fraud or Misuse</strong></li>
                Any wallet accounts found to be involved in any scams or
                fraudulent activities or misuse of the services may be deleted to protect the other
                users and safeguard the platform integrity.
                <li><strong>Security Breaches</strong></li>
                Any wallet accounts identified or suspected in engaging the
                security breaches or noticed any kind of involvement in unauthorized access or
                services or roles wallet services may delete to prevent further harm, financial
                risks and mitigate risks.
                <li> <strong>Legal Requirements</strong></li>
                Any wallet services must comply with the legal obligations
                regarding data deletion and retention. Accounts may delete if required by law or
                regulation. With response from court order or law enforcement request wallet
                services will be delete.
                <li><strong>Termination of Services</strong></li>
                Any wallet services IMEPAY decides to discontinue its
                operations or cease providing services, it may delete all users account wallets
                after providing a notice and facilitating the transfer or withdrawal of funds.
                <li><strong>User Own Request</strong></li>
                Upon the written request letter from the users along with
                their identification document copy (Citizenship, Passport, Licensee etc)
              </ol>

              <li><strong>Case Nos. #. Accounts/Data Retention</strong></li>

            </ol>
            <li><Strong>Scenario Based Data Retention</Strong></li>
            <strong>Retained Data:</strong> Though a wallet account is closed, certain data may still be
            retained on the IMEPAY server. This could include the log of; transactions history,
            account details, and other relevant user information.
            <ol type="I">
              <li><strong>NRB Guidelines</strong></li>
              Data retention procedure is governed by the guidelines
              as set by NRB to preserve the user data for a certain specific number of
              time frame. As per NRB unified directives No.13 /2080 Under Consumer
              Rights Protection Section Point No. 7 Describes that data should be
              preserved at least for 5 years digitally. These regulations endure
              compliance with government standards regarding data retention and
              privacy.
              <li><strong>Transaction History</strong></li>
              Ranging form several months to years IMEPAY
              typically retain the record of users’ transactions history. As reference for
              customer support inquiries such data and accounts are retained and
              parked at our back up server. Here we prioritize the details related with:
              Account information, verification documents, financial data, legal and
              compliance obligations, data back up and security.
            </ol>
          </ol>
          <!-- <p>
            User’s information will be kept confidential and will not be
            disclosed to other parties except required by the regulator and/or
            users agree for the disclosure. We may keep a log of the activities
            performed by users and websites using various internet techniques
            such as web cookies, server log files, etc. for analytical purposes
            and for analysis of the amiability of various features on our site.
            This information may be used to provide users with a better
            experience with our platform along with evidentiary purposes.
          </p> -->
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">CHANGES TO OUR PRIVACY POLICY</h5>
        <div class="desc ime-text-2">
          <p>
            Our Privacy Policy may change from time to time. Any changes in our
            privacy policy will be revised and the updated date will be posted,
            at the bottom of this page. However, if there is a significant
            change in the policy, we will provide a more prominent notice
            through SMS, push notifications, email, etc.
          </p>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">How to contact us</h5>
        <div class="desc ime-text-2">
          <p>
            If you have questions or concerns regarding this privacy policy, or any feedback pertaining to your privacy
            and the Services that you would like us to consider, please email us at
            <strong>privacy@imepay.com.np</strong>.
          </p>

          <p>
            <strong>“Your consent to use IME Pay will adhere to above mentioned privacy policy.”</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="menu-section">
      <ul>
        <li class="ime-text-2 active">Privacy Policy</li>
        <li class="ime-text-2" routerLink="../terms">Terms & Conditions</li>
      </ul>
    </div>
  </div>
</section>

<ime-footer></ime-footer>