import { Component } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";
import { EXTERNAL_LINKS } from "../../constants/constants";
import { ROUTER_BASE_PATH } from '../../constants/router-path';

@Component({
  selector: "ime-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  animationIconOption :AnimationOptions [];
  sisaImgUrl = 'assets/images/sisa.png';
  amtivoImgUrl = "assets/images/amtivocertification.png";
  panelOpenState1 = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
  panelOpenState4 = false;
  panelOpenState5 = false;

  commissionAndCharges = EXTERNAL_LINKS.CHARGE_N_COMMISION;
  ourAgentList = EXTERNAL_LINKS.OUR_AGENTS;

  constructor() {
    this.animationIconOption = [
      {
        path: "assets/images/json-image/icons-facebook.json",
        loop: false,
        autoplay: false,
      },
      {
        path: "assets/images/json-image/icons-youtube.json",
        loop: false,
        autoplay: false,
      },
      {
        path: "assets/images/json-image/icons-instagram.json",
        loop: false,
        autoplay: false,
      },
      {
        path: "assets/images/json-image/icons-linkedin.json",
        loop: false,
        autoplay: false,
      },
    ];
  }
  routerPath = ROUTER_BASE_PATH;

  goToLink(url) {
    window.open(url, "_blank");
  }
 
}
