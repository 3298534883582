  <owl-carousel-o [options]="customOptions" #owlCar >
      <ng-template carouselSlide   *ngFor="let slide of slides" >
        <div class="card-container" >
          <div  class="container-lifestyle-wallet" [style.background]="slide.backgroundColor">
            <span class="ime-heading-3">{{slide.text}}</span>
          <img [src]="slide.imgSrc" [class]="slide.imgClass" alt="" srcset="">
        </div>
        </div>
      </ng-template >
</owl-carousel-o>
