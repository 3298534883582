import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import * as CryptoJS from "crypto-js";
import { LoaderService } from "../service/loader.service";

@Injectable()
export class SmsInterceptor implements HttpInterceptor {
  SECRET_KEY = "Yn3hT8LBBg7Gaqp9MYH";
  private allRequests: HttpRequest<any>[] = [];
  constructor(private _loaderService: LoaderService) {}

  encrypt(value: string): string {
    return CryptoJS.TripleDES.encrypt(value, this.SECRET_KEY).toString();
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.allRequests.indexOf(req);
    if (i >= 0) {
      this.allRequests.splice(i, 1);
    }
    this._loaderService.showLoadingIcon.next(this.allRequests.length > 0);
  }

  generateSignature(requestBody) {
    return this.encrypt(requestBody);
  }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this._loaderService.showLoadingIcon.next(true);
    const newHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      signature: this.generateSignature(request.body),
    });
    let clone = request.clone({
      headers: newHeaders,
    });

    return next.handle(clone).pipe(
      map((response) => {
        return response;
      }),
      finalize(() => this.removeRequest(request)) ,
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
