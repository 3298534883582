import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ROUTER_BASE_PATH } from "../../shared/constants/router-path";

@Component({
  selector: 'ime-isms-policy',
  templateUrl: './isms-policy.component.html',
  styleUrls: ['./isms-policy.component.scss']
})
export class IsmsPolicyComponent implements OnInit {
  constructor(private titleService: Title) {
    this.titleService.setTitle("ISMS Policy - ImePay")
  }

  ngOnInit(): void {
  }
  routerPath = ROUTER_BASE_PATH;
}
