import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {ROUTER_BASE_PATH} from "../../shared/constants/router-path";

@Component({
  selector: 'ime-safety-and-compliance',
  templateUrl: './safety-and-compliance.component.html',
  styleUrls: ['./safety-and-compliance.component.scss']
})
export class SafetyAndComplianceComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle("Safety & Compliance - ImePay")
   }
  routerPath = ROUTER_BASE_PATH;

  ngOnInit(): void {
  }

}
