import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingPageComponent } from "./landing-page.component";
import { HowItWorksComponent } from "./how-it-works/how-it-works.component";
import { RemittanceComponent } from "./remittance/remittance.component";
import { WalletTransferComponent } from "./wallet-transfer/wallet-transfer.component";
import { WaysToPayComponent } from "./ways-to-pay/ways-to-pay.component";
import { BankTransferComponent } from "./bank-transfer/bank-transfer.component";
import { PayInAppOnlineComponent } from "./pay-in-app-online/pay-in-app-online.component";
import { PayInStoreComponent } from "./pay-in-store/pay-in-store.component";
import { ImePayVisaCardComponent } from "./ime-pay-visa-card/ime-pay-visa-card.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./terms-conditions/terms-conditions.component";
import { GetAppComponent } from "./get-app/get-app.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ROUTER_BASE_PATH } from "../shared/constants/router-path";
import { AccessibilityStatementComponent } from "./accessibility-statement/accessibility-statement.component";
import { PayServicesComponent } from "./pay-services/pay-services.component";
import { SafetyAndComplianceComponent } from "./safety-and-compliance/safety-and-compliance.component";
import {GrievanceComponent} from "./grievance/grievance.component";
import { IsmsPolicyComponent } from "./isms-policy/isms-policy.component";

const routes: Routes = [
  { path: "", component: LandingPageComponent },
  {
    path: ROUTER_BASE_PATH.HOW_IT_WORKS,
    component: HowItWorksComponent,
  },
  {
    path: ROUTER_BASE_PATH.REMITTANCE,
    component: RemittanceComponent,
  },
  {
    path: ROUTER_BASE_PATH.WALLET_TRANSFER,
    component: WalletTransferComponent,
  },
  {
    path: ROUTER_BASE_PATH.BANK_TRANSFER,
    component: BankTransferComponent,
  },
  {
    path: ROUTER_BASE_PATH.WAY_TO_PAY,
    component: WaysToPayComponent,
  },
  {
    path: ROUTER_BASE_PATH.PAY_SERVICE,
    component: PayServicesComponent,
  },
  {
    path: ROUTER_BASE_PATH.PAY_ONLINE_IN_APPS,
    component: PayInAppOnlineComponent,
  },
  {
    path: ROUTER_BASE_PATH.PAY_IN_STORE,
    component: PayInStoreComponent,
  },
  {
    path: ROUTER_BASE_PATH.PAY_THROUGH_CARD,
    component: ImePayVisaCardComponent,
  },

   {
    path:  ROUTER_BASE_PATH.GET_APP,
    component: GetAppComponent,
  },

  {
    path: ROUTER_BASE_PATH.PRIVACY_POLICY,
    component: PrivacyPolicyComponent,
  },
  {
    path: ROUTER_BASE_PATH.TERMS,
    component: TermsConditionsComponent,
  },
  {
    path: ROUTER_BASE_PATH.ABOUT_US,
    component: AboutUsComponent,
  },
  {
    path: ROUTER_BASE_PATH.ACCESSIBILITY_STATEMENT,
    component: AccessibilityStatementComponent,
  },
  {
    path: ROUTER_BASE_PATH.SAFETY_AND_COMPLIANCE,
    component: SafetyAndComplianceComponent,
  },
  {
    path: ROUTER_BASE_PATH.GRIEVANCES,
    component: GrievanceComponent,
  },
  {
    path: ROUTER_BASE_PATH.ISMS_POLICY,
    component: IsmsPolicyComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingPageRoutingModule {}
