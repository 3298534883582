import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class NotificatonService {

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  constructor(private _snackBar: MatSnackBar) { }

  openSnackBar(messagae, actionName?, displayDuration?) {
    this._snackBar.open(messagae, actionName, {
      duration: displayDuration ? displayDuration : 10000,
      panelClass: "ime-snackbar",
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
