import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor( private _domSanitizer: DomSanitizer){
  }
  transform(value: string, type:string): SafeResourceUrl {
    if(type == 'url' && value !== undefined){
      return this._domSanitizer.bypassSecurityTrustResourceUrl(value);
    }else if(type == 'html' && value !== undefined){
      return this._domSanitizer.bypassSecurityTrustHtml(value)
    }
  }

}
