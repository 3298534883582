import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';
import { setTiltProp } from 'src/app/shared/service/utilsService/utility.service';

@Component({
  selector: 'ime-pay-services',
  templateUrl: './pay-services.component.html',
  styleUrls: ['./pay-services.component.scss']
})
export class PayServicesComponent implements OnInit {

  tiltSettingsPopupImage = setTiltProp(true);
  tiltSettingsMainImage = setTiltProp();
  animationIconOptions: AnimationOptions[];
  constructor(private titleService: Title) {
    this.titleService.setTitle("Pay for IME Pay Services - IME Pay");
    this.animationIconOptions = [
      {
        path:"assets/images/json-image/entertainment.json",
        loop:true,
        autoplay: true
      },
      {
        path:"assets/images/json-image/entertainment-mobile.json",
        loop:true,
        autoplay: true
      }
    ]
   }

  ngOnInit(): void {
  }

}
