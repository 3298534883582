import { GetAppComponent } from './get-app/get-app.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { LandingPageComponent } from './landing-page.component';
import { MaterialsModule } from '../materials/materials.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LifestyleWalletCardComponent } from './lifestyle-wallet-card/lifestyle-wallet-card.component';
import { RemittanceComponent } from './remittance/remittance.component';
import { WalletTransferComponent } from './wallet-transfer/wallet-transfer.component';
import { WaysToPayComponent } from './ways-to-pay/ways-to-pay.component';
import { BankTransferComponent } from './bank-transfer/bank-transfer.component';
import { PayInAppOnlineComponent } from './pay-in-app-online/pay-in-app-online.component';
import { PayInStoreComponent } from './pay-in-store/pay-in-store.component';
import { ImePayVisaCardComponent } from './ime-pay-visa-card/ime-pay-visa-card.component';
import { AngularTiltModule } from 'angular-tilt';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AccessibilityStatementComponent } from './accessibility-statement/accessibility-statement.component';
import { PayServicesComponent } from './pay-services/pay-services.component';
import { SafetyAndComplianceComponent } from './safety-and-compliance/safety-and-compliance.component';
import { GrievanceComponent } from './grievance/grievance.component';
import { IsmsPolicyComponent } from './isms-policy/isms-policy.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LandingPageComponent, HowItWorksComponent,
    LifestyleWalletCardComponent, RemittanceComponent,
    WalletTransferComponent, WaysToPayComponent,BankTransferComponent,
    PayInAppOnlineComponent,ImePayVisaCardComponent,
     PayInStoreComponent,GetAppComponent, PrivacyPolicyComponent, TermsConditionsComponent, AboutUsComponent, AccessibilityStatementComponent, PayServicesComponent, SafetyAndComplianceComponent, GrievanceComponent, IsmsPolicyComponent],


  imports: [
    CommonModule,
    LandingPageRoutingModule,
    MaterialsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    AngularTiltModule,
    MatBottomSheetModule,
    TranslateModule
  ]
})
export class LandingPageModule { }
