import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AnimationOptions } from 'ngx-lottie';
import { ContactSupportComponent } from 'src/app/shared/components/contact-support/contact-support.component';

@Component({
  selector: 'ime-accessibility-statement',
  templateUrl: './accessibility-statement.component.html',
  styleUrls: ['./accessibility-statement.component.scss']
})
export class AccessibilityStatementComponent implements OnInit {
  animationIconOption :AnimationOptions [];
  constructor(private _bottomSheet : MatBottomSheet) { }

  ngOnInit(): void {
    this.animationIconOption = [
      {
        path: "assets/images/json-image/icons-facebook.json",
        loop: false,
        autoplay: false,
      },
      {
        path: "assets/images/json-image/icons-youtube.json",
        loop: false,
        autoplay: false,
      },
      {
        path: "assets/images/json-image/icons-instagram.json",
        loop: false,
        autoplay: false,
      },
      {
        path: "assets/images/json-image/icons-linkedin.json",
        loop: false,
        autoplay: false,
      },
    ];
  }
  sisaImgUrl = 'assets/images/sisa.png';
  showHelpLine() {
    this._bottomSheet.open(ContactSupportComponent, {
      panelClass: "HL-dailog",
    });
  }
}
