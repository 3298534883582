import { interval} from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { YoutubeComponent } from "../shared/components/youtube/youtube.component";
import { YoutubeserviceService } from "../shared/service/youtube-service/youtube-service.service";
import { finalize, repeat, take, takeWhile } from "rxjs/operators";
import { ObserveElementService } from "../shared/service/observe-element/observe-element.service";
import { YOUTUBE_LINKS } from "../shared/constants/constants";
import {ROUTER_BASE_PATH} from '../shared/constants/router-path'
import { OwlOptions } from "ngx-owl-carousel-o";
import { AnimationOptions } from "ngx-lottie";
import { setTiltProp } from "../shared/service/utilsService/utility.service";
import { Title } from "@angular/platform-browser";
import Typewriter from 'typewriter-effect/dist/core';

import * as metaInfo from './../../assets/meta-info.json';
import { ApiService } from "../core/service/api.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ime-home",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit, AfterViewInit {
  spanNumber = 1;

  imeInfoYoutubeLink = YOUTUBE_LINKS.IME_INFO_YOUTUBE_LINK;
  routerPath = ROUTER_BASE_PATH;

  usp:any = [];
  basicCards:any  = []
  tiltSettings = setTiltProp();
  tiltSettingsPopupImage = setTiltProp(true);

  
  moreWithIme = {
    title: "Perks of using IME Pay",
    item1: {
      header: "More Reward points",
      text: "Get cash for your points & spend your way, no gimmicks",
      imageUrl: "assets/images/redeem-to-cash.webp",
    },
    item2: {
      header: "Highest Cashback",
      text: "Just compare yourself before making any transactions",
      imageUrl: "assets/images/top-up.webp",
    },
    item3: {
      header: "Free Transactions",
      text: "We do not take any fees by ourselves, its almost free",
      imageUrl: "assets/images/free-transaction.webp",
    },
  };

  /* start any questions ?*/
  slidesCollection = [
    {
      imageUrl: "assets/images/mobile-with-imepay-logo.webp",
      text: "How to open your FREE  IME Pay account?",
      backgroundColor: "#FEC4C4",
      className: "openImePayImg",
      buttonImage: "assets/icon/circled-play-red.svg",
      blackButton: "assets/icon/circled-play-black.svg",
      hovered: false,
      videoLink: YOUTUBE_LINKS.VERIFY_KYC_IMEPAY,
    },
    {
      imageUrl: "assets/images/kyc-verify.webp",
      text: "How to verify KYC in the IME Pay app?",
      backgroundColor: "#55EBFD",
      className: "kycImg",
      buttonImage: "assets/icon/circled-play-blue.svg",
      blackButton: "assets/icon/circled-play-black.svg",
      hovered: false,
      videoLink: YOUTUBE_LINKS.VERIFY_KYC_IMEPAY,
    },
    {
      imageUrl: "assets/images/mobile-activate-visa-card.webp",
      text: "How to activate your Virtual VISA Card?",
      backgroundColor: "#7FFCB7",
      className: "activateVisaCardImg",
      buttonImage: "assets/icon/circled-play-green.svg",
      blackButton: "assets/icon/circled-play-black.svg",
      hovered: false,
      videoLink: YOUTUBE_LINKS.VIRTUAL_VISA_CARDS_ACTIVATE_LINK,
    },
    {
      imageUrl: "assets/images/mobile-send-money.webp",
      text: "How to send money to another IME Pay user?",
      backgroundColor: "#F5BAD2",
      className: "mobileSendMoneyImg",
      buttonImage: "assets/icon/circled-play-pink.svg",
      blackButton: "assets/icon/circled-play-black.svg",
      hovered: false,
      videoLink: YOUTUBE_LINKS.SEND_P2P_YOUTUBE_LINK,
    },

    {
      imageUrl: "assets/images/mobile-link-bank.webp",
      text: "How to link your bank to your IME Pay account?",
      backgroundColor: "#B7F8D1",
      className: "mobileLinkBankImg",
      buttonImage: "assets/icon/circled-play-blue.svg",
      blackButton: "assets/icon/circled-play-black.svg",
      hovered: false,
      videoLink: YOUTUBE_LINKS.LINK_BANK_YOUTUBE_LINK,
    },
    {
      imageUrl: "assets/images/mobile-purchase-air-ticket.webp",
      text: "How to purchase airline tickets?",
      backgroundColor: "#EDDFBB",
      className: "mobileAirticketsImg",
      buttonImage: "assets/icon/circled-play-yellow.svg",
      blackButton: "assets/icon/circled-play-black.svg",
      hovered: false,
      videoLink: YOUTUBE_LINKS.PURCHASE_AIR_TICKET,
    },

  ];

  customOptionsQuestions: OwlOptions = {
    rtl: false,
    autoplay: false,
    loop: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    autoplaySpeed: 1500,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 1000,
    slideBy: 3,
    margin: 10,
    stagePadding: 0,
    mergeFit: true,
    items: 1,
    responsive: {
      0: {
        items: 1.25,
      },
      480: {
        items: 1,
      },
      700: {
        items: 3,
      },
      1080: {
        items: 3,
      },
      1200: {
        items: 4,
      },
      1350: {
        items: 4,
      },
      1440: {
        items: 4,
      },
      1700: {
        items: 4,
      },
      1900: {
        items: 4,
      },
    },
    nav: false,
    autoHeight: true,
  };

  imgSrcLearMoreArrow = "assets/icon/learnmore-arrow.svg";
  imgSrcLock = "assets/icon/lock-locked.png";
  scanQrClass = "qrContainer";

  toggleScroll = "";

  interestRate = 0.0;

  buttonRequired = {
    getApp: true,
  };

  // showAutoScrollBtn = true;
  showQrCode = true;
  halfHideQr = false;
  @ViewChild("footerDiv") footerDiv: ElementRef;
  // @ViewChild("whyUsDiv") whyUsDiv: ElementRef;
  @ViewChild("interestDiv") interestDiv: ElementRef;

  animationIconOptions: AnimationOptions[];
  navArrowPrevSrcImg = "assets/icon/expand-icon-blur.svg";
  navArrowFrontSrcImg = "assets/icon/expand-icon-blur.svg";
  constructor(
    private _youtubeService: YoutubeserviceService,
    private _dialog: MatDialog,
    private _elementObserver: ObserveElementService,
    private titleService: Title,
    private apiService: ApiService,
    private translate: TranslateService
  ) {
    this.titleService.setTitle("IME Pay - Nepal's Best Digital Wallet");
    this.animationIconOptions = [
      {
        path: "assets/images/json-image/remittance-list.json",
        loop: true,
        autoplay: true,
      },
      {
        path: "assets/images/json-image/banks.json",
        loop: true,
        autoplay: true,
      },
    ];
    this.translate.setDefaultLang('en')
  }

  heroSectionText = ['Money','Banking','Payment','Remittance','Insurance'];
  heroText = '';
  heroCharacter='_';

  goToLink(url: string) {
    window.open(url, '_blank')
  }
  highLightIcon(arrowName: string) {
    this[arrowName] = "assets/icon/expand-icon.svg";
  }
  blurIcon(arrowName: string) {
    this[arrowName] = "assets/icon/expand-icon-blur.svg";
  }
  lockhover() {
    this.imgSrcLock = "assets/images/Lock-Left.png";
    setTimeout(() => {
      this.imgSrcLock = "assets/icon/lock-locked.png";
    }, 1000);
  }
  currentInterestRate = 5.97;
  spinInterest() {
    interval(13)
    .pipe(takeWhile(() => (this.interestRate < this.currentInterestRate ? true : false)))
      .subscribe(() => {
        this.interestRate += 0.11;
        this.interestRate = this.interestRate > this.currentInterestRate ? this.currentInterestRate : this.interestRate;
        this.interestRate = parseFloat(this.interestRate.toFixed(3));
      });
  }



  changeButton(type = "default", id) {
    if (type == "hovered") {
      this.slidesCollection[id].hovered = true;
    } else {
      this.slidesCollection[id].hovered = false;
    }
  }
  ngOnInit(): void {
    this.apiService.getInterest().subscribe((data=>{
      this.currentInterestRate = parseFloat(data['interest-rate'])
    }));

    
  this.translate.onLangChange.subscribe(() => {
    this.loadUsp();
  });

  // Initial load
  this.loadUsp();
  }


  sendUrl(url:string,title?:string) {
    this._youtubeService.setUrl(url);
    this._dialog.open(YoutubeComponent, {
      height: "69%",
      width: "80%",
      panelClass: "custom-dialog-container",
      data: {
        videoTitle: title?title:"Jay Pay Garda Pani IME Pay TVC",
      },
    });
  }
  toggleSlideQrCode() {
    if (this.halfHideQr) {
      this.scanQrClass = "qrContainer slideLeft";
    } else {
      this.scanQrClass = "qrContainer slideRight";
    }
    this.halfHideQr = !this.halfHideQr;
  }
  ngAfterViewInit() {
    this._elementObserver
      .observeElement(this.interestDiv, "-35%")
      .subscribe((res) => {
        if (res) {
          this.spinInterest();
        }
      });
    // this._elementObserver
    //   .observeElement(this.whyUsDiv, "-10%")
    //   .subscribe((res) => {
    //     if (res) {
    //       // this.showAutoScrollBtn = false;
    //       if (!this.halfHideQr) {
    //         this.toggleSlideQrCode();
    //       }
    //     }
    //   });
    this._elementObserver
      .observeElement(this.footerDiv, "-10%")
      .subscribe((res) => {
        if (res) {
          this.scanQrClass = "qrContainer run";
        } else if (this.scanQrClass == "qrContainer run") {
          this.halfHideQr = !this.halfHideQr;
          this.toggleSlideQrCode();
        }
      });
    //TOUPDATE carwosel only trigger when resize
    window.dispatchEvent(new Event("resize"));

    this.textAnimate();
  }

  textAnimate(){
    var app = document.getElementById('text__animate');

    var typewriter = new Typewriter(app, {
      loop: true,
      delay: 150,
      cursor: '_'
    })

    typewriter
    .pauseFor(1000)
    .typeString('<span style="color:#cc0000; opacity:100%">'+this.usp[0] || 'Money'+'<span>')
    .pauseFor(2000)
    .deleteAll()
    .typeString('<span style="opacity:0.6">'+this.usp[1]?this.usp[1]: 'Money'+'</span>')
    .pauseFor(2000)
    .deleteAll()
    .typeString('<span style="opacity:0.6">'+this.usp[2]?this.usp[2]: 'Money'+'</span>')
    .pauseFor(2000)
    .deleteAll()
    .typeString('<span style="opacity:0.6">'+this.usp[3]?this.usp[3]: 'Money'+'</span>')
    .pauseFor(2000)
    .deleteAll()
    .typeString('<span style="opacity:0.6">'+this.usp[4]?this.usp[4]: 'Money'+'</span>')
    .pauseFor(2000)
    .deleteAll()
    .start()
  }
  qrArrowContainerClass = "arrow-container";
  showArrow() {
    if (this.halfHideQr) {
      this.qrArrowContainerClass = "arrow-container show-arrow-left";
    } else {
      this.qrArrowContainerClass = "arrow-container show-arrow-right";
    }
  }
  hideArrow() {
    if (
      this.halfHideQr &&
      this.qrArrowContainerClass == "arrow-container show-arrow-left"
    ) {
      this.qrArrowContainerClass = "arrow-container hide-arrow-left";
    } else if (
      this.halfHideQr == false &&
      this.qrArrowContainerClass == "arrow-container show-arrow-right"
    ) {
      this.qrArrowContainerClass = "arrow-container hide-arrow-right";
    }
  }

  loadUsp() {
    this.translate.get('HOME.safer_simple_smarter.heading').subscribe((value: any) => {
      if (Array.isArray(value)) {
        this.usp = value[1];  // Access the first element if it's an array
        this.textAnimate();
      } else {
        console.error('Expected an array but got:', value);
      }
    });

    this.translate.get('HOME.watch_the_basics.cards').subscribe((value: any) => {
        this.slidesCollection[0].text = value[0].title
        this.slidesCollection[1].text = value[1].title
        this.slidesCollection[2].text = value[2].title
        this.slidesCollection[3].text = value[3].title
        this.slidesCollection[4].text = value[4].title
        this.slidesCollection[5].text = value[5].title
        this.textAnimate();
      
    });
  }
}
