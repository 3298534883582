import { Component, OnInit } from '@angular/core';
import * as AOS from "aos";
import { LoaderService } from './core/service/loader.service';

@Component({
  selector: 'ime-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  userLogin: boolean;
  constructor(public loaderService: LoaderService) {
    
  }

  ngOnInit(): void {
    AOS.init({
      offset: -250,
      duration: 1200, 
      once: true,
    });
  }



}
