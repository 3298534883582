import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {
   }

   handleError(error) {
        return throwError(error);
   }

   get(path: string, params: HttpParams = new HttpParams()) : Observable<any> {
     return this.http.get(path, {params})
              .pipe(catchError(this.handleError));
   }

   post(path:string, body: object = {}, header: object = {}) : Observable<any> {
     return this.http.post(path, JSON.stringify(body))
              .pipe(catchError(this.handleError));
   }

   put(path: string, body: object = {}, header: object = {}): Observable<any> {
     return this.http.put(path, JSON.stringify(body))
          .pipe(catchError(this.handleError));
   }
   getInterest(){
    return this.get('./../../assets/meta-info.json')
   }
}
