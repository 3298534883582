<section class="about-us">
  <div class="about-us-hero">
    <div class="about-us-hero__header ime-heading-4"
    data-aos="zoom-in"
    data-aos-delay="400"
    >
      Hi. We’re
      <span class="ime-color-red">IME Pay.</span>
    </div>
    <div class="about-us-hero__container">
      <div class="about-us-hero__left">
        <div class="about-us-hero__left__content">
          <div class="about-us-hero__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="1200"
          >
            <p>
              IME Digital has introduced mobile money service, with the brand name
              “IME pay”, under its digital financial services initiative. IME
              Digital Solution Ltd. is Nepal’s first licensed company to provide
              digital payment solution.
            </p>
            <p>
              Since 2017, IME Pay has been the app for fast, safe, secure payments
              between friends. IME Pay offers a wide range of payment services and
              convenient way to transfer money inside the country from
              wallet-to-wallet, pay utility bills and make purchases online or
              offline instantly with just a few easy clicks. 7 years, 5 million
              users, and millions of delightful payments (and emojis, and inside
              jokes) later, we’re still that — and much more.
            </p>
          </div>
        </div>
      </div>

      <div class="about-us-hero__right">
        <div class="about-us-hero__right__images">
          <img
            src="assets/images/about-us-app.webp"
            alt=""
            class="about-us-hero__right__images__app"
            data-aos="zoom"
            data-aos-delay="800"
            aTilt
              [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
    </div>


  </div>
  <div class="container-lifestyle-wallet">
    <div class="container-lifestyle-wallet__contents content-container">
      <span class="container-lifestyle-wallet__contents__title ime-heading-1"
      data-aos="zoom-in"
      data-aos-delay="200"
      >
        A Lifestyle Wallet
      </span>
      <ime-lifestyle-wallet-card [data]="this.data"></ime-lifestyle-wallet-card>
    </div>
  </div>

  <div class="ime-group">
    <div class="ime-group__container">
      <div class="ime-group__left">
        <div class="ime-group__left__images">
          <img src="assets/images/ime-group-family.png" class="ime-group__left__images__main" alt=""
          data-aos="zoom"
          data-aos-delay="200"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/imegroup.png" class="ime-group__left__images__popup"alt=""
          data-aos="fade-up-left"
          data-aos-delay="200"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
        </div>
      </div>
      <div class="ime-group__right">
        <div class="ime-group__right__heading ime-heading-2"
        data-aos="zoom-in"
        data-aos-delay="400"
        >Part of the
         <br><span class="ime-color-red">IME Group</span> family</div>
        <div class="ime-group__right__text  ime-text-1"
        data-aos="fade-up"
        data-aos-delay="600"
        >We’re owned by IME Group of Companies, a corporate pioneer in Nepal. We’re proud to be part of the pack.</div>
        <button
        mat-stroked-button
        class="learn-more-btn"
        (mouseover)="
            imgSrcLearMoreArrow =
              'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          (click)="goToLink('https://www.imegroup.com.np/')"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="1300"
        data-aos-delay="800"
        data-aos-anchor-placement="top-bottom"
      >
        <div class="learn-more-btn-content">
          <div class="learn-more-btn-content__title">Know more</div>
          <div class="learn-more-btn-content__img">
            <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
          </div>
        </div>
      </button>
      </div>
    </div>
  </div>

  <div class="remit-page__network">
    <div class="remit-page__network__info">
      <div class="remit-page__network__info__cards cards content-container">
        <div
          class="cards__card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="100"
        >
          <div class="cards__card__image">
            <img src="assets/icon/icon-anchor.png" alt="" />
          </div>
          <div class="cards__card__stats ime-heading-1">77</div>
          <div class="cards__card__header">District Presence</div>
        </div>
        <div
          class="cards__card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="300"
        >
          <div class="cards__card__image">
            <img src="assets/icon/icon-sign.png" alt="" />
          </div>
          <div class="cards__card__stats ime-heading-1">35K+</div>
          <div class="cards__card__header">Agents Network</div>
        </div>
        <div
          class="cards__card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="600"
        >
          <div class="cards__card__image">
            <img src="assets/icon/icon-bolt.png" alt="" />
          </div>
          <div class="cards__card__stats ime-heading-1">3M+</div>
          <div class="cards__card__header">Users Community</div>
        </div>
        <div
        class="cards__card"
        data-aos="zoom-in-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="600"
      >
        <div class="cards__card__image">
          <img src="assets/icon/icon-flag.png" alt="" />
        </div>
        <div class="cards__card__stats ime-heading-1">1M+</div>
        <div class="cards__card__header">Payout Location</div>
      </div>
      </div>
    </div>
  </div>

  <div class="ime-roles">
    <div class="ime-roles__container">
      <div class="ime-roles__left">
        <div class="ime-roles__left__images">
          <img src="assets/images/role-person-1.png" class="ime-roles__left__images__person ime-roles__left__images__person-1" alt=""
          data-aos="zoom-in"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/role-person-2.png" class="ime-roles__left__images__person ime-roles__left__images__person-2" alt=""
          data-aos="zoom-in"
          data-aos-delay="200"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/role-person-3.png" class="ime-roles__left__images__person ime-roles__left__images__person-3" alt=""
          data-aos="zoom-in"
          data-aos-delay="400"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/role-person-4.png" class="ime-roles__left__images__person ime-roles__left__images__person-4" alt=""
          data-aos="zoom-in"
          data-aos-delay="600"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
        </div>
      </div>
      <div class="ime-roles__right">
        <div class="ime-roles__right__heading ime-heading-2"
        data-aos="zoom"
        data-aos-delay="800"
        >
        <span class="ime-color-red">Explore</span> roles at <br>
        IME Pay’s office</div>
        <div class="ime-roles__right__text  ime-text-1"
        data-aos="fade-up"
        data-aos-delay="1000"
        >We’re always on the look-out for new people who share our vision and excitement for what’s possible.</div>
        <button
        mat-stroked-button
        class="learn-more-btn"
        (mouseover)="
            imgSrcLearMoreArrow =
              'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          (click)="goToLink('https://www.linkedin.com/company/imepay-official/jobs/')"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="1300"
        data-aos-delay="1200"
        data-aos-anchor-placement="top-bottom"
      >
        <div class="learn-more-btn-content">
          <div class="learn-more-btn-content__title" >SEE ROLES</div>
          <div class="learn-more-btn-content__img">
            <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
          </div>
        </div>
      </button>
      </div>
    </div>
  </div>

  <div class="support-partner">
    <div class="support-partner__container">
      <div class="support-partner__left">
        <div class="support-partner__left__content">
          <div class="support-partner__left__content__heading ime-heading-3"
          data-aos="fade-right"
          >
            Our Support <span class="ime-color-red">Partners</span>
          </div>
          <div class="support-partner__left__content__text ime-heading-4">
            Pleasure to work with
          </div>
        </div>
      </div>
      <div class="support-partner__right">
        <div class="support-partner__right__images">
          <img
          src="assets/logo/partner-aaid.png"
          alt=""
          class="support-partner__right__image"
          data-aos="zoom-in"
          data-aos-delay="400"
        />
        <img src="assets/logo/partner-aid.png" alt="" class="support-partner__right__image"
        data-aos="zoom-in"
          data-aos-delay="500"
        />
        <img
          src="assets/logo/partner-usaid.png"
          alt=""
          class="support-partner__right__image"
          data-aos="zoom-in"
          data-aos-delay="500"
        />
        <img src="assets/logo/partner-save.png" alt="" class="support-partner__right__image"
        data-aos="zoom-in"
        data-aos-delay="600"
        /><img
          src="assets/logo/partner-rrn.png"
          alt=""
          class="support-partner__right__image"
          data-aos="zoom-in"
          data-aos-delay="700"
        />
        <img src="assets/logo/partner-ukaid.png" alt="" class="support-partner__right__image"
        data-aos="zoom-in"
        data-aos-delay="800"
        />
        <img
          src="assets/logo/partner-un.png"
          alt=""
          class="support-partner__right__image"
          data-aos="zoom-in"
          data-aos-delay="900"
        />
        <img src="assets/logo/partner-care.png" alt="" class="support-partner__right__image"
        data-aos="zoom-in"
          data-aos-delay="1000"
        />
        </div>
      </div>
    </div>
  </div>
<!--   
  <div class="blogs">
    <div class="blogs__content">
      <div class="blogs__header ime-heading-4">More things to know</div>
      <div class="blogs__articles">
        <div class="article" *ngFor="let article of articleData">
          <div class="article__image">
            <img [src]="article.articleImage" alt="">
            <div class="article__image__overlay">
              <div class="article__image__overlay__icon" [ngClass]="article.articleType == 'video' ? 'article__image__overlay__video':'article__image__overlay__icon'">
                <img src="assets/icon/play-button.svg" alt="">
              </div>
              <div class="article__image__overlay__content">
                <div class="article__image__overlay__read">Read More</div>
              <div class="article__image__overlay__duration">
                <img src="assets/icon/clock-duration.svg" alt=""> <span class="text">3 minutes {{article.articleType == 'video' ? 'video':'read'}}</span>
              </div>
              </div>
            </div>
            <div class="article__image__social-link">
              <a
              href="https://www.linkedin.com/company/imepay-official"
              target="_blank"
              class="social-icons"
            >
              <ime-json-animator
                [options]="animationIconOption[0]"
              ></ime-json-animator>
            </a>
            <a
            href="https://www.facebook.com/imepay"
            target="_blank"
            class="social-icons"
          >
            <ime-json-animator
              [options]="animationIconOption[1]"
            ></ime-json-animator>
          </a>
          <a
          href="https://twitter.com/imepay_official"
          target="_blank"
          class="social-icons twitter"
        >
          <ime-json-animator
            [options]="animationIconOption[2]"
          ></ime-json-animator>
        </a>
            </div>
          </div>
          <div class="article__time ime-text-1">{{article.articleTime}}</div>
          <div class="article__title">{{article.articleTitle}}</div>
          <div class="article__text ime-text-3">{{article.articleText}}</div>
        </div>
      </div>
      <div class="blogs__read-more">
        <button
        mat-stroked-button
        class="learn-more-btn"
        (mouseover)="
            imgSrcLearMoreArrow =
              'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
        (click)="goToLink('https://www.imepay.com.np/blog/')"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="1300"
        data-aos-delay="400"
        data-aos-anchor-placement="top-bottom"
      >
        <div class="learn-more-btn-content">
          <div class="learn-more-btn-content__title">Read more</div>
          <div class="learn-more-btn-content__img">
            <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
          </div>
        </div>
      </button>
      </div>
    </div>
  </div> -->

  <div class="about-cta">
    <div class="about-cta__background">
      <div class="about-cta__content">
        <img src="assets/icon/bulb-left.png" alt="" class="about-cta__content__popup1">
        <img src="assets/icon/magnifer-left.png" alt="" class="about-cta__content__popup2">
        <div class="about-cta__left">
          <div class="about-cta__left__header ime-heading-4">Want to get in touch?</div>
          <div class="about-cta__left__text ime-text-1">Contact our team if you’d like to know more.</div>
          <div class="about-cta__left__button">
            <div class="contact-btn-content">
              <button
              mat-stroked-button
              class="contact-btn"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="1300"
              data-aos-delay="400"
              data-aos-anchor-placement="top-bottom"
            >
              <div class="contact-btn-content">
                <div class="contact-btn-content__title">Contact Team</div>
              </div>
            </button>
            </div>
          </div>
        </div>
        <div class="about-cta__right">
          <div class="about-cta__right__images">
            <img src="assets/images/footer-card-section.webp" alt="" class="about-cta__right__images__main"
            data-aos="fade-down"
            data-aos-delay="400"
            data-aos-easing="ease-in-out-back"
            data-aos-duration="1000"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/footer-home-section.webp" alt="" class="about-cta__right__images__app"
            data-aos="fade-down"
            data-aos-delay="600"
            data-aos-easing="ease-in-out-back"
            data-aos-duration="1000"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/footer-visacard_cta.webp" alt="" class="about-cta__right__images__card"
            alt=""
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-easing="ease-in-quart"
            data-aos-duration="1000"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</section>
