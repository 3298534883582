import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { Title } from "@angular/platform-browser";
import { ApiService } from "src/app/core/service/api.service";
import { NotificatonService } from "src/app/core/service/notificaton.service";
import { ContactSupportComponent } from "src/app/shared/components/contact-support/contact-support.component";
import { GetAppMessageComponent } from "src/app/shared/components/get-app-message/get-app-message.component";
import { validateMobile } from "src/app/shared/utilities/formvalidators/validate-mobile";
import { environment } from "src/environments/environment";
@Component({
  selector: "ime-get-app",
  templateUrl: "./get-app.component.html",
  styleUrls: ["./get-app.component.scss"],
})
export class GetAppComponent implements OnInit {
  constructor(
    private _formBuilder: FormBuilder,
    private _bottomSheet: MatBottomSheet,
    private _apiService: ApiService,
    private titleService: Title,
    private _notificationService:NotificatonService
  ) {
    this.titleService.setTitle("Get App - Get IME Pay")
  }

  getStartedForm = this._formBuilder.group({
    country: [{value:"Nepal",disabled:true}, [Validators.required]],
    ReceiverNo: ["", [Validators.required, validateMobile]],
  });

  get country(): FormControl {
    return this.getStartedForm.get("country") as FormControl;
  }

  get phone(): FormControl {
    return this.getStartedForm.get("ReceiverNo") as FormControl;
  }
  //consume event if reached inout limit
  limitInput(event) {
    if (
      this.phone.value &&
      this.phone.value.toString().length >= 10 &&
      event.which !== 8 &&
      event.which !== 46 &&
      event.which !== 37 &&
      event.which != 39
    ) {
      event.preventDefault();
    }
  }

  submit() {
    if(this.getStartedForm.get("ReceiverNo").value !=='' && this.getStartedForm.status.toLocaleLowerCase() == 'valid'){
      this._apiService
      .post(environment.SMS_PROXY_SERVER, this.getStartedForm.value)
      .subscribe((res) => {
        if(res.responseCode == 100){
          this._bottomSheet.open(GetAppMessageComponent, {
            panelClass: "get-app-message-dailog",
          });
        }
      });
    }
  }
  showHelpLine() {
    this._bottomSheet.open(ContactSupportComponent, {
      panelClass: "HL-dailog",
    });
  }
  ngOnInit(): void {}
  infoText(){
    this._notificationService.openSnackBar("Service is currently available only in Nepal.")
  }
  generateSignature(){
    let time = new Date();

    console.log(window.btoa(time.toString()))
  }
}
