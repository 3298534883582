import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import clevertap from 'clevertap-web-sdk'

clevertap.init(environment.CLEVERTAP_PROJECT_ID, 'IN1', '')
clevertap.spa = true

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
