<div class="message-sent">
  <div class="message-sent__icon">
    
    <ime-json-animator
    [options]="animationIconOption"
  ></ime-json-animator>
  </div>
  <div class="message-sent__text">
    The message has been sent to the given number.
  </div>
</div>
