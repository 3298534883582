import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ime-ads-banner',
  templateUrl: './ads-banner.component.html',
  styleUrls: ['./ads-banner.component.scss']
})
export class AdsBannerComponent implements OnInit {
  constructor() { }
  mainClass = 'ads-container';
  ngOnInit(): void {
  }
  close(){
    this.mainClass = 'ads-container hide'
  }
 
}
