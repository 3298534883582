import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AnimationOptions } from "ngx-lottie";
import { OwlOptions } from "ngx-owl-carousel-o";
import { EXTERNAL_LINKS } from "src/app/shared/constants/constants";
import { setTiltProp } from "src/app/shared/service/utilsService/utility.service";


@Component({
  selector: "ime-bank-transfer",
  templateUrl: "./bank-transfer.component.html",
  styleUrls: ["./bank-transfer.component.scss"],
})
export class BankTransferComponent implements OnInit {

  commision_link = EXTERNAL_LINKS.CHARGE_N_COMMISION;
  customOptions: OwlOptions = {
    rtl: false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplaySpeed: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 3000,
    slideBy: 1,
    margin: 10,
    responsive: {
      0: {
        items: 4,
      },
      480: {
        items: 6,
      },
      700: {
        items: 8,
      },
      1080: {
        items: 10,
      },
    },
    nav: false,
    autoHeight: true,
  };
  comBanks = [
    "assets/bank-logos/bank-com-1.png",
    "assets/bank-logos/bank-com-2.png",
    "assets/bank-logos/bank-com-3.png",
    "assets/bank-logos/bank-com-4.png",
    "assets/bank-logos/bank-com-5.png",
    "assets/bank-logos/bank-com-6.png",
    "assets/bank-logos/bank-com-7.png",
    "assets/bank-logos/bank-com-8.png",
    "assets/bank-logos/bank-com-9.png",
    "assets/bank-logos/bank-com-10.png",
    "assets/bank-logos/bank-com-11.png",
    "assets/bank-logos/bank-com-12.png",
  ];
  devBanks = [
    "assets/bank-logos/bank-dev-1.png",
    "assets/bank-logos/bank-dev-2.png",
    "assets/bank-logos/bank-dev-3.png",
    "assets/bank-logos/bank-dev-4.png",
    "assets/bank-logos/bank-dev-5.png",
    "assets/bank-logos/bank-dev-6.png",
  ];
  finances = [
    "assets/bank-logos/bank-fin-1.png",
    "assets/bank-logos/bank-fin-2.png",
    "assets/bank-logos/bank-fin-3.png",
    "assets/bank-logos/bank-fin-4.png",
    "assets/bank-logos/bank-fin-5.png",
  ];
  allBankFinance = this.comBanks.concat(
    this.devBanks,
    this.finances,
    this.comBanks
  );
  mgSrcLearMoreArrow = "assets/icon/learnmore-arrow.svg";
  animationIconOption: AnimationOptions;
  animationIconOptionBank: AnimationOptions;
  constructor(private titleService: Title) {
    this.titleService.setTitle("Account Transfer - IME Pay");
    this.animationIconOption ={
      path:"assets/images/json-image/cooperative-list.json",
      autoplay:true,
      loop:true,
    }
    this.animationIconOptionBank ={
        path: "assets/images/json-image/banks.json",
        loop: true,
        autoplay: true,
    }
  }
  tiltSettingsPopupImage = setTiltProp(true);
  tiltSettingsMainImage = setTiltProp();
  ngOnInit(): void {}
}
