//base url and its child url name
export const ROUTER_BASE_PATH ={
    HOW_IT_WORKS:'how-it-works',
    REMITTANCE:'remittance',
    WALLET_TRANSFER:'wallet-transfer',
    BANK_TRANSFER:'bank-transfer',
    WAY_TO_PAY:'way-to-pay',
    PAY_SERVICE:'pay-service',
    PAY_ONLINE_IN_APPS:'pay-app',
    PAY_IN_STORE:'pay-in-store',
    PAY_THROUGH_CARD:'pay-through-visa-card',
    GET_APP:'get-app',
    COMING_SOON:'coming-soon',
    PRIVACY_POLICY:'privacy-policy',
    TERMS:'terms',
    ABOUT_US:'about-us',
    ACCESSIBILITY_STATEMENT:'accessibility',
    SAFETY_AND_COMPLIANCE: 'safety-and-compliance',
    GRIEVANCES: 'grievances',
    ISMS_POLICY: 'isms-policy'
}



