<section class="bank-transfer">
  <div class="bank-transfer__remit">
    <div class="bank-transfer__remit__contents content-container">
      <div class="bank-transfer__remit__contents__left">
        <div
          class="bank-transfer__remit__contents__left__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          {{'bank_and_transfer.banking_made_easier.heading.0' | translate}} <br />
          <span
            class="ime-color-red"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="800"
            data-aos-delay="300"
            >{{'bank_and_transfer.banking_made_easier.heading.1' | translate}}</span
          >
        </div>
        <div
          class="bank-transfer__remit__contents__left__title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="900"
          data-aos-delay="400"
        >
          {{'bank_and_transfer.banking_made_easier.subheading' | translate}}
        </div>
        <div
          class="bank-transfer__remit__contents__left__button"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
        </div>
      </div>
      <div class="bank-transfer__remit__contents__right">
        <div class="bank-transfer__remit__contents__right__img">
          <div class="bank-transfer__remit__contents__right__img__main">
            <img
              src="assets/images/bank-easier.png"
              class="bank-transfer-hero-banner"
              alt=""
              data-aos="zoom-in-down"
              data-aos-easing="ease-in-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="500"
              aTilt
              [tiltSettings]="tiltSettingsMainImage"
            />
          </div>
          <img
            src="assets/images/deposit-success-no-pig.png"
            class="hero-section-icon__deposit-success"
            alt=""
            data-aos="zoom-in-right"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="800"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/icon/pig-no-fee.png"
            class="hero-section-icon__pig"
            alt=""
            data-aos="zoom-in-left"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="1300"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <div class="hero-section-icon__box ime-hide-only-on-mobile"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="bank-transfer__recieve content-container">
    <div class="bank-transfer__recieve__info">
      <div class="bank-transfer__recieve__info__content">
        <div
          class="bank-transfer__recieve__info__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="900"
          data-aos-delay="100"
        >
          {{'bank_and_transfer.bank_transfers.heading.0' | translate}} <br />
          <span class="ime-color-red">{{'bank_and_transfer.bank_transfers.heading.1' | translate}}</span>
        </div>
        <div
          class="bank-transfer__recieve__info__title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
        {{'bank_and_transfer.bank_transfers.subheading' | translate}}
        </div>
      </div>
    </div>
    <div class="bank-transfer__recieve__figure">
      <div class="bank-transfer__recieve__figure__img">
        <img
          src="assets/images/bank-transfer.webp"
          class="bank-transfer__recieve__figure__img__main"
          alt=""
          data-aos="zoom-in-down"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="300"
          aTilt
          [tiltSettings]="tiltSettingsMainImage"
        />
        <div
          class="bank-transfer__recieve__figure__icon-search-bank"
          data-aos="zoom-in-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1200"
          data-aos-delay="800"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        >
          <ime-json-animator
            [options]="animationIconOptionBank"
          ></ime-json-animator>
        </div>
      </div>
    </div>
    <div class="bank-transfer__recieve__method">
      <div class="bank-transfer__recieve__method__steps steps">
        <div class="steps__step">
          <div
            class="steps__step__header ime-heading-6"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            {{'bank_and_transfer.bank_transfers.cards.0.title' | translate}}
          </div>
          <div
            class="steps__step__info ime-text-3--alt"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="800"
          >
          {{'bank_and_transfer.bank_transfers.cards.0.description' | translate}}
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header ime-heading-6"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="1500"
          >
          {{'bank_and_transfer.bank_transfers.cards.1.title' | translate}}
          </div>
          <div
            class="steps__step__info ime-text-3--alt"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="1800"
          >
          {{'bank_and_transfer.bank_transfers.cards.1.description' | translate}}
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header ime-heading-6"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="2500"
          >
          {{'bank_and_transfer.bank_transfers.cards.2.title' | translate}}
          </div>
          <div
            class="steps__step__info ime-text-3--alt"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="2800"
          >
          {{'bank_and_transfer.bank_transfers.cards.2.description' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bank-link-steps content-container">
    <div
      class="bank-link-steps__header ime-heading-1"
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="800"
      data-aos-delay="100"
    >
      {{'bank_and_transfer.link_bank.heading' | translate}}
    </div>
    <div class="bank-link-steps__container steps">
      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="200"
      >
        <div class="steps__step__icon">
          <img
            src="assets/icon/bank-3d.png"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            alt=""
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--first ime-heading-3"
          >
          {{'bank_and_transfer.link_bank.cards.0.title' | translate}}
          </div>
          <div class="steps__step__info ime-text-1">
            {{'bank_and_transfer.link_bank.cards.0.description' | translate}}
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="500"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/bank-details.png"
            class="steps-icon-enter-details"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--second ime-heading-3"
          >
            {{'bank_and_transfer.link_bank.cards.1.title' | translate}}
          </div>
          <div class="steps__step__info ime-text-1">
            {{'bank_and_transfer.link_bank.cards.1.description' | translate}}
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="800"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/link-bank.png"
            class="steps-icon-link"
            alt=""
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--third ime-heading-3"
          >
          {{'bank_and_transfer.link_bank.cards.2.title' | translate}}
          </div>
          <div class="steps__step__info ime-text-1">
            {{'bank_and_transfer.link_bank.cards.2.title' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bank-transfer__track">
    <div class="bank-transfer__track__container content-container">
      <div class="bank-transfer__track__container__left">
        <div class="bank-transfer__track__container__left__img">
          <img
            src="assets/images/co-operative-transfer.webp"
            class="co-operative-transfer-img"
            alt=""
            data-aos="zoom-in-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="500"
            aTilt
            [tiltSettings]="tiltSettingsMainImage"
          />
          <div
            class="co-operative-transfer-icon-coorative-list"
            data-aos="zoom-in-right"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="1500"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          >
            <ime-json-animator
              [options]="animationIconOption"
            ></ime-json-animator>
          </div>
        </div>
      </div>
      <div class="bank-transfer__track__container__right">
        <div class="bank-transfer__track__container__right__content">
          <div
            class="bank-transfer__track__container__right__content__heading ime-heading-1"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            {{'bank_and_transfer.cooperative_transfer.heading.0' | translate}}<br /><span class="ime-color-red">{{'bank_and_transfer.cooperative_transfer.heading.1' | translate}}</span>
          </div>
          <div
            class="bank-transfer__track__container__right__content__title ime-text-1"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
          {{'bank_and_transfer.cooperative_transfer.subheading' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="micro-finance-container">
    <div class="micro-finance-container__content">
      <div class="micro-finance-container__content__left">
        <div
          class="micro-finance-container__content__left__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          {{'bank_and_transfer.microfinance_transfer.heading.0' | translate}}<br /><span class="ime-color-red">{{'bank_and_transfer.microfinance_transfer.heading.1' | translate}}</span>
        </div>
        <div
          class="micro-finance-container__content__left__subtitle ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1200"
          data-aos-delay="600"
        >
        {{'bank_and_transfer.microfinance_transfer.subheading' | translate}}
        </div>
      </div>
      <div class="micro-finance-container__content__right">
        <div class="micro-finance-container__content__right__img">
          <img
            src="assets/images/micro-finance-transfer.png"
            alt=""
            data-aos="zoom-in-left"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="200"
            class="micro-finance-transfer-main-img"
            aTilt
            [tiltSettings]="tiltSettingsMainImage"
          />
          <img
            src="assets/icon/global-icon-tick.png"
            class="micro-finance-transfer-icon-check"
            alt=""
            data-aos="zoom-in-down"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="500"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="bank-transfer__partners ime-hide-only-on-mobile">
    <div class="bank-transfer__partners__contents content-container">
      <div class="bank-transfer__partners__left">
        <div
          class="bank-transfer__partners__left__header"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          {{'bank_and_transfer.you_wont_miss_accounts.card_title' | translate}}
        </div>
        <div
          class="bank-transfer__partners__left__types"
          data-aos="fade-up-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
        {{'bank_and_transfer.you_wont_miss_accounts.cards.0.title' | translate}}
        </div>
        <div
          class="bank-transfer__partners__left__types"
          data-aos="fade-up-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="800"
        >
        {{'bank_and_transfer.you_wont_miss_accounts.cards.1.title' | translate}}
        </div>
        <div
          class="bank-transfer__partners__left__types"
          data-aos="fade-up-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="1100"
        >
        {{'bank_and_transfer.you_wont_miss_accounts.cards.2.title' | translate}}
        </div>
      </div>
      <div class="bank-transfer__partners__right">
        <div
          class="bank-transfer__partners__right__heading ime-heading-2"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
        {{'bank_and_transfer.you_wont_miss_accounts.heading' | translate}}
        </div>
        <div
          class="bank-transfer__partners__right__text ime-text-2--alt"
          data-aos="fade-up-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1200"
          data-aos-delay="600"
        >
        {{'bank_and_transfer.you_wont_miss_accounts.subheading' | translate}}
        </div>
      </div>
    </div>
    <div class="bank-transfer__partners__icons">
      <img
        *ngFor="let image of allBankFinance; let i = index"
        [src]="image"
        alt=""
        srcset=""
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="500"
        [attr.data-aos-delay]="i * 50 + 50"
      />
    </div>
  </div>
  <div class="partners content-container ime-show-only-on-mobile">
    <div class="partners__contents">
      <div class="partners__contents__info">
        <div class="partners__contents__title ime-heading-2">
          {{'bank_and_transfer.you_wont_miss_accounts.heading' | translate}}
        </div>
        <div class="partners__contents__label ime-text-2--alt">
          {{'bank_and_transfer.you_wont_miss_accounts.subheading' | translate}}
        </div>
      </div>
      <div class="partners__contents__commercial-patners commercial-patners">
        <div class="commercial-patners__subtitle">{{'bank_and_transfer.you_wont_miss_accounts.cards.0.title' | translate}}</div>
        <div class="commercial-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template carouselSlide *ngFor="let slide of comBanks">
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="partners__contents__finance-patners finance-patners">
        <div class="finance-patners__subtitle">{{'bank_and_transfer.you_wont_miss_accounts.cards.1.title' | translate}}</div>
        <div class="finance-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template carouselSlide *ngFor="let slide of devBanks">
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="partners__contents__finance-patners finance-patners">
        <div class="finance-patners__subtitle">{{'bank_and_transfer.you_wont_miss_accounts.cards.2.title' | translate}}</div>
        <div class="finance-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template carouselSlide *ngFor="let slide of finances">
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>

  <div class="container-hidden-fee content-container">
    <div class="container-hidden-fee__box">
      <div class="container-hidden-fee__box__title"
      data-aos="custom-zoom-in"
      data-aos-delay="600"
      data-aos-duration="400"
      >
        {{'bank_and_transfer.no_hidden_fees.heading' | translate}}
      </div>
      <img
        src="assets/icon/pig-no-fee.png"
        class="icon__pig"
        alt=""
        data-aos="zoom-in-left"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="700"
        data-aos-duration="600"
      />
      <div
        class="container-hidden-fee__box__subtitle ime-text-4"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="850"
        data-aos-duration="600"
      >
      {{'bank_and_transfer.no_hidden_fees.subheading' | translate}}
      </div>
      <div
        class="container-hidden-fee__box__learn-more-link"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="1000"
        data-aos-duration="600"
      >
        <a  [href]="commision_link" target="_blank">{{'buttons.learn_more' | translate}}</a>
      </div>
      <div class="rupees-section">
        <div
          class="rupees-section__wallet-transfer"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-cubic"
          data-aos-delay="1150"
          data-aos-duration="600"
        >
          <span class="charge-rs">{{'bank_and_transfer.no_hidden_fees.cards.0.title' | translate}}</span>
          <p>{{'bank_and_transfer.no_hidden_fees.cards.0.description' | translate}}</p>
        </div>
        <div
          class="rupees-section__remmittance"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-cubic"
          data-aos-delay="1300"
          data-aos-duration="300"
        >
          <span class="charge-rs">{{'bank_and_transfer.no_hidden_fees.cards.1.title' | translate}}</span>
          <p>{{'bank_and_transfer.no_hidden_fees.cards.1.description' | translate}}</p>
        </div>
        <div
          class="rupees-section__bank-deposit"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-cubic"
          data-aos-delay="1450"
          data-aos-duration="300"
        >
          <span class="charge-rs">{{'bank_and_transfer.no_hidden_fees.cards.2.title' | translate}}</span>
          <p>{{'bank_and_transfer.no_hidden_fees.cards.2.description' | translate}}</p>
        </div>
      </div>
      <span
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="1550"
        data-aos-duration="600"
        class="ime-hide-only-on-mobile"
      >
        {{'bank_and_transfer.no_hidden_fees.conditions' | translate}}
      </span>
      <img
        src="assets/images/Hand.webp"
        class="icon__hand"
        alt=""
        data-aos="zoom-in-left"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="1700"
        data-aos-duration="600"
      />
      <img
        src="assets/images/plane.webp"
        class="icon__plane ime-hide-only-on-mobile"
        alt=""
        data-aos="zoom-in-right"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="1850"
        data-aos-duration="600"
      />
    </div>
  </div>

  <div class="container-life-easy">
    <div class="container-life-easy__background">
      <div class="container-life-easy__container content-container">
        <div class="container-life-easy__left">
          <img
            src="assets/icon/pig.png"
            class="container-life-easy__left__pig"
            alt=""
            data-aos="fade-left"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="800"
          />
          <div
            class="container-life-easy__left__header ime-heading-7 ime-hide-only-on-mobile"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
          >
            <span class="ime-color-red">{{'bank_and_transfer.link_your_bank_now.heading.0' | translate}}</span> {{'bank_and_transfer.link_your_bank_now.heading.1' | translate}} <br />{{'bank_and_transfer.link_your_bank_now.heading.2' | translate}}
          </div>
          <div
            class="container-life-easy__left__header ime-heading-7 ime-show-only-on-mobile"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
          >
            <span class="ime-color-red">{{'bank_and_transfer.link_your_bank_now.heading.0' | translate}}</span> {{'bank_and_transfer.link_your_bank_now.heading.1' | translate}} <br />{{'bank_and_transfer.link_your_bank_now.heading.2' | translate}}
          </div>
          <div
            class="get-app__button"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
          </div>
        </div>
        <div class="container-life-easy__right">
          <div class="container-life-easy__right__container">
            <img
              src="assets/images/link-now-popup.webp"
              class="container-life-easy__right__main-1"
              alt=""
              data-aos="fade-down"
              data-aos-easing="ease-in-out-cubic"
              data-aos-duration="1200"
              data-aos-delay="1000"
              aTilt
              [tiltSettings]="tiltSettingsMainImage"
            />
            <img
              src="assets/images/mobile-cta-bank-2.webp"
              class="container-life-easy__right__main-2"
              alt=""
              data-aos="fade-up"
              data-aos-easing="ease-in-out-cubic"
              data-aos-duration="1200"
              data-aos-delay="1500"
              aTilt
              [tiltSettings]="tiltSettingsMainImage"
            />
            <img
              src="assets/icon/link.png"
              class="container-life-easy__right__link"
              alt=""
              data-aos="zoom-in"
              data-aos-easing="ease-in-out-cubic"
              data-aos-duration="1200"
              data-aos-delay="1800"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</section>
