<div class="container-AS">
  <div class="container-AS-section AS">
    <div class="AS__heading">
      <div class="AS__heading__text"><span class="ime-color-red">IME Pay</span> Accessibility Statement</div>
      <div class="AS__heading__img">
          <img src="assets/images/accessibility.webp" class="icon-img" alt="" srcset="">
      </div>
    </div>
    <div class="AS__label">
      We want to make sure the content on our website and app is accessible and
      easy for everyone to use. We’re actively learning new ways to meet the
      accessibility needs of our community. If you ever find it challenging to
      view or navigate our content, or come across any content, features, or
      functionality that you feel isn’t fully accessible to people with
      disabilities, please Contact Us. Your feedback is important and helps us
      understand how to accommodate all of our customers and evaluate our
      accessibility policies. Lastly, even though we don’t have control over the
      content from other companies we partner with, we do our best to strongly
      encourage them to provide easily used, accessible content.
    </div>
    <div class="container-AS__bottom">
      <div
        class="container-AS__bottom__content"
        (click)="showHelpLine()"
      >
      <button
          mat-stroked-button
          class="contact-us-btn">
          <div class="container-AS__bottom__text">
            Contact Support
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</div>
