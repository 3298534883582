<div class="container-PIS">
  <!-- Hero Section -->
  <div class="container-pay-in-store">
    <div class="container-pay-in-store__wrapper">
      <div class="container-pay-in-store__left">
        <div
          class="container-pay-in-store__left__title ime-heading-1"
          data-aos="zoom-out"
          data-aos-delay="400"
          data-aos-duration="600"
        >
          {{'pay_stores.pay_touch.heading.0' | translate}} <br />
          <span class="ime-color-red"> {{'pay_stores.pay_touch.heading.1' | translate}} </span>
        </div>
        <div
          class="container-pay-in-store__left__label ime-text-1"
          data-aos="fade-up"
          data-aos-delay="800"
          data-aos-duration="600"
        >
        {{'pay_stores.pay_touch.subheading' | translate}}
        </div>
        <div
          class="container-pay-in-store__left__button"
          data-aos="fade-up"
          data-aos-delay="1200"
          data-aos-duration="600"
        >
          <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
        </div>
      </div>
      <div class="container-pay-in-store__right">
        <div class="container-pay-in-store__right__image">
          <img
            src="assets/images/pay-by-visa-card.webp"
            alt=""
            class="container-pay-in-store__right__main-image"
            data-aos="zoom-in"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
          />
          <img
          src="assets/images/qr-channel.webp"
          alt=""
          class="container-pay-in-store__right__via-image-desktop ime-hide-only-on-mobile"
          data-aos="fade-down-left"
          data-aos-duration="600"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        </div>
        <img
        src="assets/images/qr-channel.webp"
        alt=""
        class="container-pay-in-store__right__via-image ime-show-only-on-mobile"
        data-aos="fade-down-left"
        data-aos-duration="600"
        aTilt
        [tiltSettings]="tiltSettingsPopupImage"
      />
      </div>
    </div>
  </div>

  <!-- Scan any QR section -->
  <div class="container-scan-any">
    <div class="container-scan-any__left">
      <div class="container-scan-any__left__image">
        <img
          src="assets/images/scan-any-qr.webp"
          class="container-scan-any__left__image__scan-qr"
          alt=""
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          data-aos="fade-in"
          data-aos-duration="800"
        />
        <img
          src="assets/images/scan-any-qr-popup.webp"
          class="container-scan-any__left__image__popup"
          alt=""
          data-aos="fade-up-right"
          data-aos-delay="400"
          data-aos-duration="800"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>
    </div>
    <div class="container-scan-any__right">
      <div
        class="container-scan-any__right__title ime-heading-1"
        data-aos="zoom-out"
        data-aos-delay="500"
        data-aos-duration="800"
      >
        <span class="ime-color-red">{{'pay_stores.pay_touch.heading.0' | translate}}</span> <br />{{'pay_stores.pay_touch.heading.1' | translate}}
      </div>
      <div
        class="container-scan-any__right__label ime-text-1"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="800"
      >
        {{'pay_stores.scan_any_qr.subheading.0' | translate}} <br />
        <br />{{'pay_stores.scan_any_qr.subheading.1' | translate}}
      </div>
    </div>
  </div>

  <!-- Scan, pay & Go section -->
  <div class="container-scan-pay-go">
    <div class="container-scan-pay-go__title ime-heading-1">{{'pay_stores.scan_pay_go.heading' | translate}}</div>
    <div class="container-scan-pay-go__content">

      <div
        class="container-scan-pay-go__content__left"
        data-aos="fade-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <div class="container-scan-pay-go__content__left__img">
          <img
            src="assets/images/scan-qr-tick.webp"
            class="container-scan-pay-go__content__left__img__main1"
            alt=""
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
        <div class="container-scan-pay-go__content__left__label text">
          <span class="text__subTitle ime-heading-3"> {{'pay_stores.scan_pay_go.cards.0.title' | translate}} </span>
          <div class="text__label ime-text-1">
            {{'pay_stores.scan_pay_go.cards.0.description' | translate}}
          </div>
        </div>
      </div>

      <div
        class="container-scan-pay-go__content__middle"
        data-aos="fade-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <div class="container-scan-pay-go__content__middle__img">
          <img
            src="assets/images/pay-amount.webp"
            class="container-scan-pay-go__content__middle__img__main2"
            alt=""
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
        <div class="container-scan-pay-go__content__middle__label text">
          <span class="text__subTitle ime-heading-3"> {{'pay_stores.scan_pay_go.cards.1.title' | translate}} </span>
          <div class="text__label ime-text-1">
            {{'pay_stores.scan_pay_go.cards.1.description' | translate}}
          </div>
        </div>
      </div>
      <div
        class="container-scan-pay-go__content__right"
        data-aos="fade-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        data-aos-delay="800"
      >
        <div class="container-scan-pay-go__content__right__img container-scan-pay-go__content__right__img--last">
          <img
            src="assets/images/dancing-1.webp"
            class="container-scan-pay-go__content__right__img__main3"
            alt=""
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
        <div class="container-scan-pay-go__content__right__label text">
          <span class="text__subTitle ime-heading-3"> {{'pay_stores.scan_pay_go.cards.2.title' | translate}} </span>
          <div class="text__label ime-text-1">
            {{'pay_stores.scan_pay_go.cards.2.description' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Pay via ime card section -->
  <div class="container-pay-via-visa-card">
    <div class="container-pay-via-visa-card__bg">

    <div class="container-pay-via-visa-card__wrapper">
      <div class="container-pay-via-visa-card__left">
        <div
          class="container-pay-via-visa-card__left__title ime-heading-1"
          data-aos="zoom-in"
          data-aos-duration="800"
        >
          {{'pay_stores.ime_visa_card.heading.0' | translate}}<br />
          <span class="ime-color-blue">{{'pay_stores.ime_visa_card.heading.1' | translate}}</span>
          <span class="ime-color-red"> {{'pay_stores.ime_visa_card.heading.2' | translate}}</span>
        </div>
        <div
          class="container-pay-via-visa-card__left__label ime-text-1"
          data-aos="zoom-in-down"
          data-aos-delay="400"
          data-aos-duration="800"
        >
        {{'pay_stores.ime_visa_card.subheading' | translate}}
        </div>
        <button
          mat-stroked-button
          class="learn-more-btn"
          (mouseover)="
            imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          data-aos="zoom-in-down"
          data-aos-delay="800"
          data-aos-duration="800"
          [routerLink]="['/',routerPath.PAY_THROUGH_CARD]"
        >
          <div class="learn-more-btn-content">
            <div class="learn-more-btn-content__title">{{'button_text.learn_more' | translate}}</div>
            <div class="learn-more-btn-content__img">
              <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
            </div>
          </div>
        </button>
      </div>
      <div class="container-pay-via-visa-card__right"
      >
        <div class="container-pay-via-visa-card__right__image">
          <img
            src="assets/images/holding-visa-card-bg.webp"
            class="container-pay-via-visa-card__right__image__visa-card"
            alt=""
            aTilt
            [tiltSettings]="tiltSettings"
            data-aos="zoom-out"
            data-aos-delay="300"
            data-aos-duration="800"
          />
          <img
            src="assets/images/holding-visa-card-popup.png"
            class="container-pay-via-visa-card__right__image__popup"
            alt=""
            data-aos="fade-up-left"
            data-aos-delay="700"
            data-aos-duration="800"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
    </div>
  </div>

  </div>

  <!-- Simple & Safe Section -->
  <div class="container-simple-safe">
    <div class="container-simple-safe__title ime-heading-1 ime-hide-only-on-mobile">
      {{'pay_stores.pay_in_stores.heading.0' | translate}} {{'pay_stores.pay_in_stores.heading.1' | translate}}
    </div>
    <div class="container-simple-safe__title ime-heading-1 ime-show-only-on-mobile">
      {{'pay_stores.pay_in_stores.heading.0' | translate}}<br> {{'pay_stores.pay_in_stores.heading.1' | translate}}
    </div>
    <div class="container-simple-safe__box">
      <div class="card" data-aos="zoom-out-left" data-aos-duration="600">
        <div class="card__image">
          <div class="card__image-1">
            <img
              src="assets/images/look.webp"
              alt=""
              aTilt
              [tiltSettings]="tiltSettings"
            />
          </div>
        </div>
        <div class="card__info">
          <div class="card__heading">
            <div class="circle">{{'pay_stores.pay_in_stores.cards.0.number' | translate}}</div>
            <span class="card__heading__title ime-heading-5">{{'pay_stores.pay_in_stores.cards.0.title' | translate}}</span>
          </div>
          <div class="card__text ime-text-2">
            {{'pay_stores.pay_in_stores.cards.0.description' | translate}}
          </div>
          <img src="assets/icon/contactless-icon.webp" class="card__icon ime-hide-only-on-mobile" alt="" srcset="">
        </div>
      </div>
      <div
        class="card"
        data-aos="zoom-out-left"
        data-aos-delay="300"
        data-aos-duration="600"
      >
        <div class="card__image">
          <div class="card__image-2">
            <img
              src="assets/images/tap.webp"
              alt=""
              aTilt
              [tiltSettings]="tiltSettings"
            />
          </div>
        </div>
        <div class="card__info">
          <div class="card__heading">
            <div class="circle">{{'pay_stores.pay_in_stores.cards.1.number' | translate}}</div>
            <span class="card__heading__title ime-heading-5">{{'pay_stores.pay_in_stores.cards.1.title' | translate}}</span>
          </div>
          <div class="card__text ime-text-2">
            {{'pay_stores.pay_in_stores.cards.1.description' | translate}}
          </div>
        </div>
      </div>
      <div
        class="card"
        data-aos="zoom-out-left"
        data-aos-delay="800"
        data-aos-duration="600"
      >
        <div class="card__image">
          <div class="card__image-3">
            <img
              src="assets/images/go.webp"
              alt=""
              aTilt
              [tiltSettings]="tiltSettings"
            />
          </div>
        </div>
        <div class="card__info">
          <div class="card__heading">
            <div class="circle">{{'pay_stores.pay_in_stores.cards.2.number' | translate}}</div>
            <span class="card__heading__title ime-heading-5">{{'pay_stores.pay_in_stores.cards.2.title' | translate}}</span>
          </div>
          <div class="card__text ime-text-2">
            {{'pay_stores.pay_in_stores.cards.2.description' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Pay like a pro -->
  <div class="container-pay-like-pro">
    <div class="container-pay-like-pro__content">
      <div class="container-pay-like-pro__content__left">
        <div class="container-pay-like-pro__content__left__images">
          <img
            src="assets/images/qr-stand-1.webp"
            alt=""
            srcset=""
            class="img-front"
            data-aos="fade-up-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="800"
            aTilt
            [tiltSettings]="tiltSettings"
          />
          <img
            src="assets/images/visa-card-alt-3.webp"
            alt=""
            srcset=""
            class="img-back"
            data-aos="fade-up-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
      </div>
      <div class="container-pay-like-pro__content__right">
        <div
          class="container-pay-like-pro__content__right__title ime-heading-2"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="500"
        >
          {{'pay_stores.pay_like_pro.heading.0' | translate}} <span class="ime-color-red">{{'pay_stores.pay_like_pro.heading.1' | translate}}</span>
        </div>
        <div
          class="container-pay-like-pro__content__right__sub-title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
        >
        {{'pay_stores.pay_like_pro.subheading' | translate}}
        </div>
      </div>
    </div>
  </div>
  <!-- Get more with IME section -->
  <div class="container-get-more">
    <div class="container-get-more__content content-container">
      <div
        class="container-get-more__content__title ime-heading-1"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="300"
      >
        {{'pay_stores.pay_with_confidence.heading' | translate}}
      </div>
      <div class="container-get-more__content__contents">
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="500"
        >
          <div class="card__image redeemImg">
            <img
              src="assets/images/msg.webp"
              alt=""
              class="redeemImg__main"
              aTilt
              [tiltSettings]="tiltSettings"
            />
          </div>

          <div class="card__info">
            <div class="card__heading ime-heading-5">{{'pay_stores.pay_with_confidence.cards.0.title' | translate}}</div>
            <div class="card__text ime-text-2">
              {{'pay_stores.pay_with_confidence.cards.0.description' | translate}}
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
        >
          <div class="card__image mobileTopUpImg">
            <img
              src="assets/images/1x.webp"
              alt=""
              aTilt
              [tiltSettings]="tiltSettings"
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">{{'pay_stores.pay_with_confidence.cards.1.title' | translate}}</div>
            <div class="card__text ime-text-2">
              {{'pay_stores.pay_with_confidence.cards.1.description' | translate}}
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1500"
        >
          <div class="card__image handImg">
            <img
              src="assets/images/shopping.webp"
              alt=""
              aTilt
              [tiltSettings]="tiltSettings"
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">
              {{'pay_stores.pay_with_confidence.cards.2.title' | translate}}
            </div>
            <div class="card__text ime-text-2">
              {{'pay_stores.pay_with_confidence.cards.2.description' | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Pay smarter section -->
  <div class="container-pay-smarter">
    <div class="container-banner">
      <div class="container-pay-smarter__wrapper">
        <img
          src="assets/images/cart-alt.png"
          alt=""
          class="container-pay-smarter__wrapper__gift-image"
          data-aos="fade-down-right"
          data-aos-delay="1500"
          data-aos-duration="600"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <div class="container-banner__left">
          <img
            src="assets/images/visa-card-alt-1.webp"
            class="container-banner__left__visa-card"
            alt=""
            data-aos="fade-down-right"
            data-aos-delay="500"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
        <div class="container-banner__middle">
          <div
            class="container-banner__middle__title"
            data-aos="flip-left"
            data-aos-duration="600"
          >
            <span class="ime-color-red">{{'pay_stores.faster_and_easier.heading.0' | translate}}</span><br />
            {{'pay_stores.faster_and_easier.heading.1' | translate}}
          </div>
          <div
            class="container-banner__middle__button"
            data-aos="zoom-in-down"
            data-aos-delay="1600"
            data-aos-duration="600"
          >
            <ime-btn-get-app [data]="{ getApp: true }">GET APP</ime-btn-get-app>
          </div>
        </div>
        <div class="container-banner__right">
          <img
            src="/assets/images/qr-stand.webp"
            class="container-banner__right__qr-scan"
            alt=""
            data-aos="fade-left"
            data-aos-delay="1000"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</div>
