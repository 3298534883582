import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'cdn'
})
export class CdnPipe implements PipeTransform {

  //TODO: need to change cdnBaseUrl in both env file.
   cdnBaseUrl : string = environment.CDN_BASE_URL;

  transform(value: string, ...args: unknown[]): unknown {
    return this.cdnBaseUrl+value;
  }

}
