import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import * as AOS from "aos";
import { filter } from "rxjs/operators";
import { ROUTER_BASE_PATH } from "../../constants/router-path";
import { TranslateService } from "@ngx-translate/core";

enum VisibilityState {
  Visible = "visible",
  Hidden = "hidden",
}

@Component({
  selector: "ime-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],

  animations: [
    trigger("toggle", [
      state(
        VisibilityState.Hidden,
        style({
          transform: "translateY(-100px)",
        })
      ),
      state(
        VisibilityState.Visible,
        style({
          transform: "translateY(0px)",
        })
      ),
      transition("* => *", animate("300ms ease-in-out")),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public isVisible = true;
  userLogin: boolean = false; //TODO false by default
  userDetails = {
    userName: "",
    ssid: "",
  };
  headerDynamicText:any = {}

  languageList = ['en', 'np'];
  currentIndex = 0;

  headerMenuItems = [];

  buttonRequired = {
    login: true,
    getApp: false,
    agentLogin: false
  };

  eventOptions = {
    capture: true,
    passive: true,
  };
  windowOffSetValues = [];
  @Input() inputSideNav: MatSidenav;

  language:string = 'en';

  constructor(
    private _router: Router,
    @Inject(DOCUMENT) private _document: Document,
    private translate: TranslateService
  ) {}
  changeHeaderCss = false;

  scrollHandler = (event) => {
    let value = event.srcElement["scrollTop"] || event.path[1].scrollY;
    // if(value) {

    //   this.windowOffSetValues.push(value);
    //   this.windowOffSetValues = this.windowOffSetValues.splice(-3);
    // }
    // this.isVisible =
    //   this.windowOffSetValues[this.windowOffSetValues.length - 1] <
    //   this.windowOffSetValues[this.windowOffSetValues.length - 2];
    // !this.isVisible ?

    if (value > 5) {
      this.changeHeaderCss = true;
    } else {
      this.changeHeaderCss = false;
    }

    AOS.refresh();
    // : '';
  };

  ngAfterViewInit(): void {
    this.changeHeaderCss = false;
    this._document.addEventListener(
      "scroll",
      this.scrollHandler,
      <any>this.eventOptions
    );
  }
  showMenuList = false;

  showMenu = true;

  goToLink(url) {
    window.open(url, "_blank");
  }

  ngOnInit(): void {
    // this._authService.isUserLoggedIn$.subscribe((response) => {
    //   this.userLogin = response;
    // });
    // this._authService.userLoginDetails$.subscribe((response) => {
    //   this.userDetails.userName = response?.fullName;
    //   this.userDetails.ssid = response?.walletBalance;
    // });
    // current url
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url == "/get-app") {
          this.showMenu = false;
          this.buttonRequired.getApp = false;
        } else {
          this.showMenu = true;
          this.buttonRequired.getApp = true;
        }
      });

      this.translate.onLangChange.subscribe(()=>{
        this.fetchHeaderLocalization();
      })
      this.translate.setDefaultLang('en');
      this.fetchHeaderLocalization()
      this.initializeMenus()
  }

  @Output() closeSidenavEvent = new EventEmitter();
  openMenu() {
    this.inputSideNav.open();
    this.closeSidenavEvent.emit(true);
  }

  fetchHeaderLocalization(){
    this.translate.get('navbar').subscribe((value:any)=>{
      this.headerDynamicText = value
      this.initializeMenus()
    })
  }
  toggleLanguage() {
    // Toggle between languages
    this.currentIndex = (this.currentIndex + 1) % this.languageList.length;
    const newLang = this.languageList[this.currentIndex];
    
    // Set the new language
    this.translate.use(newLang);
  
    // Refresh translations
    this.fetchHeaderLocalization();
  }

  initializeMenus(){
    this.headerMenuItems = [
      {
        menuItem: this.headerDynamicText.send_and_receive?this.headerDynamicText.send_and_receive:"Send & Receive",
        redirect: null,
        class: "sub-menu sendReceive",
        subMenu: [
          {
            name: this.headerDynamicText.how_it_works?this.headerDynamicText.how_it_works:"How it works",
            link: ROUTER_BASE_PATH.HOW_IT_WORKS,
          },
          {
            name: this.headerDynamicText.remittance?this.headerDynamicText.remittance:"Remittanceee",
            link: ROUTER_BASE_PATH.REMITTANCE,
          },
          {
            name: this.headerDynamicText.wallet_transfer?this.headerDynamicText.wallet_transfer:"Wallet Transfer",
            link: ROUTER_BASE_PATH.WALLET_TRANSFER,
          },
          {
            name: this.headerDynamicText.bank_transfers?this.headerDynamicText.bank_transfers:"Bank & Transfers",
            link: ROUTER_BASE_PATH.BANK_TRANSFER,
          },
        ],
      },
      {
        menuItem: this.headerDynamicText.pay_with_imepay?this.headerDynamicText.pay_with_imepay:"Pay with IME Pay",
        redirect: null,
        class: "sub-menu pay",
        subMenu: [
          {
            name: this.headerDynamicText.ways_to_pay? this.headerDynamicText.ways_to_pay: "Ways to pay",
            link: ROUTER_BASE_PATH.WAY_TO_PAY,
          },
          {
            name: this.headerDynamicText.pay_for_ime_pay_services? this.headerDynamicText.pay_for_ime_pay_services: "Pay For IME Pay Services",
            link: ROUTER_BASE_PATH.PAY_SERVICE,
          },
          {
            name: this.headerDynamicText.pay_in_apps_and_online? this.headerDynamicText.pay_in_apps_and_online: "Pay in Apps and Online",
            link: ROUTER_BASE_PATH.PAY_ONLINE_IN_APPS,
          },
          {
            name: this.headerDynamicText.pay_in_stores? this.headerDynamicText.pay_in_stores: "Pay in Stores",
            link: ROUTER_BASE_PATH.PAY_IN_STORE,
          },
          {
            name: this.headerDynamicText.ime_pay_visa_card? this.headerDynamicText.ime_pay_visa_card: "IME Pay Visa Card",
            link: ROUTER_BASE_PATH.PAY_THROUGH_CARD,
          },
        ],
      },
      {
        menuItem: this.headerDynamicText.become_ime_agent? this.headerDynamicText.become_ime_agent: "Become an IME Pay Agent",
        redirect: "coming-soon",
        subMenu: null,
      },
    ];
  }

  selectLanguage(lang:string){
    this.translate.use(lang);
    this.language = lang
    // Refresh translations
    this.fetchHeaderLocalization();
  }
}

