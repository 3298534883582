import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PagenotfoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { ComingSoonComponent } from "./shared/components/coming-soon/coming-soon.component";
import { ROUTER_BASE_PATH } from './shared/constants/router-path';

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./landing-page/landing-page.module").then(
        (m) => m.LandingPageModule
      ),
  },

  {
    path: ROUTER_BASE_PATH.COMING_SOON,
    component: ComingSoonComponent,
  },
  {
    path: "**",
    component: PagenotfoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
