//embeded url not normal link
export const YOUTUBE_LINKS ={
    IME_INFO_YOUTUBE_LINK : 'https://www.youtube.com/embed/ZAIyggKtvXk',
    LOAD_BANK_YOUTUBE_LINK : 'https://www.youtube.com/embed/zxlNbFnFb7g',
    SEND_P2P_YOUTUBE_LINK : 'https://www.youtube.com/embed/vjMtuhdSFx0',
    VIRTUAL_VISA_CARDS_ACTIVATE_LINK : 'https://www.youtube.com/embed/eLbuvDfQ_vE',
    PURCHASE_AIR_TICKET :'https://www.youtube.com/embed/R0fTPS3QpNY',
    TRANSACTIONS_HISTORY_YOUTUBE_LINK : 'https://www.youtube.com/embed/7RDNWQVJ6sc',
    LINK_BANK_YOUTUBE_LINK : 'https://www.youtube.com/embed/1HL7Gi6-ISI',
    HOW_TO_SCAN_QR_YOUTUBE_LINK : 'https://www.youtube.com/embed/Dd-lwNPT-xU',
    REMITTANCE_YOUTUBE_LINK :'https://www.youtube.com/embed/7vyAvHzpdT0',
    PAYMENT_YOUTUBE_LINK :'https://www.youtube.com/embed/ekabOuJl7Fg',
    VERIFY_KYC_IMEPAY :'https://www.youtube.com/embed/Dd-lwNPT-xU',
}

export const EXTERNAL_LINKS = {
    CHARGE_N_COMMISION: 'https://blog.imepay.com.np/wp-content/uploads/2022/07/Charge-and-Commission-Details.pdf',
    OUR_AGENTS: 'https://www.imepay.com.np/assets/pdf/Final_Agent_Details.pdf'
}
