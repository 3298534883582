import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { setTiltProp } from 'src/app/shared/service/utilsService/utility.service';

@Component({
  selector: 'ime-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  animationIconOption :AnimationOptions [];

  panelOpenState1 = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
  panelOpenState4 = false;


  customOptions: OwlOptions = {
    rtl: false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplaySpeed: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 3000,
    slideBy: 1,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1080: {
        items: 3,
      },
      1200: {
        items: 3,
      },
      1350: {
        items: 4,
      },
      1440: {
        items: 4,
      },
      1700: {
        items: 5,
      },
      1900: {
        items: 5,
      },
    },
    nav: false,
    autoHeight: true,
  };

  articleData = [{
    articleImage:'assets/images/blog-first.webp',
    articleTime:'Nov 7, 2021',
    articleTitle:'SHOP MORE. PAY LESS – Only at Daraz 11:11 via IME Pay.',
    articleText:'Get an additional 15% discount via IME Pay on your purchase during the big Daraz 11:11 sale! The mega-discount festival is right here.',
    articleType:'blog'
  },
  {
    articleImage:'assets/images/blog-second.png',
    articleTime:'Nov 7, 2021',
    articleTitle:'SHOP MORE. PAY LESS – Only at Daraz 11:11 via IME Pay.',
    articleText:'Get an additional 15% discount via IME Pay on your purchase during the big Daraz 11:11 sale! The mega-discount festival is right here.',
    articleType:'video'
  },
  {
    articleImage:'assets/images/blog-third.png',
    articleTime:'Nov 7, 2021',
    articleTitle:'SHOP MORE. PAY LESS – Only at Daraz 11:11 via IME Pay.',
    articleText:'Get an additional 15% discount via IME Pay on your purchase during the big Daraz 11:11 sale! The mega-discount festival is right here.',
    articleType:'blog'
  }
]

  slides = [
    {
      backgroundColor:
        "linear-gradient(180deg, #F5B12C 0%, rgba(245, 177, 44, 0.48) 100%)",
      text: "Book your airlines ticket directly via app.",
      imgSrc: "assets/images/plane.webp",
      imgClass: "lifestyle-icon-plane",
    },
    {
      backgroundColor:
        "linear-gradient(180deg, #DD3333 0%, rgba(241, 81, 81, 0.48) 100%)",
      text: "Send Money to anyone using their mobile numbers.",
      imgSrc: "assets/icon/rocket.webp",
      imgClass: "lifestyle-icon-rocket",
    },
    {
      backgroundColor:
        "linear-gradient(180deg, #5193DF 0%, rgba(81, 147, 223, 0.48) 100%)",
      text: "Get  highest cashback on top-up.",
      imgSrc: "assets/icon/iPhone-l.png",
      imgClass: "lifestyle-icon-phone",
    },
    {
      backgroundColor:
        "linear-gradient(180deg, #563FE1 0%, rgba(128, 111, 232, 0.48) 100%)",
      text: "Banking has never been this easy.",
      imgSrc: "assets/icon/pig.png",
      imgClass: "lifestyle-icon-pig",
    },
    {
      backgroundColor:
        "linear-gradient(180deg, #00CC8F 0%, rgba(0, 204, 143, 0.48) 100%)",
      text: "Scan any QR and pay easily.",
      imgSrc: "assets/images/cart-alt.png",
      imgClass: "lifestyle-icon-cart",
    },
  ];
  data;
  constructor(private observer: BreakpointObserver, private titleService: Title) {
    this.titleService.setTitle("About Us - IME Pay");
    this.data = {
      slides: this.slides,
      config: this.customOptions,
    };

    this.animationIconOption = [
      {
        path: "assets/images/json-image/icons-linkedin.json",
        loop: false,
        autoplay: false,
      },
      {
        path: "assets/images/json-image/icons-facebook.json",
        loop: false,
        autoplay: false,
      },
      {
        path: "assets/images/json-image/icons-twitter.json",
        loop: false,
        autoplay: false,
      }
    ];
   }
   imgSrcLearMoreArrow = "assets/icon/learnmore-arrow.svg";

  goToLink(url: string) {

    window.open(url, '_blank');

   }

   tiltSettingsPopupImage = setTiltProp(true);
  ngOnInit(): void {

    this.observer.observe('(max-width: 640px)').subscribe((result)=>{
      this.customOptions.autoplaySpeed = 250;
    })
  }

}
