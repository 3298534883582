<section class="remit-page">
  <div class="remit-page__remit content-container">
    <div class="remit-page__remit__left">
      <div class="remit-page__remit__left__img">
        <div class="remit-page__remit__left__img__container">
          <img
            src="assets/images/remit-with-ime.webp"
            class="img__banner"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="200"
            loading="eager"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <img
          src="assets/images/money-gram.webp"
          class="icon-money-gram"
          alt=""
          data-aos="fade-up-left"
          data-aos-easing="ease-out-sine"
          data-aos-duration="700"
          data-aos-delay="700"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <img
          src="assets/images/plane.webp"
          class="remit-icon-plane"
          alt=""
          data-aos="fade-up-right"
          data-aos-easing="ease-out-sine"
          data-aos-duration="700"
          data-aos-delay="500"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>
    </div>
    <div class="remit-page__remit__right">
      <div class="remit-page__remit__right__content">
        <div
          class="remit-page__remit__right__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="700"
          data-aos-anchor-placement="top-bottom"
        >
          <span>{{'remittance.remit_with_imepay.heading.0' | translate}}</span> {{'remittance.remit_with_imepay.heading.1' | translate}} <br />
          {{'remittance.remit_with_imepay.heading.2' | translate}}
        </div>
        <div
          class="remit-page__remit__right__title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="800"
          data-aos-delay="300"
          data-aos-anchor-placement="top-bottom"
        >
        {{'remittance.remit_with_imepay.subheading' | translate}}
        </div>

        <div
          class="remit-page__remit__right__button"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__recieve content-container">
    <div class="remit-page__recieve__info">
      <div class="remit-page__recieve__info__content">
        <div
          class="remit-page__recieve__info__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          {{'remittance.receive_remittance.heading.0' | translate}} <br /><span class="ime-color-red">{{'remittance.receive_remittance.heading.1' | translate}}</span>
        </div>
        <div
          class="remit-page__recieve__info__title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
        {{'remittance.receive_remittance.subheading' | translate}}
        </div>
      </div>
    </div>
    <div class="remit-page__recieve__figure">
      <div class="remit-page__recieve__figure__image">
        <img
          src="assets/images/remittance-mobile_716_1364.webp"
          class="remit-receive"
          alt=""
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="800"
          data-aos-delay="300"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <div
          class="remit-icon-partner"
          data-aos="fade-up-right"
          data-aos-easing="ease-out-sine"
          data-aos-duration="800"
          data-aos-delay="700"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        >
          <ime-json-animator
            [options]="animationIconOptions"
          ></ime-json-animator>
        </div>
      </div>
    </div>
    <div class="remit-page__recieve__method">
      <div class="remit-page__recieve__method__steps steps">
        <div class="steps__step">
          <div
            class="steps__step__header steps__step__header--first ime-heading-5"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            {{'remittance.receive_remittance.cards.0.title' | translate}}
          </div>
          <div
            class="steps__step__info ime-text-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="900"
            data-aos-delay="400"
          >
          {{'remittance.receive_remittance.cards.0.description' | translate}}
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header steps__step__header--second ime-heading-5"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="800"
          >
          {{'remittance.receive_remittance.cards.1.title' | translate}}
          </div>
          <div
            class="steps__step__info ime-text-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="900"
          >
          {{'remittance.receive_remittance.cards.1.description' | translate}}
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header steps__step__header--third ime-heading-5"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="1200"
          >
          {{'remittance.receive_remittance.cards.2.title' | translate}}
          </div>
          <div
            class="steps__step__info ime-text-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="1300"
          >
          {{'remittance.receive_remittance.cards.2.description' | translate}}
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header steps__step__header--fourth ime-heading-6"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
            data-aos-delay="1500"
          >
          {{'remittance.receive_remittance.cards.3.title' | translate}}
          </div>
          <div
            class="steps__step__info steps__step__info--last ime-text-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
            data-aos-delay="1600"
          >
          {{'remittance.receive_remittance.cards.3.description' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__track">
    <div class="remit-page__track__container content-container">
      <div class="remit-page__track__container__left">
        <div class="remit-page__track__container__left__images">
          <img
            src="assets/images/track-money.webp"
            class="track-money-img"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/icon/eye.png"
            class="track-money-icon-eye"
            aTilt
            [tiltSettings]="tiltSettingsPopupEye"
          />
        </div>
      </div>
      <div class="remit-page__track__container__right">
        <div class="remit-page__track__container__right__content">
          <div
            class="remit-page__track__container__right__content__heading ime-heading-1"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
          >
            {{'remittance.track_money.heading.0' | translate}}<br />
            <span>{{'remittance.track_money.heading.1' | translate}}</span>
          </div>
          <div
            class="remit-page__track__container__right__content__title ime-text-1"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
          {{'remittance.track_money.subheading' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__network ime-hide-only-on-mobile">
    <div class="remit-page__network__info">
      <div class="remit-page__network__info__cards cards content-container">
        <div
          class="cards__card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="100"
        >
          <div class="cards__card__image">
            <img src="assets/icon/icon-anchor.png" alt="" />
          </div>
          <div class="cards__card__stats ime-heading-1">{{'remittance.numbers.cards.0.heading' | translate}}</div>
          <div class="cards__card__header">{{'remittance.numbers.cards.0.subheading' | translate}}</div>
        </div>
        <div
          class="cards__card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="300"
        >
          <div class="cards__card__image">
            <img src="assets/icon/icon-sign.png" alt="" />
          </div>
          <div class="cards__card__stats ime-heading-1">{{'remittance.numbers.cards.1.heading' | translate}}</div>
          <div class="cards__card__header">{{'remittance.numbers.cards.1.subheading' | translate}}</div>
        </div>
        <div
          class="cards__card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="600"
        >
          <div class="cards__card__image">
            <img src="assets/icon/icon-bolt.png" alt="" />
          </div>
          <div class="cards__card__stats ime-heading-1">{{'remittance.numbers.cards.2.heading' | translate}}</div>
          <div class="cards__card__header">{{'remittance.numbers.cards.2.heading' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__network ime-show-only-on-mobile">
    <div
      *ngFor="let slide of remittanceNetwork"
      class="remit-page__network__box"
    >
      <div class="network-card">
        <div class="network-card__image">
          <img [src]="slide.img" alt="" />
        </div>
        <div class="network-card__stats ime-heading-1">
          {{ slide.title }}
        </div>
        <div class="network-card__header">{{ slide.desc }}</div>
      </div>
    </div>
  </div>
  <div class="remit-page__remit-steps content-container">
    <div
      class="remit-page__remit-steps__header ime-heading-1 ime-hide-only-on-mobile"
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="800"
      data-aos-delay="100"
    >
      {{'remittance.send_remittance_free.heading' | translate}}
    </div>
    <div
      class="remit-page__remit-steps__header ime-heading-1 ime-show-only-on-mobile"
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="800"
      data-aos-delay="100"
    >
      {{'remittance.send_remittance_free.headings.0' | translate}} <br>{{'remittance.send_remittance_free.headings.1' | translate}}
    </div>
    <div class="remit-page__remit-steps__container steps">
      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="200"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/receiver-contact.webp"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            alt=""
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--first ime-heading-3"
          >
            {{'remittance.send_remittance_free.cards.0.title' | translate}}
          </div>
          <div class="steps__step__info ime-text-1">
            {{'remittance.send_remittance_free.cards.0.description' | translate}}
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="500"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/send-money-enter-details.webp"
            class="steps-icon-enter-details ime-hide-only-on-mobile"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/send-money-enter-details-mobile.webp"
            class="steps-icon-enter-details ime-show-only-on-mobile"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--second ime-heading-3"
          >
          {{'remittance.send_remittance_free.cards.1.title' | translate}}
          </div>
          <div class="steps__step__info ime-text-1">
            {{'remittance.send_remittance_free.cards.1.description' | translate}}
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="800"
      >
        <div class="steps__step__icon">
          <img src="assets/icon/rocket.webp" class="steps-icon-rocket" alt="" />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--third ime-heading-3"
          >
          {{'remittance.send_remittance_free.cards.2.title' | translate}}
          </div>
          <div class="steps__step__info ime-text-1">
            {{'remittance.send_remittance_free.cards.2.description' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__partners content-container ime-hide-only-on-mobile">
    <div class="remit-page__partners__contents">
      <div class="remit-page__partners__contents__top">
        <div class="remit-page__partners__left">
          <div class="remit-page__partners__left__header">
            {{'remittance.favourite_remit_partner.card_title' | translate}}
          </div>
          <div
            class="remit-page__partners__left__types"
            data-aos="fade-up-right"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="700"
            data-aos-delay="400"
          >
          {{'remittance.favourite_remit_partner.cards.0.title' | translate}}
          </div>
          <div
            class="remit-page__partners__left__types"
            data-aos="fade-up-right"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="800"
            data-aos-delay="600"
          >
          {{'remittance.favourite_remit_partner.cards.1.title' | translate}}
          </div>
        </div>
        <div class="remit-page__partners__right">
          <div
            class="remit-page__partners__right__heading ime-heading-2"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="700"
            data-aos-delay="200"
          >
            {{'remittance.favourite_remit_partner.heading' | translate}}
          </div>
          <div
            class="remit-page__partners__right__text ime-text-2--alt"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="800"
            data-aos-delay="400"
          >
          {{'remittance.favourite_remit_partner.subheading' | translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="remit-page__partners__bottom">
      <div *ngFor="let image of allPatners; let i = index">
        <img
          [src]="image"
          alt=""
          srcset=""
          data-aos="fade-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="500"
          [attr.data-aos-delay]="i * 50 + 50"
        />
      </div>
    </div>
  </div>
  <div class="partners content-container ime-show-only-on-mobile">
    <div class="partners__contents">
      <div class="partners__contents__info">
        <div class="partners__contents__title ime-heading-2">
          {{'remittance.favourite_remit_partner.heading' | translate}}
        </div>
        <div class="partners__contents__label ime-text-2--alt">
          {{'remittance.favourite_remit_partner.subheading' | translate}}
        </div>
      </div>
      <div class="partners__contents__intl-patners intl-patners">
        <div class="intl-patners__subtitle">{{'remittance.favourite_remit_partner.cards.0.title' | translate}}</div>
        <div class="intl-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template
              carouselSlide
              *ngFor="let slide of internationalPatners"
            >
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="partners__contents__domestic-patners domestic-patners">
        <div class="domestic-patners__subtitle">{{'remittance.favourite_remit_partner.cards.1.title' | translate}}</div>
        <div class="domestic-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template carouselSlide *ngFor="let slide of domesticPatners">
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
  <div class="container-hidden-fee content-container">
    <div class="container-hidden-fee__box">
      <div class="container-hidden-fee__box__title"
      data-aos="custom-zoom-in"
      data-aos-delay="400"
      data-aos-duration="1000"
      >
        {{'remittance.no_hidden_fees.heading' | translate}}
      </div>
      <img src="assets/icon/pig-no-fee.png" class="icon__pig" alt="" />
      <div
        class="container-hidden-fee__box__subtitle ime-text-4"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="800"
        data-aos-delay="1300"
        data-aos-anchor-placement="top-bottom"
      >
        {{'remittance.no_hidden_fees.subheading' | translate}}
      </div>
      <div
        class="container-hidden-fee__box__learn-more-link"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="800"
        data-aos-delay="1400"
        data-aos-anchor-placement="top-bottom"
      >
        <a [href]="commision_link" target="_blank">{{'buttons.learn_more' | translate}}</a>
      </div>
      <div class="rupees-section">
        <div
          class="rupees-section__wallet-transfer"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
          data-aos-delay="1500"
          data-aos-anchor-placement="top-bottom"
        >
          <span class="charge-rs">{{'remittance.no_hidden_fees.cards.0.title' | translate}}</span>
          <p>{{'remittance.no_hidden_fees.cards.0.description' | translate}}</p>
        </div>
        <div
          class="rupees-section__remmittance"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1200"
          data-aos-delay="1600"
          data-aos-anchor-placement="top-bottom"
        >
          <span class="charge-rs">{{'remittance.no_hidden_fees.cards.1.title' | translate}}</span>
          <p>{{'remittance.no_hidden_fees.cards.1.description' | translate}}</p>
        </div>
      </div>
      <span
        data-aos="fade-up"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="1000"
        data-aos-delay="1800"
        data-aos-anchor-placement="top-bottom"
        class="ime-hide-only-on-mobile"
      >
      {{'remittance.no_hidden_fees.conditions' | translate}}
      </span>
      <span class="ime-show-only-on-mobile">*T&C Apply</span>
      <img
        src="assets/images/Hand.webp"
        class="icon__hand"
        alt=""
        data-aos="fade-left"
        data-aos-easing="ease-out-sine"
        data-aos-duration="600"
        data-aos-delay="900"
        data-aos-anchor-placement="top-bottom"
      />
      <img
        src="assets/images/plane.webp"
        class="icon__plane"
        alt=""
        data-aos="fade-down"
        data-aos-delay="1100"
        data-aos-easing="fade-right"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
      />
    </div>
  </div>

  <div class="container-pay-like-pro">
    <div class="container-pay-like-pro__bg">
      <div class="container-pay-like-pro__content content-container">
        <div class="container-pay-like-pro__content__left">
          <div class="container-pay-like-pro__content__left__images">
            <img
              src="assets/images/remit-like-pro-2.webp"
              alt=""
              srcset=""
              class="img-front"
              data-aos="fade-up-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="100"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/remit-like-pro-1.webp"
              alt=""
              srcset=""
              class="img-back"
              data-aos="fade-up-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="100"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/Lightning.webp"
              alt="lightning"
              srcset=""
              class="icon-lightning"
              data-aos="fade-down"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="800"
            />
          </div>
        </div>
        <div class="container-pay-like-pro__content__right">
          <div
            class="container-pay-like-pro__content__right__title ime-heading-2"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="1000"
          >
            {{'remittance.fast_safe_reliable.heading.0' | translate}}<span class="ime-color-red"> {{'remittance.fast_safe_reliable.heading.1' | translate}} </span>
          </div>
          <div
            class="container-pay-like-pro__content__right__sub-title ime-text-1"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
          {{'remittance.fast_safe_reliable.subheading' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-do-more">
    <div class="container-do-more__content content-container">
      <div
        class="container-do-more__content__title ime-heading-1 ime-hide-only-on-mobile"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="300"
      >
        {{'remittance.receive_without_borders.heading' | translate}}
      </div>
      <div
        class="container-do-more__content__title ime-heading-1 ime-show-only-on-mobile"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="300"
      >
        {{'remittance.receive_without_borders.heading_mob.0' | translate}}<br/>{{'remittance.receive_without_borders.heading_mob.1' | translate}}
      </div>
      <div class="container-do-more__content__contents">
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="500"
        >
          <div class="card__image redimImg"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/secure-sheild.webp"
              class="card__image__sheild"
              alt=""
              srcset=""
            />
            <img
              src="assets/images/robot.webp"
              class="card__image__reward-point"
              alt=""
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">{{'remittance.receive_without_borders.cards.0.title' | translate}}</div>
            <div class="card__text ime-text-2 card__text--large">
              {{'remittance.receive_without_borders.cards.0.description' | translate}}
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
        >
          <div class="card__image mobileTopUpImg"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/get-cash-bonus.webp"
              class="card__image__cash-back"
              alt=""
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">{{'remittance.receive_without_borders.cards.1.title' | translate}}</div>
            <div class="card__text ime-text-2">
              {{'remittance.receive_without_borders.cards.1.description' | translate}}
            </div>
          </div>
        </div>

        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1500"
        >
          <div class="card__image handImg"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/find-near-by.webp"
              class="card__image__free-transaction"
              alt=""
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">{{'remittance.receive_without_borders.cards.2.title' | translate}}</div>
            <div class="card__text ime-text-2">
              {{'remittance.receive_without_borders.cards.2.description' | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-life-easy">
    <div class="container-life-easy__background">
      <div class="container-life-easy__container content-container">
        <div class="container-life-easy__left">
          <div class="container-life-easy__left__header ime-heading-7">
            {{'remittance.move_money_easily.heading.0' | translate}}<br />{{'remittance.move_money_easily.heading.1' | translate}} <span class="ime-color-red">{{'remittance.move_money_easily.heading.2' | translate}}</span>
          </div>
          <div class="get-app__button">
            <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
          </div>
        </div>
        <div class="container-life-easy__right">
          <div class="container-life-easy__right__container">
            <div class="container-life-easy__right__container__img">
              <img
                src="assets/images/more-with-money-2.webp"
                class="container-life-easy__right__container__img__front"
                alt=""
                data-aos="fade-down"
                data-aos-delay="600"
                data-aos-easing="ease-in-out-back"
                data-aos-duration="1000"
                aTilt
                [tiltSettings]="tiltSettingsPopupImage"
              />
              <img
                src="assets/images/more-with-money-1.webp"
                class="container-life-easy__right__container__img__back"
                alt=""
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-easing="ease-in-out-back"
                data-aos-duration="1000"
                aTilt
                [tiltSettings]="tiltSettingsPopupImage"
              />
            </div>
            <img
              src="assets/images/plane.webp"
              class="container-life-easy__right__plane"
              alt=""
              data-aos="fade-down"
              data-aos-delay="800"
              data-aos-easing="fade-right"
              data-aos-duration="1000"
            />

            <img
              src="assets/images/Gift_Left.webp"
              class="container-life-easy__right__gift"
              alt=""
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</section>
