<section class="PP-container">
  <div class="PP-container__content">
    <div class="desc-section">

      <div class="ime-heading-4 page-title">
        <span>Grievances Mechanism</span>
      </div>

      <div class="update-date">
        <div class="ime-heading-5">Last Updated</div>
        <p class="ime-text-2">Feb 9, 2023</p>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">A. Who can make a grievance ?</h5>
        <div class="desc ime-text-2">
          <p>
            Any customer who faces minor or major issue at the time of transaction can receive remedy to their problems
            by reporting their grievance. Grievance can be reported in case of issues encountered as for loss of
            mobile phone/PIN, amount transferred to wrong wallet under peer to peer transactions,
            amount debited and transaction not completed, fraud, scam related issues.
          </p>
        </div>
      </div>

      <div class="blocks-desc">
        <h5 class="title ime-heading-5">B. How to submit a grievance ?</h5>
        <div class="desc ime-text-2">
          <p>
            Customer can lodge their Grievances via calling in Customer help-desk or through chat option of Viber, WhatsApp, website.
          </p>
        </div>
      </div>
      <div class="blocks-desc">
        <h5 class="title ime-heading-5">C. WHAT IS THE Grievance Review Process ?</h5>
        <div class="desc ime-text-2">
          <ul>
            <li>Support team will resolve the grievance depending upon the nature.
              The support team will review the details, analyze to diagnose, and identify the appropriate course of action to resolve as quickly as possible.
            </li>
            <li>          If there exists any delay in taking action, the support staff escalates the grievance to IT operation.
            </li>
            <li>          If the Grievance cannot be resolved, then it will be further escalated to relevant functional team (business, account, technical), respective functional team will acknowledge the grievance and necessary action shall be taken.
            </li>
          </ul>

        </div>
      </div>
      <div class="blocks-desc">
        <h5 class="title ime-heading-5">D. Details of Grievance officer ?</h5>
        <div class="desc ime-text-2">
          <p>
            If grievance cannot be resolved even after lodging it in the Customer help desk and escalating it to the various department concerned,
            then only as a last remedy, customer can lodge their Grievances through reporting it to Grievance Officer. The details are as follows:
          </p>
            <strong>Name: </strong>  Anil Tharu<br/>
          <strong>Designation: </strong>  Customer Experience Officer<br/>
            <strong>Contact Details: </strong>  4217600/ 9801822841<br/>
              <strong> Email ID: </strong> <a href = "mailto: anil.tharu@imepay.com.np">anil.tharu@imepay.com.np</a>
            <p>
            Customer can also report Grievances in following link as provided by Nepal Rastra Bank <a href="https://gunaso.nrb.org.np/" target="_blank">https://gunaso.nrb.org.np/</a>
            </p>

        </div>
      </div>
    </div>

    <div class="menu-section">
      <ul>
        <li class="ime-text-2 active">Grievances Mechanism </li>
        <li class="ime-text-2" [routerLink]="'../' + routerPath.SAFETY_AND_COMPLIANCE">Safety & Compliance </li>
      </ul>
    </div>
  </div>
</section>

<ime-footer></ime-footer>
