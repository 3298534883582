<div class="custom-dialog-container">
  <div class="custom-dialog-container__header">
    <p class="custom-dialog-container__header--title">{{title}} </p>
    <p class="custom-dialog-container__header--close">
      <img src="assets/icon/close.svg" alt="" (click)="close()">
    </p>
  </div>
    <iframe width="100%" height="100%" [src]="youtubeUrl | safeUrl:'url'" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

</div>
