import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { EXTERNAL_LINKS } from "src/app/shared/constants/constants";

import { setTiltProp } from "src/app/shared/service/utilsService/utility.service";

@Component({
  selector: "ime-wallet-transfer",
  templateUrl: "./wallet-transfer.component.html",
  styleUrls: ["./wallet-transfer.component.scss"],
})
export class WalletTransferComponent implements OnInit {

  commision_link = EXTERNAL_LINKS.CHARGE_N_COMMISION;
  constructor(private titleService: Title) {
    this.titleService.setTitle("Wallet Transfer - Ime Pay");
  }
  tiltSettingsPopupImage = setTiltProp(true);
  tiltSettingsImage = setTiltProp();
  imgSrcLearMoreArrow = "assets/icon/learnmore-arrow.svg";

  ngOnInit(): void {}
}
