<section class="PP-container">
    <div class="PP-container__content">
        <div class="desc-section">

            <div class="ime-heading-4 page-title">
                <span>Safety & Compliance</span>
            </div>

            <div class="update-date">
                <div class="ime-heading-5">Last Updated</div>
                <p class="ime-text-2">Feb 9, 2023</p>
            </div>

            <div class="blocks-desc">
                <h5 class="title ime-heading-5">IME/IME Pay Cautions you about</h5>
                <div class="desc ime-text-2">
                    <p>
                        <strong>Foreign Employment Scam:</strong> If you come across social media messages or ads
                        offering foreign employment opportunities that seem too good to be true, be wary. These types of
                        people may ask you to either remit the money or send money to a wallet or deposit funds into a
                        bank account as a "processing fee" or for other pre-employment expenses. These messages are
                        often scams and the people behind them are trying to make money off of you. Do not fall for
                        these frauds.
                    </p>
                    <p>
                        <strong>Lottery Scam:</strong> Beware of any messages or calls claiming that you have won a
                        lottery, whether domestic or foreign, and requesting that you pay a fee to claim your prize.
                        These scams often occur through social media, phone calls, or messaging apps like WhatsApp or
                        Viber. Even if the notice appears legitimate, it is important to exercise caution and not send
                        any money to claim a lottery prize. These types of scams are designed to trick you into giving
                        away your money.
                    </p>
                    <p>
                        <strong>Internet related purchase Scam:</strong> Beware of internet ads that offer extremely
                        cheap products or services that seem too good to be true and ask you to send money through IME
                        or deposit it into a wallet account. These sellers may ask for payment for delivery or a booking
                        fee, and may even send fake authentication letters or emails. Do not send any money as it is a
                        scam and you will not receive the merchandise. Any money sent cannot be recovered.
                    </p>
                    <p>
                        <strong>Relative/friend in need scam:</strong> Beware of scams involving requests for money in
                        exchange for a loan. This can occur through ads, social media posts, emails, letters, or
                        messages. If you are asked to send money in order to receive a loan, do not do It. This is a
                        scam and once you send the money, it will be difficult to recover. Protect yourself by being
                        cautious and not falling for this trick, as it could result in the loss of the money you sent.
                    </p>
                    <p>
                        <strong>Loan Scam:</strong> Beware of internet ads that offer extremely cheap products or
                        services that seem too good to be true and ask you to send money through IME or deposit it into
                        a wallet account. These sellers may ask for payment for delivery or a booking fee, and may even
                        send fake authentication letters or emails. Do not send any money as it is a scam and you will
                        not receive the merchandise. Any money sent cannot be recovered.
                    </p>
                    <p>
                        <strong>Donation Scam:</strong> Be careful of any ads, messages, social media posts, or calls
                        that ask you to donate money to help people in need, especially after a natural disaster such as
                        a flood, fire, or earthquake. Some of these may be scams trying to trick you into giving money.
                        To protect yourself, make sure to check if the organization asking for donations is real before
                        giving any money. If you do send money, it may be difficult to get it back.
                    </p>
                    <p>
                        <strong>Newspaper ads Scam:</strong> Be wary of any ads that ask you to send money in exchange
                        for a product or service, especially if the offer seems too good to be true. These types of
                        scams may promise high returns on investments, easy loans, or products at significantly
                        discounted prices. Always do your research and verify the legitimacy of any company or
                        individual before sending any money. Be especially cautious of ads that require you to remit the
                        money or deposit it into wallet account or bank account, as this is a common tactic used by
                        scammers and it can be difficult to recover the money once it has been sent.
                    </p>
                    <p>
                        <strong>Romance:</strong> If you've made friends online and things seem to be going well, but
                        then they suddenly ask you for money to solve a financial problem or to travel to meet you, be
                        very careful. These could be

                        scams and you may not be able to recover the money sent. Be cautious when someone you've only
                        met online asks for money.
                    </p>
                    <p>
                        <strong>Networking Scam</strong> Networking scams are fraudulent schemes that use the guise of
                        professional networking opportunities to scam individuals or businesses. Be cautious of any
                        unexpected job offers or business opportunities, especially if they seem too good to be true.
                        Don't give out personal or financial information to anyone you don't know, and be wary of
                        requests to transfer money or pay upfront fees for promised services. Do your research and
                        verify the legitimacy of any company or individual before engaging in any business dealings.
                        Remember that if something seems too good to be true, it probably is.
                    </p>
                    <p>
                        <strong>Pyramid scheme Scam:</strong> A pyramid scheme is a type of investment scam in which
                        each person involved recruit’s others to join. Money made by the new members funnels up to the
                        higher members. At the top of the pyramid are the scammers who take in all the money and leave
                        the rest of the members with nothing. These schemes often rely on the constant recruitment of
                        new members to bring in funds, as there is no real product or service being sold. Pyramid
                        schemes are illegal in many countries, including Nepal, and are a form of investment fraud. If
                        you are approached to join a pyramid scheme, be cautious and do your research before investing
                        any money. Be wary of any opportunity that requires you to recruit new members in order to make
                        a profit, as this is a common tactic used by pyramid scheme operators. Remember, if an
                        investment opportunity seems too good to be true, it probably is.
                    </p>
                    <p>
                        <strong>Rent/Housing/land Scam:</strong> Be careful of anyone who asks you to pay an upfront fee
                        to help you find a house or land to purchase or rent. Some of these people might ask you to
                        deposit money into their account and then not be available to contact. It's best to only deal
                        with people you know or who have a good reputation. Be wary of anyone who asks for money before
                        they provide a service.
                    </p>
                    <p>
                        <strong>Identity Theft:</strong> It is important to exercise caution when it comes to sharing
                        personal information, such as citizenship documents, driver's licenses, passports, or other
                        forms of identification, with individuals or websites that are unknown or untrusted. There is a
                        risk that this information may be used for nefarious purposes, including identity theft and
                        illegal financial transactions. To protect yourself, it is advisable to keep personal
                        information private and only share it with trusted sources or on secure websites. ID’s can be
                        used to open wallet accounts or do unauthorized domestic remit transaction.
                    </p>
                    <p>
                        <strong>Online Gambling:</strong> Online gambling, including sites like 1xbet and bet365, is
                        illegal in Nepal. If you use IME or IME Pay, it is important to stay away from these types of
                        sites. They may have agents who help people use and withdraw money from the site. However, if
                        you lose money through online gambling, you won't be able to get it back. If the police catch
                        you gambling online, you could face legal action. So it's best to avoid online gambling
                        altogether.
                    </p>
                    <p>
                        <strong>Pin/Password Theft:</strong> Pin and password theft is a type of cybercrime in which an
                        individual's personal identification numbers (PINs) or passwords are stolen and used to gain
                        unauthorized access to their accounts or personal information. To protect yourself from pin and
                        password theft, it is important to use strong, unique passwords for each of your accounts and to
                        avoid sharing them with anyone. You should also avoid writing down your passwords or storing
                        them in a location that is easily accessible to others. If you suspect that your password or PIN
                        has been compromised, it is important to change it immediately and to monitor your accounts for
                        any suspicious activity. It is also a good idea to enable two-factor

                        authentication on your accounts, which adds an extra layer of security by requiring you to
                        provide a one-time code in addition to your password when logging in.
                    </p>

                </div>
            </div>

            <div class="blocks-desc">
                <h5 class="title ime-heading-5">Be aware of all the Scam and notify your nearest police station and file a case regarding the scam.</h5>
                <div class="desc ime-text-2">
                    <p>
                        <strong>Phishing/fishing scam:</strong> Phishing and fishing are types of cyber scams that are designed to trick individuals into revealing sensitive personal or financial information, such as passwords, login credentials, or credit card numbers. These scams typically involve the use of fake emails, text messages, or websites that appear to be legitimate, but are actually designed to steal your information.
                    </p>
                    <p>
                        To protect yourself from phishing and fishing scams, it is important to be cautious when clicking on links or downloading attachments from unknown or suspicious sources. You should also avoid entering personal or financial information on websites that you do not trust or that do not have a secure connection. If you receive an email or message that asks for your personal or financial information, do not respond or click on any links. Instead, contact the company directly to verify the authenticity of the request. It is also a good idea to use anti-phishing and anti-virus software to help protect your device from these types of scams.
                    </p>
                    <p>
                        <strong>PIN/Password/OTP Share:</strong> Do not give out any personal information like PIN or passwords or OTP to anyone, especially for your wallet or bank account. If you do, someone may be able to take your money without your permission. It will be hard to get your money back once stolen.
                    </p>
                </div>
            </div>
            <div class="blocks-desc">
                <h5 class="title ime-heading-5 center">How to Protect Against Fraud</h5>
                <div class="desc ime-text-2">
                    <p>Protecting yourself against consumer fraud</p>
                  <p>
                      To protect your hard-earned money from thieving fraudsters, it's critical to stay one step ahead of them by understanding their tricks and exercising sound judgment. You can stop these con artists from succeeding by following a few simple tips and using common sense. Remember that every scam relies on someone falling for it, so don't be that person! Here are some precautions you can take to avoid wire transfer fraud:
                  </p>
                  <p>Always:</p>
                  <ul>
                      <li>Be aware of who you're dealing with, especially if you're dealing with an unsolicited prize or gift offer. Never believe a message that says, "Congratulations, you just won Nrs XXXX amount in a foreign lottery!"</li>
                      <li>Research any offer to ensure it is genuine. If something appears to be too good to be true, it most likely is.</li>
                      <li>Remember that wiring money is the same as sending cash. You can't get it back once you've sent it.</li>
                  </ul>
                  <p>Never</p>
                  <ul>
                      <li>Never send money to someone you don't know!</li>
                      <li>Deposit a check from someone who instructs you to return some of the funds.</li>
                      <li>Send money to a relative/friends in need without first investigating the situation. In order to confirm the person's identity, ask questions.</li>
                      <li>Never send money in order to receive some prize money back.</li>
                      <li>Never share your PIN/Password or OTP to someone you don't trust your money with</li>
                  </ul>
                </div>
              </div>
              <div class="blocks-desc">
                <h5 class="title ime-heading-5 center">Fraud Prevention</h5>
                <div class="desc ime-text-2">
                  <p>
                    At IME/IMEPAY Pay, we are fiercely committed to protecting consumers from those who seek to exploit our services. These deceptive actors will tell you lies in order to get you to wire money to them, fully aware that you will be unable to retrieve it. To put a stop to them, you must first understand their methods of deception. That is why IME/IMEPAY Pay is committed to educating the public; we want to equip you to avoid becoming a victim of these scams.
                  </p>
                  <p>
                    Our aim is to provide you with the knowledge and tools to protect your finances and personal information from fraudulent schemes. Take a look at our list of <strong>common consumer scams</strong> to be aware of the red flags. Keep in mind that there are always new scams emerging, so it's crucial to use your best judgement when sending money. The most important piece of advice we can offer is to <strong>never send money to someone you do not know.</strong>
                  </p>
                  <p>
                    If you have been victim of fraud, please report the incident at the nearest police station and also report the incident to us. You can call our customer support at 01-4217600 or email: <strong>info@imepay.com.np</strong>
                  </p>
                </div>
              </div>
              <div class="blocks-desc">
                <h5 class="title ime-heading-5 center">Fraud- FAQ</h5>
                <div class="desc ime-text-2">
                  <p>
                    <strong>What do I do if I have been scammed?</strong>
                  </p>
                  <p>
                    If you have been scammed or feel like you have been scammed report immediately to your local police. If you have use IMEPAY to send money then you can report the incident at our customer care at 01-4217600 or email us: <strong>info@imepay.com.np</strong>
                  </p>
                </div>
              </div>

              <div class="blocks-desc">
                <div class="desc ime-text-2">
                  <p>
                    <strong>What are some of the red flag to look out for to protect own self form scam?</strong>
                  </p>
                <ul>
                    <li>A person you do not know requests that you transfer money to them.</li>
                    <li>Someone asks you to deposit a check they have given you, and then send a portion of the funds to them.</li>
                    <li>If something appears to be too good to be true, it most likely is. Do not fall for internet ads, newspaper ads that pitch product and services that are too good to be true.</li>
                    <li>A person pretending to be a friend or family member contacts you and claims to be in a crisis, urging you to send them money immediately.</li>
                    <li>Someone tells you that you have won a prize or contest that you do not recall participating in, and asks you to transfer money to cover fees, taxes, or customs.</li>
                </ul>
                </div>
              </div>

              <div class="blocks-desc">
                <div class="desc ime-text-2">
                  <p>
                    <strong>Who is at risk for money transfer Fraud?</strong>
                  </p>
                  <p>Everyone is potentially vulnerable to scams. Fraudsters do not discriminate in their targets and may target anyone, regardless of their age, gender, or background. They often seek out individuals who may be unaware of a scam, trusting of others, or perceived as more vulnerable in some way.</p>
                </div>
              </div>
              <div class="blocks-desc">
                <div class="desc ime-text-2">
                  <p>
                    <strong>Is it safe to use IMEPAY services to send money?</strong>
                  </p>
                  <p>Yes, it is safe and secure to send money via IMEPAY. However, when you are unsure of the identity of the recipient, there is a risk in transferring money as it is similar to sending cash. Once the money has been sent, it is typically not recoverable, similar to how physical cash cannot be retrieved once it has been given away.</p>
                </div>
              </div>
              <div class="blocks-desc">
                <div class="desc ime-text-2">
                  <p>
                    <strong>Is it safe to share OPT/PIN/Password?</strong>
                  </p>
                  <p>Never share your OPT/PIN/Password with anybody you don't trust your money with. Sharing this information can lead to unauthorized access to your agent web portal/wallet/bank account that can result in financial loss.</p>
                </div>
              </div>
              <div class="blocks-desc">
                <div class="desc ime-text-2">
                  <p>
                    <strong>Is online gambling legal in Nepal?</strong>
                  </p>
                  <p>Online gambling is illegal in Nepal and has legal consequences. It is best to avoid using these sites.</p>
                </div>
              </div>
        </div>

        <div class="menu-section">
            <ul>
              <li class="ime-text-2 " [routerLink]="'../' + routerPath.GRIEVANCES" >Grievances Mechanism </li>
                <li class="ime-text-2 active">Safety & Compliance</li>
            </ul>
        </div>
    </div>
</section>

<ime-footer></ime-footer>
