import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'ime-json-animator',
  templateUrl: './ime-json-animator.component.html',
  styleUrls: ['./ime-json-animator.component.scss']
})
export class ImeJsonAnimatorComponent implements OnInit {


  amimationItem;
  @Input('options') options: AnimationOptions;

  @Input('containerClass') containerClass;

  constructor() { }


  mouseEnter() {
    this.amimationItem.loop = true;
    // this.amimationItem.goToAndPlay(0, true);
  }

  mouseleave() {
      this.amimationItem.loop = this.options.loop;
  }
  animationCreated(animationItem: AnimationItem): void {
    this.amimationItem = animationItem;
  }

  ngOnInit(): void {
  }

}
