// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SIGNALR_URL: 'http://202.166.194.89:8771/UserConnectionHub', //TODO: #Pseudo end-point. Change connection url
  SECRET_KEY: 'Yn3hT8LBBg7GaqpMYH',
  CLEVERTAP_PROJECT_ID: 'TEST-564-64W-R65Z',
  CDN_BASE_URL: 'https://cdn.imepay.com.np/web/images/',
  SMS_PROXY_SERVER: 'https://smsproxy.imepay.com.np:8082/msg'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
