import { Component, OnInit, ViewChild } from '@angular/core';
import {  MatSidenav } from "@angular/material/sidenav";
import { ROUTER_BASE_PATH } from '../../constants/router-path';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'ime-side-nav-mobile',
  templateUrl: './side-nav-mobile.component.html',
  styleUrls: ['./side-nav-mobile.component.scss']
})
export class SideNavMobileComponent implements OnInit {
  @ViewChild('sideNav', {static: true}) sidenav: MatSidenav;

  language:string = 'en';

  showHeader = true;
  menuItems = [
    {
      menuItem: "Send & Receive",
      class: 'menu__sendReceive',
      subMenu: [
        {
          name: "How it works",
          link: ROUTER_BASE_PATH.HOW_IT_WORKS,
        },
        {
          name: "Remittance",
          link: ROUTER_BASE_PATH.REMITTANCE,
        },
        {
          name: "Wallet Transfer",
          link: ROUTER_BASE_PATH.WALLET_TRANSFER,
        },
        {
          name: "Bank & Transfers",
          link: ROUTER_BASE_PATH.BANK_TRANSFER,
        },
      ],
    },
    {
      menuItem: "Pay with IME Pay",
      class: 'menu__withImePay',
      subMenu: [
        {
          name: "Ways to pay",
          link: ROUTER_BASE_PATH.WAY_TO_PAY,
        },
        {
          name: "Pay For IME Pay Services",
          link: ROUTER_BASE_PATH.PAY_SERVICE,
        },
        {
          name: "Pay in Apps and Online",
          link: ROUTER_BASE_PATH.PAY_ONLINE_IN_APPS,
        },
        {
          name: "Pay in Stores",
          link: ROUTER_BASE_PATH.PAY_IN_STORE,
        },
        {
          name: "IME Pay Visa Card",
          link: ROUTER_BASE_PATH.PAY_THROUGH_CARD,
        },
      ],
    },

  ];
  constructor(private translate: TranslateService) { }
  headerDynamicText:any = {}
  
  ngOnInit(): void {
    this.translate.onLangChange.subscribe(()=>{
      this.fetchHeaderLocalization();
    })
  }
  fetchHeaderLocalization(){
    this.translate.get('navbar').subscribe((value:any)=>{
      this.headerDynamicText = value
      this.initializeMenus()
    })
  }
  closeMenu(){
    this.showHeader = !this.showHeader;
    this.sidenav.close();
  }
  hideHeader(eventData){
    this.showHeader = !this.showHeader;
  }

  initializeMenus(){
    this.menuItems = [
      {
        menuItem: this.headerDynamicText.send_and_receive?this.headerDynamicText.send_and_receive:"Send & Receive",
        class: 'menu__sendReceive',
        subMenu: [
          {
            name: this.headerDynamicText.how_it_works?this.headerDynamicText.how_it_works:"How it works",
            link: ROUTER_BASE_PATH.HOW_IT_WORKS,
          },
          {
            name: this.headerDynamicText.remittance?this.headerDynamicText.remittance:"Remittance",
            link: ROUTER_BASE_PATH.REMITTANCE,
          },
          {
            name: this.headerDynamicText.wallet_transfer?this.headerDynamicText.wallet_transfer:"Wallet Transfer",
            link: ROUTER_BASE_PATH.WALLET_TRANSFER,
          },
          {
            name: this.headerDynamicText.bank_transfers?this.headerDynamicText.bank_transfers:"Bank & Transfers",
            link: ROUTER_BASE_PATH.BANK_TRANSFER,
          },
        ],
      },
      {
        menuItem: this.headerDynamicText.pay_with_ime_pay?this.headerDynamicText.pay_with_ime_pay:"Pay with IME Pay",
        class: 'menu__withImePay',
        subMenu: [
          {
            name: this.headerDynamicText.ways_to_pay?this.headerDynamicText.ways_to_pay:"Ways to pay",
            link: ROUTER_BASE_PATH.WAY_TO_PAY,
          },
          {
            name: this.headerDynamicText.pay_for_ime_pay_services?this.headerDynamicText.pay_for_ime_pay_services:"Pay For IME Pay Services",
            link: ROUTER_BASE_PATH.PAY_SERVICE,
          },
          {
            name: this.headerDynamicText.pay_in_apps_and_online?this.headerDynamicText.pay_in_apps_and_online:"Pay in Apps and Online",
            link: ROUTER_BASE_PATH.PAY_ONLINE_IN_APPS,
          },
          {
            name: this.headerDynamicText.pay_in_stores?this.headerDynamicText.pay_in_stores:"Pay in Stores",
            link: ROUTER_BASE_PATH.PAY_IN_STORE,
          },
          {
            name: this.headerDynamicText.ime_pay_visa_card?this.headerDynamicText.ime_pay_visa_card:"IME Pay Visa Card",
            link: ROUTER_BASE_PATH.PAY_THROUGH_CARD,
          },
        ],
      },
  
    ];
  }

  selectLanguage(lang:string){
    this.translate.use(lang);
    this.language = lang
  }
}
