import { Injectable } from '@angular/core';
import {  BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YoutubeserviceService {
  public embedUrlSource$ = new BehaviorSubject<string>(null);
  constructor(){
  }

  setUrl(url:string){
    this.embedUrlSource$.next(url+'?autoplay=1');
  }

  

}
