<div class="SL-container">
  <div class="SL-container__box">
    <div class="SL-container__content">
      <div class="SL-container__heading">Support Help Line Numbers</div>
      <div class="SL-container__contact">
        <div class="SL-container__contact__icon">
          <div class="icon__container">
            <img
              src="assets/icon/support-agent-black.svg"
              alt=""
              class="icon-support"
              srcset=""
            />
          </div>
        </div>
        <div class="SL-container__contact__info">
          <span class="phone-title">Toll Free : </span>
          <span class="phone-number" [cdkCopyToClipboard]="tollFreeNo" (click)="notify(0)">{{tollFreeNo}}</span>
          <div class="copied-text" *ngIf="copiedStatus[0]">copied </div>
        </div>
      </div>
      <div class="SL-container__contact">
        <div class="SL-container__contact__icon">
          <div class="icon__container">
            <img
              src="assets/icon/support-phone.svg"
              alt=""
              class="icon-phone"
              srcset=""
            />
          </div>
        </div>
        <div class="SL-container__contact__info">
          <span class="phone-title">Phone : </span>
          <span class="phone-number" [cdkCopyToClipboard]="phone" (click)="notify(1)">9803556655</span>
          <div class="copied-text" *ngIf="copiedStatus[1]">copied </div>
        </div>
      </div>
      <div class="SL-container__contact">
        <div class="SL-container__contact__icon">
          <div class="icon__container">
            <img
              src="assets/icon/support-viber.svg"
              alt=""
              class="icon-viber"
              srcset=""
            />
          </div>
        </div>
        <div class="SL-container__contact__info">
          <span class="phone-title">Viber : </span>
          <span class="phone-number" [cdkCopyToClipboard]="phone" (click)="notify(2)">{{phone}}</span>
          <div class="copied-text" *ngIf="copiedStatus[2]">copied </div>
        </div>
      </div>
      <div class="SL-container__contact">
        <div class="SL-container__contact__icon">
          <div class="icon__container">
            <img
              src="assets/icon/support-whatsapp.svg"
              alt=""
              class="icon-whatsapp"
              srcset=""
            />
          </div>
        </div>
        <div class="SL-container__contact__info">
          <span class="phone-title">Whatapp : </span>
          <span class="phone-number" [cdkCopyToClipboard]="phone" (click)="notify(3)">{{phone}}</span>
          <div class="copied-text" *ngIf="copiedStatus[3]">copied </div>
        </div>
      </div>
      <div class="SL-container__contact">
        <div class="SL-container__contact__icon">
          <div class="icon__container">
            <img
              src="assets/icon/support-email.svg"
              alt=""
              class="icon-email"
              srcset=""
            />
          </div>
        </div>
        <div class="SL-container__contact__info">
          <span class="phone-title">Email : </span>
          <span class="phone-number"> <a class="phone-number" href="mailto:{{email}}">
            {{email}}
          </a></span>
          <div class="copied-text" *ngIf="copiedStatus[4]">copied </div>
        </div>
      </div>
    </div>
  </div>
</div>
