    <div [ngClass]="mainClass">
        <div class="ads-container__contents">
            <div class="ads-container__contents__left">
                <img src="assets/promo-images/iphone-13pro.png" alt="" srcset="">
            </div>
            <div class="ads-container__contents__right">
                <button mat-raised-button>Book your’s now</button>
            </div>
        </div>
        <div class="ads-container__close">
            <div class="ads-container__close__circle">
                <img src="assets/icon/close.svg" alt="" (click)="close()">
            </div>
        </div>
    </div>