
export function isSafari(){
  var ua = navigator.userAgent.toLowerCase(); 
  if (ua.indexOf('safari') != -1) { 
    if (ua.indexOf('chrome') > -1) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
export function isMobileDevice() {

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  return regexp.test(details);

}


export function setTiltProp(isPopup?:boolean) {
  return {
    max: isMobileDevice() ? 0 : (isPopup ? 50: 30),
    speed: 1000,
    scale: isPopup ? 1.1 : 1,
    transition: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
    perspective: isPopup ? 800 : 600,
    glare: false,
  }
}

