import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'ime-btn-get-app',
  templateUrl: './btn-get-app.component.html',
  styleUrls: ['./btn-get-app.component.scss']
})
export class BtnGetAppComponent implements OnInit {
  downloadAnimationIconOption: AnimationOptions;
  downloadAnimationIcon;

  @Input('data') data;
  constructor(private _router: Router, private translate: TranslateService) {
    this.downloadAnimationIconOption = {
      path: 'assets/images/json-image/icons-download-primary.json',
      loop: false,
      autoplay: false
    };
   }

  playAnimation(play:boolean) {
    this.downloadAnimationIcon.loop = play;
    this.downloadAnimationIcon.goToAndPlay(0, true);
  }
  animationCreated(animationItem: AnimationItem): void {
    this.downloadAnimationIcon = animationItem;
  }
  ngOnInit(): void {
    console.log("data-->",this.data)
  }

  detectMobile() {


    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    if (isMobileDevice) {
      window.open('http://onelink.to/imepay');
    } else {
        this._router.navigate(['get-app']);
    }
  }

}
