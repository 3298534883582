import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { AnimationOptions } from "ngx-lottie";
import { OwlOptions } from "ngx-owl-carousel-o";
import { EXTERNAL_LINKS } from "src/app/shared/constants/constants";
import { ROUTER_BASE_PATH } from "src/app/shared/constants/router-path";
import { setTiltProp } from "src/app/shared/service/utilsService/utility.service";

@Component({
  selector: "ime-remittance",
  templateUrl: "./remittance.component.html",
  styleUrls: ["./remittance.component.scss"],
})
export class RemittanceComponent implements OnInit {
  imgSrcLearMoreArrow = "assets/icon/learnmore-arrow.svg";
  tiltSettingsPopupImage = setTiltProp();
  tiltSettingsPopupEye = setTiltProp(true);
  routerPath = ROUTER_BASE_PATH;
  commision_link = EXTERNAL_LINKS.CHARGE_N_COMMISION;

  customOptions: OwlOptions = {
    rtl: false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplaySpeed: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 3000,
    slideBy: 1,
    margin: 10,
    responsive: {
      0: {
        items: 4,
      },
      480: {
        items: 6,
      },
      700: {
        items: 8,
      },
      1080: {
        items: 10,
      },
    },
    nav: false,
    autoHeight: true,
  };
  customOptionsNetwork: OwlOptions = {
    rtl: false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplaySpeed: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 3000,
    slideBy: 1,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      700: {
        items: 1,
      },
    },
    nav: false,
    autoHeight: true,
  };
  internationalPatners = [
    "assets/remit-partner-logos/remit-00.png",
    "assets/remit-partner-logos/remit-01.png",
    "assets/remit-partner-logos/remit-02.png",
    "assets/remit-partner-logos/remit-03.png",
    "assets/remit-partner-logos/remit-04.png",
    "assets/remit-partner-logos/remit-05.png",
    "assets/remit-partner-logos/remit-06.png",
    "assets/remit-partner-logos/remit-07.png",
    "assets/remit-partner-logos/remit-08.png",
    "assets/remit-partner-logos/remit-10.png",
    "assets/remit-partner-logos/remit-11.png",
    "assets/remit-partner-logos/remit-12.png",
    "assets/remit-partner-logos/remit-13.png",
    "assets/remit-partner-logos/remit-14.png",
    "assets/remit-partner-logos/remit-15.png",
    "assets/remit-partner-logos/remit-16.png",
    "assets/remit-partner-logos/remit-17.png",
    "assets/remit-partner-logos/remit-18.png",
  ];
  domesticPatners = [
    "assets/remit-partner-logos/remit-domestic-1.png",
    "assets/remit-partner-logos/remit-domestic-2.png",
    "assets/remit-partner-logos/remit-domestic-3.png",
    "assets/remit-partner-logos/remit-domestic-4.png",
  ];
  remittanceNetwork = [
    {
      img:'assets/icon/icon-anchor.png',
      title:'77',
      desc:'District Presence'
    },
    {
      img:'assets/icon/icon-sign.png',
      title:'35K+',
      desc:'Agents Network'
    },
    {
      img:'assets/icon/icon-bolt.png',
      title:'3M+',
      desc:'Users Community'
    }
  ]

  //double patners for design TO DO
  allPatners = this.internationalPatners
    .concat(this.domesticPatners)

    animationIconOptions: AnimationOptions;
    animationIconCountries: AnimationOptions;
  constructor(private titleService: Title, private translate: TranslateService) {
    this.titleService.setTitle("Remittance - Ime Pay")
    this.animationIconOptions = {
      path: "assets/images/json-image/remittance-list.json",
      loop: true,
      autoplay: true,
    }
    this.animationIconCountries = {
      path: "assets/images/json-image/countries.json",
      loop: true,
      autoplay: true,
    }
    this.translate.setDefaultLang('en')
  }
  ngOnInit(): void {

    this.translate.onLangChange.subscribe(() => {
      this.loadRemittance();
    });

    this.loadRemittance()
  }

  loadRemittance(){
    this.translate.get('remittance.numbers.cards').subscribe(value=>{
      this.remittanceNetwork[0].title = value[0].heading
      this.remittanceNetwork[0].desc = value[0].subheading
      this.remittanceNetwork[1].title = value[1].heading
      this.remittanceNetwork[1].desc = value[1].subheading
      this.remittanceNetwork[2].title = value[2].heading
      this.remittanceNetwork[2].desc = value[2].subheading
    })
  }
}
