import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OwlOptions} from 'ngx-owl-carousel-o';
import { EXTERNAL_LINKS } from 'src/app/shared/constants/constants';
import { ROUTER_BASE_PATH } from 'src/app/shared/constants/router-path';
import { setTiltProp } from 'src/app/shared/service/utilsService/utility.service';
@Component({
  selector: 'ime-ime-pay-visa-card',
  templateUrl: './ime-pay-visa-card.component.html',
  styleUrls: ['./ime-pay-visa-card.component.scss']
})
export class ImePayVisaCardComponent implements OnInit, AfterViewInit {

  panelOpenState1 = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
  panelOpenState4 = false;

  tiltSettings = setTiltProp();
  tiltSettingsPopupImage = setTiltProp(true);
  routerPath  = ROUTER_BASE_PATH;
  commision_link = EXTERNAL_LINKS.CHARGE_N_COMMISION;

  imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'
  constructor(private titleService: Title) {
    this.titleService.setTitle("IME Pay visa card - IME Pay")
   }
  ngOnInit(): void {
  }


  ngAfterViewInit(){
  }

  getPhysicalVisaCard() {
    window.open('https://blog.imepay.com.np/get-physical-visa-card/');
  }
}
