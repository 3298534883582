import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {ROUTER_BASE_PATH} from "../../shared/constants/router-path";

@Component({
  selector: 'ime-grievance',
  templateUrl: './grievance.component.html',
  styleUrls: ['../safety-and-compliance/safety-and-compliance.component.scss']
})
export class GrievanceComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle("Grievances - ImePay")
  }

  ngOnInit(): void {
  }
  routerPath = ROUTER_BASE_PATH;
}
