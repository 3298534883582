<section class="way-to-pay">
  <div class="container-way-to-pay">
    <div class="background">
      <div class="background-text marquee">
        <span  >{{'ways_to_pay.spending_it.heading' | translate}}</span>
      </div>
    </div>
    <div class="container-way-to-pay__contents way-to-pay__container">
      <div class="container-way-to-pay__images">
        <img
          src="assets/images/imepay-qr.png"
          class="container-way-to-pay__images__qr-image"
          data-aos="custom-qr-animation"
          data-aos-delay="500"
          id="qr-image"
          alt=""
        />
        <img
          src="assets/images/waystopay-section.webp"
          class="container-way-to-pay__images__main-image"
          data-aos="custom-zoom-animation"
          data-aos-delay="300"
        />
        <img
          src="assets/images/visa-card-alt.webp"
          class="container-way-to-pay__images__card"
          alt=""
          data-aos="custom-card-animation"
          data-aos-delay="500"
        />
      </div>
      <div class="container-way-to-pay__wrapper">
        <div
          class="container-way-to-pay__label ime-text-1"
          data-aos="fade-up"
          data-aos-delay="900"
        >
        {{'ways_to_pay.spending_it.subheading' | translate}}
        </div>
        <div
          class="container-way-to-pay__wrapper__button"
          data-aos="fade-up"
          data-aos-delay="1400"
        >
          <ime-btn-get-app [data]="{ getApp: true }">GET APP</ime-btn-get-app>
        </div>
      </div>
    </div>
  </div>

  <div class="container-grow-business">
    <div class="grow-business grow-business--alt content-container">
      <div class="grow-business__top">
        <img
          src="assets/images/scan-any-qr-popup.webp"
          alt=""
          class="grow-business__top__popup"
          data-aos="zoom-in"
        />
        <div class="grow-business__top__image">
          <div class="grow-business__top__image__img">
            <img
              src="assets/images/cashout-section.webp"
              loading="eager"
              alt=""
              data-aos="zoom-out"
            />
          </div>
        </div>
      </div>
      <div class="grow-business__bottom">
        <div class="grow-business__bottom__left">
          <div
            class="grow-business__bottom__left__heading ime-heading-1"
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="300"
            data-aos-easing="ease-in-out"
          >
            {{'ways_to_pay.shop_local.heading.0' | translate}}<br />
            <span class="ime-color-red">{{'ways_to_pay.shop_local.heading.1' | translate}}</span>
          </div>
        </div>
        <div class="grow-business__bottom__right">
          <div
            class="grow-business__bottom__right__text-2 ime-text-1"
            data-aos="fade-up"
            data-aos-delay="400"
          >
          {{'ways_to_pay.shop_local.subheading' | translate}}
          </div>
          <button
            mat-stroked-button
            class="learn-more-btn"
            (mouseover)="
              imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
            "
            (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
            data-aos="fade-up"
            data-aos-delay="800"
            [routerLink]="['/', routerPath.PAY_IN_STORE]"
          >
            <div class="learn-more-btn-content">
              <div class="learn-more-btn-content__title">{{'button_text.learn_more' | translate}}</div>
              <div class="learn-more-btn-content__img">
                <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- checkout-out section -->
  <div class="checkout__service" #cardScanDiv>
    <div
      class="checkout__service__contents checkout__service content-container"
    >
      <div class="checkout__service__contents__right">
        <div class="checkout__service__contents__right__images">
          <div class="checkout__service__contents__right__images__container">
            <div class="checkout__service__contents__right__images__main">
              <img src="assets/images/payment-method.png" alt="" />
            </div>
            <div class="checkout__service__contents__right__images__icon-1">
              <img
                src="assets/images/payment-neo.png"
                alt=""
                data-aos="fade-down-right"
              />
            </div>
            <div class="checkout__service__contents__right__images__icon-2">
              <img
                src="assets/images/payment-bhoj.png"
                alt=""
                data-aos="fade-down-left"
              />
            </div>
            <div class="checkout__service__contents__right__images__icon-3">
              <img
                src="assets/images/payment-sony.png"
                alt=""
                data-aos="fade-up-right"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="checkout__service__contents__left">
        <div
          class="checkout__service__contents__left__heading ime-heading-1 ime-hide-only-on-mobile"
          data-aos="zoom-out"
          data-aos-delay="500"
        >
          <span>{{'ways_to_pay.check_out.heading.0' | translate}}</span> {{'ways_to_pay.check_out.heading.1' | translate}} {{'ways_to_pay.check_out.heading.2' | translate}}
        </div>
        <div
          class="checkout__service__contents__left__heading ime-heading-1 ime-show-only-on-mobile"
          data-aos="zoom-out"
          data-aos-delay="500"
        >
          <span>{{'ways_to_pay.check_out.heading.0' | translate}}</span> {{'ways_to_pay.check_out.heading.0' | translate}} <br />{{'ways_to_pay.check_out.heading.2' | translate}}
        </div>
        <div
          class="checkout__service__contents__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="800"
        >
        {{'ways_to_pay.check_out.subheading' | translate}}
        </div>
        <button
          mat-stroked-button
          class="learn-more-btn"
          (mouseover)="
            imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          data-aos="fade-up"
          data-aos-delay="1000"
          [routerLink]="['/', routerPath.PAY_ONLINE_IN_APPS]"
        >
          <div class="learn-more-btn-content">
            <div class="learn-more-btn-content__title">{{'button_text.learn_more' | translate}}</div>
            <div class="learn-more-btn-content__img">
              <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>

  <!-- Debit card container -->
  <div class="container-visa-debit-card">
    <div
      class="title"
      data-aos="fade-up"
      data-aos-delay="800"
      data-aos-anchor-placement="top-bottom"
    >
      {{'ways_to_pay.visa_card.heading' | translate}}
    </div>
    <div class="content-box">
      <div class="content-box__wrapper">
        <div class="content-box__top">
          <span
            >{{'ways_to_pay.visa_card.subheading' | translate}}</span
          >
          <button
            mat-button
            class="content-box__top__button"
            color="primary"
            [routerLink]="['/', routerPath.PAY_THROUGH_CARD]"
          >
          {{'button_text.learn_more' | translate}}
          </button>
        </div>
        <div class="content-box__buttom">
          <div class="content-box__buttom__left">
            <div class="content-box__buttom__left__top">
              <div class="charge-amount">
                <span class="charge-amount-rs">{{'ways_to_pay.visa_card.card.0.title' | translate}}</span>
                <span>{{'ways_to_pay.visa_card.card.0.description' | translate}}</span>
              </div>
              <div class="charge-amount">
                <span class="charge-amount-rs">{{'ways_to_pay.visa_card.card.1.title' | translate}}</span>
                <span>{{'ways_to_pay.visa_card.card.1.description' | translate}}</span>
              </div>
            </div>
            <div
              class="content-box__buttom__left__buttom ime-hide-only-on-mobile"
            >
            {{'ways_to_pay.visa_card.conditions' | translate}}
            </div>
            <div
              class="content-box__buttom__left__buttom ime-show-only-on-mobile"
            >
              *T&C Apply
            </div>
          </div>
          <div class="content-box__buttom__right">
            <img
              src="assets/images/holding-visa-card.webp"
              alt=""
              srcset=""
              data-aos="fade-up-left"
              data-aos-delay="1200"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Touch free payments -->

  <div class="container-grow-business">
    <div class="grow-business grow-business--alt content-container">
      <div class="grow-business__top">
        <img
          src="assets/images/touch-popup.webp"
          alt=""
          class="grow-business__top__popup2"
          data-aos="zoom-in"
          data-aos-anchor-placement="top-bottom"
        />
        <div class="grow-business__top__image">
          <div class="grow-business__top__image__img">
            <img
              src="assets/images/touch-free-payment.webp"
              data-aos="zoom-out"
              data-aos-anchor-placement="top-bottom"
              loading="eager"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="grow-business__bottom">
        <div class="grow-business__bottom__left">
          <div
            class="grow-business__bottom__left__heading ime-heading-1"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="400"
            data-aos-easing="ease-in-out"
          >
            <span class="ime-color-red">{{'ways_to_pay.touch_free.heading.0' | translate}}</span> <br />{{'ways_to_pay.touch_free.heading.1' | translate}}
          </div>
        </div>
        <div class="grow-business__bottom__right">
          <div
            class="grow-business__bottom__right__text ime-text-1"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            {{'ways_to_pay.touch_free.subheading.0' | translate}}<br /><br />
            {{'ways_to_pay.touch_free.subheading.1' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- pay utilities -->
  <div class="container-utility-pay">
    <div class="container-utility-pay__container">
      <div class="container-utility-pay__right">
        <img
          src="assets/icon/Wi-Fi_Left.webp"
          alt=""
          class="container-utility-pay__container__popup"
        />
        <div class="container-utility-pay__right__first">
          <img
            src="assets/images/utility-service-first.png"
            class="container-utility-pay__right__img container-utility-pay__right__img--1"
            alt=""
            data-aos="fade-up"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="container-utility-pay__right__second">
          <img
            src="assets/images/utility-service-third.png"
            class="container-utility-pay__right__img container-utility-pay__right__img--3"
            alt=""
            data-aos="fade-up"
            data-aos-delay="200"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="container-utility-pay__right__third">
          <img
            src="assets/images/utility-service-second.png"
            class="container-utility-pay__right__img container-utility-pay__right__img--2"
            alt=""
            data-aos="fade-up"
            data-aos-delay="400"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="container-utility-pay__right__fourth">
          <img
            src="assets/images/utility-service-fourth.png"
            class="container-utility-pay__right__img container-utility-pay__right__img--4"
            alt=""
            data-aos="fade-up"
            data-aos-delay="600"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
      <div class="container-utility-pay__left">
        <div
          class="container-utility-pay__left__title ime-heading-1"
          data-aos="zoom-in"
          data-aos-delay="200"
          data-aos-duration="600"
        >
          {{'ways_to_pay.pay_for_utilities.heading.0' | translate}} <br />
          <span class="ime-color-red"> {{'ways_to_pay.pay_for_utilities.heading.1' | translate}} </span>
        </div>
        <span
          class="container-utility-pay__left__label ime-text-1"
          data-aos="zoom-in-up"
          data-aos-delay="1500"
          data-aos-duration="600"
        >
        {{'ways_to_pay.pay_for_utilities.subheading' | translate}}
        </span>

        <div class="container-utility-pay__left__button">
          <button
            mat-stroked-button
            class="learn-more-btn"
            (mouseover)="
              imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
            "
            (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
            data-aos="fade-up"
            data-aos-delay="700"
            [routerLink]="['/', routerPath.PAY_SERVICE]"
          >
            <div class="learn-more-btn-content">
              <div class="learn-more-btn-content__title">{{'button_text.learn_more' | translate}}</div>
              <div class="learn-more-btn-content__img">
                <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- ecommerce -->
  <div class="ecommerce">
    <div class="ecommerce__background">
      <div class="ecommerce__container">
        <div class="ecommerce__left">
          <div
            class="ecommerce__left__heading ime-heading-1 ime-hide-only-on-mobile"
            data-aos="zoom-in"
            data-aos-delay="600"
          >
           {{'ways_to_pay.shop_ecommerce.heading.0' | translate}} <br />
           {{'ways_to_pay.shop_ecommerce.heading.1' | translate}} <br />
            <span class="ime-color-red"> {{'ways_to_pay.shop_ecommerce.heading.2' | translate}} </span>
          </div>
          <div
            class="ecommerce__left__heading ime-heading-1 ime-show-only-on-mobile"
            data-aos="zoom-in"
            data-aos-delay="600"
          >
          {{'ways_to_pay.shop_ecommerce.heading.0' | translate}} {{'ways_to_pay.shop_ecommerce.heading.1' | translate}}<br />
            <span class="ime-color-red"> {{'ways_to_pay.shop_ecommerce.heading.2' | translate}} </span>
          </div>
          <div
            class="ecommerce__left__text ime-text-1"
            data-aos="zoom-in-up"
            data-aos-delay="800"
          >
          {{'ways_to_pay.shop_ecommerce.subheading' | translate}}
          </div>
        </div>
        <div class="ecommerce__right">
          <div class="ecommerce__right__images">
            <div class="ecommerce__right__images__main">
              <img
                src="assets/images/checkout-in-app.webp"
                class="ecommerce__right__images__main__img"
                alt=""
              />
            </div>
            <div class="ecommerce__right__images__popup-1">
              <img
                src="assets/images/checkout-in-app-2.webp"
                alt=""
                data-aos="fade-left"
                data-aos-delay="300"
              />
            </div>
            <div class="ecommerce__right__images__popup-2">
              <img
                src="assets/images/cheers-logo.webp"
                alt=""
                data-aos="fade-up-right"
                data-aos-delay="300"
              />
            </div>
            <div class="ecommerce__right__images__popup-3">
              <img
                src="assets/images/sasto-logo.webp"
                alt=""
                data-aos="fade-down-left"
                data-aos-delay="300"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Do more with ime -->
  <div class="container-do-more">
    <div class="container-do-more__content content-container">
      <div class="container-do-more__content__title ime-heading-1">
        {{'ways_to_pay.spend_imepay_way.heading.0' | translate}} <span>{{'ways_to_pay.spend_imepay_way.heading.1' | translate}}</span>
      </div>
      <div class="container-do-more__content__contents">
        <div
          class="card"
          data-aos="fade-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <div class="card__image">
            <img src="assets/images/pay-way-1.webp" alt="" />
          </div>

          <div class="card__info">
            <div class="card__heading ime-heading-5">{{'ways_to_pay.spend_imepay_way.cards.0.title' | translate}}</div>
            <div class="card__text ime-text-2">
              {{'ways_to_pay.spend_imepay_way.cards.0.description' | translate}}
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="fade-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          <div class="card__image card__image--large">
            <img src="assets/images/pay-way-2.webp" alt="" />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">{{'ways_to_pay.spend_imepay_way.cards.1.title' | translate}}</div>
            <div class="card__text ime-text-2">
              {{'ways_to_pay.spend_imepay_way.cards.1.description' | translate}}
              Pay.
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="fade-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="800"
        >
          <div class="card__image">
            <img src="assets/images/pay-way-3.webp" alt="" />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">{{'ways_to_pay.spend_imepay_way.cards.2.title' | translate}}</div>
            <div class="card__text ime-text-2">
              {{'ways_to_pay.spend_imepay_way.cards.2.description' | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- pay-smarter -->
  <div class="container-pay-smarter">
    <div class="container-banner">
      <div class="container-pay-smarter__wrapper">
        <img
          src="assets/images/cart-alt.png"
          alt=""
          class="container-pay-smarter__wrapper__gift-image"
          data-aos="fade-down-right"
          data-aos-delay="1500"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <div class="container-banner__left">
          <img
            src="assets/images/visa-card-dual.webp"
            alt=""
            class="container-banner__left__visa-card"
            data-aos="fade-right"
            data-aos-delay="500"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
        <div class="container-banner__middle">
          <div class="container-banner__middle__title" data-aos="flip-left">
            {{'ways_to_pay.pay_smarter.heading.0' | translate}} <span class="ime-color-red">{{'ways_to_pay.pay_smarter.heading.1' | translate}}</span><br />
            {{'ways_to_pay.pay_smarter.heading.2' | translate}}
          </div>
          <div
            class="container-banner__middle__button"
            data-aos="zoom-in-down"
            data-aos-delay="1600"
          >
            <ime-btn-get-app [data]="{ getApp: true }">GET APP</ime-btn-get-app>
          </div>
        </div>
        <div class="container-banner__right">
          <img
            src="/assets/images/imepay-qr.png"
            class="container-banner__right__qr-scan"
            alt=""
            data-aos="fade-left"
            data-aos-delay="1000"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</section>
