import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { setTiltProp } from 'src/app/shared/service/utilsService/utility.service';
import { ROUTER_BASE_PATH } from '../../shared/constants/router-path';

@Component({
  selector: 'ime-pay-in-store',
  templateUrl: './pay-in-store.component.html',
  styleUrls: ['./pay-in-store.component.scss']
})
export class PayInStoreComponent implements OnInit {

  tiltSettings = setTiltProp();
  tiltSettingsPopupImage =setTiltProp(true);
  routerPath =  ROUTER_BASE_PATH;
  constructor(private titleService: Title) {
   this.titleService.setTitle("Pay in Stores - Ime Pay");

  }
  imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg';
  ngOnInit(): void {
  }

}
