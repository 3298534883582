<div class="pay-service">
  <div class="pay-service-hero">
    <div class="pay-service-hero__container">
      <div class="pay-service-hero__left">
        <div class="pay-service-hero__left__content">
          <div class="pay-service-hero__left__heading ime-heading-1 ime-hide-only-on-mobile"
          data-aos="zoom-in"
          data-aos-delay="600"
          data-aos-duration="800"
          >
            {{'imepay_services.nepals_fastest.heading.0' | translate}} {{'imepay_services.nepals_fastest.heading.1' | translate}}
            <br />
            <span class="ime-color-red">{{'imepay_services.nepals_fastest.heading.2' | translate}}</span>
          </div>
          <div class="pay-service-hero__left__heading ime-heading-1 ime-show-only-on-mobile">
            {{'imepay_services.nepals_fastest.heading.0' | translate}} {{'imepay_services.nepals_fastest.heading.1' | translate}}
            <span class="ime-color-red">{{'imepay_services.nepals_fastest.heading.1' | translate}}</span>
          </div>
          <div class="pay-service-hero__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="800"
          >
           {{'imepay_services.nepals_fastest.subheading' | translate}}
          </div>
          <div class="pay-service-hero__left__button"
          data-aos="fade-up"
          data-aos-delay="1000"
          >
            <ime-btn-get-app [data]="{ getApp: true }">GET APP</ime-btn-get-app>
          </div>
        </div>
      </div>
      <div class="pay-service-hero__right">
        <div class="pay-service-hero__right__images"
        >
          <img src="assets/images/service-flight.webp" alt="" class="pay-service-hero__right__images__popup1"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage">
          <img src="assets/images/service-mobile-recharge.webp" alt="" class="pay-service-hero__right__images__popup2"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <div class="pay-service-hero__right__images__first">
            <img
              src="assets/images/pay-service-1.webp"
              class="pay-service-hero__right__images__person pay-service-hero__right__images__person-1"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              alt=""
              aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/pay-service-3.webp"
              class="pay-service-hero__right__images__person pay-service-hero__right__images__person-3"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              alt=""
              aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
          <div class="pay-service-hero__right__images__second">
            <img
              src="assets/images/pay-service-2.webp"
              class="pay-service-hero__right__images__person pay-service-hero__right__images__person-2"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/pay-service-4.webp"
              class="pay-service-hero__right__images__person pay-service-hero__right__images__person-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              alt=""
              aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pay-recharge">
    <div class="pay-recharge__container">
      <div class="pay-recharge__left">
        <div class="pay-recharge__left__images">
          <img
            src="assets/images/recharge-screen.webp"
            alt=""
            class="pay-recharge__left__images__main"
            data-aos="fade-in"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/recharge-ncell.webp"
            alt=""
            class="pay-recharge__left__images__popup1"
            data-aos="fade-down-left"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/recharge-smart.webp"
            alt=""
            class="pay-recharge__left__images__popup2"
            data-aos="fade-right"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/recharge-ntc.webp"
            alt=""
            class="pay-recharge__left__images__popup3"
            data-aos="fade-up"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
      <div class="pay-recharge__right">
        <div class="pay-recharge__right__info">
          <div class="pay-recharge__right__heading ime-heading-1"
          data-aos="zoom-in"
          data-aos-delay="200"
          >
            <span class="ime-color-red">{{'imepay_services.instant_recharge.heading.0' | translate}}</span><br />
            {{'imepay_services.instant_recharge.heading.1' | translate}}
          </div>
          <div class="pay-recharge__right__info__text ime-text-1 ime-hide-only-on-mobile"
          data-aos="fade-up"
          data-aos-delay="400"
          >
          {{'imepay_services.instant_recharge.subheading' | translate}}
          </div>
          <div class="pay-recharge__right__info__text ime-text-1 ime-show-only-on-mobile"
          data-aos="fade-up"
          data-aos-delay="400"
          >
          {{'imepay_services.instant_recharge.subheading' | translate}}
          </div>
          <div class="pay-recharge__right__info__logo"
          data-aos="fade-left"
          data-aos-delay="600"
          >
            <div class="pay-recharge__right__info__logo-box">
              <img src="assets/images/recharge-mobile.webp" alt=""
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
              />
            </div>
            <div class="pay-recharge__right__info__logo-2">
              <img src="assets/images/recharge-landline.webp" alt=""
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
              />
            </div>
            <div class="pay-recharge__right__info__logo-3">
              <img src="assets/images/recharge-data-pack.webp" alt=""
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pay-travel">
    <div class="pay-travel__container">
      <div class="pay-travel__left">
        <div class="pay-travel__left__content">
          <div class="ppay-travel__left__heading ime-heading-1"
          data-aos="zoom-in"
          data-aos-delay="600"
          data-aos-duration="800"
          >
            <span class="ime-color-red"> {{'imepay_services.travel.heading.0' | translate}}</span>
            <br />
            {{'imepay_services.travel.heading.1' | translate}}
          </div>
          <div class="pay-travel__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="800"
          >
          {{'imepay_services.travel.subheading' | translate}}
          </div>
          <div class="pay-travel__left__logo"
          data-aos="fade-up"
          data-aos-delay="1000"
          >
            <img src="assets/images/travel-cable-car.png" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            />
            <img src="assets/images/travel-flight.png" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
      <div class="pay-travel__right">
        <div class="pay-travel__right__images">
          <img
            src="assets/images/travel-siddharth.webp"
            alt=""
            class="pay-travel__right__images__popup1"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/travel-yeti.webp"
            alt=""
            class="pay-travel__right__images__popup2"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/travel-chandragiri.webp"
            alt=""
            class="pay-travel__right__images__popup3"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <div class="pay-travel__right__images__first">
            <img
              src="assets/images/travel-service-1.webp"
              class="pay-travel__right__images__person pay-travel__right__images__person-1"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/travel-service-3.webp"
              class="pay-travel__right__images__person pay-travel__right__images__person-3"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
          <div class="pay-travel__right__images__second">
            <img
              src="assets/images/travel-service-2.webp"
              class="pay-travel__right__images__person pay-travel__right__images__person-2"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/travel-service-4.webp"
              class="pay-travel__right__images__person pay-travel__right__images__person-4"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- go-to -->
  <div class="bank-link-steps content-container">
    <div
      class="bank-link-steps__header ime-heading-1"
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="800"
      data-aos-delay="100"
    >
    {{'imepay_services.go_to_app.heading.0' | translate}}
    </div>
    <div class="bank-link-steps__container steps">
      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="200"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/travel-step-1.webp"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            alt=""
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--first ime-heading-3"
          >
            {{'imepay_services.go_to_app.cards.0.title' | translate}}
          </div>
          <div class="steps__step__info ime-text-1">
            {{'imepay_services.go_to_app.cards.0.description' | translate}}
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="500"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/travel-step-2.webp"
            class="steps-icon-enter-details"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--second ime-heading-3"
          >
          {{'imepay_services.go_to_app.cards.1.title' | translate}}
          </div>
          <div class="steps__step__info ime-text-1">
            {{'imepay_services.go_to_app.cards.1.description' | translate}}
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="800"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/travel-step-3.webp"
            class="steps-icon-link"
            alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--third ime-heading-3"
          >
          {{'imepay_services.go_to_app.cards.2.title' | translate}}
          </div>
          <div class="steps__step__info ime-text-1">
            {{'imepay_services.go_to_app.cards.1.description' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bill-service">
    <div class="bill-service__container">
      <div class="bill-service__left">
        <div class="bill-service__left__images">
          <img
            src="assets/images/electricity.webp"
            alt=""
            class="bill-service__left__images__main"
            data-aos="zoom-in-right"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/dish-home.webp"
            alt=""
            class="bill-service__left__images__main2"
            data-aos="zoom-in-left"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/go-green.webp"
            alt=""
            class="bill-service__left__images__popup1"
            data-aos="zoom-out-down"
            data-aos-delay="300"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/wifi-bill.webp"
            alt=""
            class="bill-service__left__images__popup2"
            data-aos="zoom-out-up"
            data-aos-delay="400"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
      <div class="bill-service__right">
        <div class="bill-service__right__info">
          <div class="bill-service__right__info__heading ime-heading-1"
          data-aos="zoom-in-up"
          data-aos-delay="400"
          >
            {{'imepay_services.pay_household_bills.heading.0' | translate}}<br />
            <span class="ime-color-red">{{'imepay_services.pay_household_bills.heading.1' | translate}}</span>
          </div>
          <div class="bill-service__right__info__logos">
            <img
              src="assets/images/service-electricity.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="550"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-internet.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="700"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-water-bill.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="850"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-waste-management.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1000"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-television.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1150"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-education.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1300"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-vehicle.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1450"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-credit-card.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1500"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="entertainment-service">
    <div class="entertainment-service__container">
      <img src="assets/images/service-liv.webp" alt="" class="entertainment-service__popup1 ime-hide-only-on-mobile"
      data-aos="fade-down-right"
      aTilt
          [tiltSettings]="tiltSettingsPopupImage"
      >
      <img src="assets/images/service-qfx.webp" alt="" class="entertainment-service__popup2 ime-hide-only-on-mobile"
      data-aos="fade-up-left"
      aTilt
          [tiltSettings]="tiltSettingsPopupImage"
      >
      <div class="entertainment-service__heading ime-heading-1"
      data-aos="zoom-out"
      data-aos-delay="200"
      >
       <span class="ime-color-red">{{'imepay_services.entertainment.heading.0' | translate}}</span> {{'imepay_services.entertainment.heading.1' | translate}}
      </div>
      <div class="entertainment-service__text ime-text-1"
      data-aos="zoom-out-up"
      data-aos-delay="400"
      >
      {{'imepay_services.entertainment.subheading' | translate}}
      </div>
      <div class="entertainment-service__logos ime-hide-only-on-mobile"
      data-aos="zoom-out-up"
      data-aos-delay="600"
      >
        <img src="assets/images/service-movies.webp" alt="" class="entertainment-service__logos__logo"
        aTilt
        [tiltSettings]="tiltSettingsPopupImage"
        />
        <img src="assets/images/service-ott.webp" alt="" class="entertainment-service__logos__logo"
        aTilt
        [tiltSettings]="tiltSettingsPopupImage"
        />
        <img
          src="assets/images/service-events.webp"
          alt=""
          class="entertainment-service__logos__logo"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <img src="assets/images/service-voting.webp" alt="" class="entertainment-service__logos__logo"
        aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>
      <div class="entertainment-service__animation ime-hide-only-on-mobile">
        <ime-json-animator
              [options]="animationIconOptions[0]"
            ></ime-json-animator>
      </div>
      <div class="entertainment-service__animation ime-show-only-on-mobile">
        <ime-json-animator
              [options]="animationIconOptions[1]"
            ></ime-json-animator>
      </div>
    </div>
  </div>

  <div class="more-payment">
    <div class="more-payment__container">
      <div class="more-payment__left">
        <div class="more-payment__left__info">
          <div class="more-payment__left__heading ime-heading-1"
          data-aos="zoom-out"
          data-aos-delay="400"
          >
            <span class="ime-color-red">{{'imepay_services.more_payments.heading.0' | translate}}</span><br>
            {{'imepay_services.more_payments.heading.1' | translate}}
          </div>
          <div class="more-payment__left__text ime-text-1"
          data-aos="zoom-out-up"
          >
          {{'imepay_services.more_payments.subheading' | translate}}
          </div>
          <div class="more-payment__left__logos"
          >
            <img src="assets/images/service-payment.webp" alt=""
            data-aos="zoom-out-right"
            data-aos-delay="500"
            data-aos-duration="150"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/service-insurance.webp" alt=""
            data-aos="zoom-out-left"
            data-aos-delay="650"
            data-aos-duration="150"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/service-share.webp" alt=""
            data-aos="zoom-out-right"
            data-aos-delay="800"
            data-aos-duration="150"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/service-government.webp" alt=""
            data-aos="zoom-out-left"
            data-aos-delay="950"
            data-aos-duration="150"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
          </div>
        </div>
      </div>
      <div class="more-payment__right">
        <div class="more-payment__right__images">
          <img src="assets/images/demat.webp" alt="" class="more-payment__right__images__main1">
          <img src="assets/images/banks.webp" alt="" class="more-payment__right__images__main2"
          data-aos="zoom-out-right"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/service-meroshare.webp" alt="" class="more-payment__right__images__popup1"
          data-aos="fade-down-left"
          data-aos-delay="200"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/service-igi.webp" alt="" class="more-payment__right__images__popup2"
          data-aos="fade-right"
          data-aos-delay="200"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/service-traffic.webp" alt="" class="more-payment__right__images__popup3"
          data-aos="fade-up-left"
          data-aos-delay="200"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
        </div>
      </div>
    </div>
  </div>

  <div class="payment-benefit">
    <div class="payment-benefit__heading"
    data-aos="zoom-out"
    data-aos-delay="500"
    >
      {{'imepay_services.no_lines.heading' | translate}}
    </div>
    <div class="payment-benefit__container">
      <div class="payment-benefit__cards">
        <div class="payment-benefit__card"
        data-aos="fade-up"
        data-aos-delay="600"
        >
          <div class="payment-benefit__card__image">
            <img src="assets/images/save-time.webp" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage">
          </div>
          <div class="payment-benefit__card__info">
            <div class="payment-benefit__card__heading">
              {{'imepay_services.no_lines.cards.0.title' | translate}}
            </div>
            <div class="payment-benefit__card__text ime-text-2">
              {{'imepay_services.no_lines.cards.0.description' | translate}}
            </div>
          </div>
        </div>
        <div class="payment-benefit__card"
        data-aos="fade-up"
        data-aos-delay="800"
        >
          <div class="payment-benefit__card__image">
            <img src="assets/images/get-rebates.webp" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
          </div>
          <div class="payment-benefit__card__info">
            <div class="payment-benefit__card__heading">
              {{'imepay_services.no_lines.cards.1.title' | translate}}
            </div>
            <div class="payment-benefit__card__text ime-text-2">
              {{'imepay_services.no_lines.cards.1.description' | translate}}
            </div>
          </div>
        </div>
        <div class="payment-benefit__card"
        data-aos="fade-up"
        data-aos-delay="800"
        >
          <div class="payment-benefit__card__image">
            <img src="assets/images/pay-amount.webp" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
          </div>
          <div class="payment-benefit__card__info">
          <div class="payment-benefit__card__heading">
            {{'imepay_services.no_lines.cards.2.title' | translate}}
          </div>
          <div class="payment-benefit__card__text ime-text-2">
            {{'imepay_services.no_lines.cards.2.description' | translate}}
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="service-cta">
    <div class="service-cta__container">
      <div class="service-cta__wrapper">
        <div class="service-cta__left">
          <div class="service-cta__left__heading"
          data-aos="zoom-in"
          data-aos-delay="300"
          >
            {{'imepay_services.quick_simple.heading.0' | translate}}
            <br>
            {{'imepay_services.quick_simple.heading.1' | translate}} <span class="ime-color-red">{{'imepay_services.quick_simple.heading.2' | translate}}</span>
          </div>
          <div class="service-cta__left__button"
          data-aos="fade-up"
          data-aos-delay="500"
          >
            <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
          </div>
        </div>
        <div class="service-cta__right">
          <div class="service-cta__right__images">
            <img src="assets/images/menu.webp" alt="" class="service-cta__right__images__main1"
            data-aos="fade-up-left"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/traffic.webp" alt="" class="service-cta__right__images__main2"
            data-aos="fade-up-right"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</div>
